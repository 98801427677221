/**
 * English dictionary.
 */
export default {
  common: {
    header: {
      wallet: 'Wallet',
      wallets: 'wallets',
      supplyingAndBorrowing: 'Supply & Borrow',
      staking: 'Staking',
      governance: 'Governance',
      comingSoon: 'Coming soon',
      maintenance: 'Maintenance',
    },
    profile: {
      connectWallet: 'Connect new wallet',
      notInstalled: 'NOT INSTALLED',
      connect: 'CONNECT',
      connected: 'CONNECTED',
      connecting: 'CONNECTING',
      noneInstalled: 'None of the supported crypto wallet extensions are installed in your browser.',
      logOut: 'Log out',
      profileAndSettings: 'Profile & Settings',
    },
    notFoundPage: {
      title: '404',
      subtitle: 'Page not found.',
      text: 'Contact us for support\nor try again later',
      button: 'BACK TO MELDAPP',
      redirecting: 'REDIRECTING IN ',
    },
    loginPage: {
      theFutureOfFinance: 'THE FUTURE OF FINANCE',
      congrats: 'CONGRATS!',
      unlockedWallet: `You've just unlocked your very own crypto wallet. Welcome to the future of finance!`,

      title: 'Restore Your Wallet',
      restoreWallet: 'Restore Wallet',
      logIn: 'LOG IN',
      createWallet: 'Create Wallet',
      enterTheCodeLabel: 'Enter the code',
      accessToTestnetLabel: 'Access to MELD Testnet',
      step1Title: 'Social login',
      submitButtonTitle: 'Continue',
      step2Title: 'Saved device',
      step2Description: 'Importing device share',
      step2SuccessTitle: 'Successfully detected',
      step2ErrorMessage1: "We couldn't detect your device",
      step2ErrorMessage2: 'Continue with the options below',
      step3Title: 'Password',
      step3PasswordPlaceholder: 'Enter password',
      errorMessages: {
        passwordOrQuestionErrorMessage:
          'We cannot restore your wallet with the password and security question / answer given. Please verify and resubmit',
        wrongAccountErrorMessage:
          'We cannot find any wallet created with this account.  Please choose another one to restore your wallet',
      },

      signUpAgreementPart1: 'I have read and agree to the ',
      signUpAgreementPart2: 'Terms',
      signUpAgreementPart3: ' and ',
      signUpAgreementPart4: 'Disclaimer',

      passwordFieldPlaceholder: 'Enter an alphanumeric password',
      restoringWallets: {
        pending: 'Restoring wallets',
        success: 'Wallet successfully restored!',
      },

      flowSwitch: {
        title: 'Restore Your Wallet',
        seedPhraseFlowTitle: 'Seed Phrase',
        seedPhraseFlowDescription:
          'Enter the 24 words of your seed phrase in the correct order to restore your wallet.',
      },

      seedPhraseFlow: {
        title: 'Seed Phrase',
        secondaryTitle: 'Restore your wallet',
        description:
          'Enter your seed phrase of 24 words to restore your wallet. Mind the order of the words for each input.',
        nextButtonTitle: 'Restore wallet',
        invalidSeedPhraseError1: 'Seed Phrase not valid.',
        invalidSeedPhraseError2: 'Check the spelling and order of your words.',
      },
    },

    connectDapps: {
      paseUriHere: 'Paste WalletConnect URI here',
      dappConnections: 'dAPPS CONNECTIONS',
      emptyConnections: 'You don’t have any active connections',
      whereLink: 'Where to find the WalletConnect URI? ',
      invalidUri: 'Invalid URI address',
      invalidNetwork: 'dApp’s network not supported',

      invalidRequestTitle: `Unsupported network in {{value}} detected`,
      invalidRequestSubtitle: 'Please change the network in the dApp to continue using it.',

      txWillFail: 'Sorry but this transaction cannot be processed, either by lack of funds or for a different reason.',
    },

    signDrawer: {
      externalTransaction: 'EXTERNAL TRANSACTION',
      signMessage: 'SIGN MESSAGE',
      sign: 'SIGN',
      interactionWith: 'INTERACTION WITH',
      confirmAndSign: 'CONFIRM & SIGN',

      relayProtocol: 'RELAY PROTOCOL',
      method: 'METHOD',
      message: 'MESSAGE',

      signedMessage: `You've signed a message`,
      couldntSignMessage: `You couldn't sign a message`,

      verifyAmount: `You can verify the transaction amount in the dApp you're interacting with before you confirm.`,
    },

    secretCodeScreen: {
      title: 'Password',
      secondaryTitle: '',
      description:
        'By adding a password to your Seed Phrase, you can improve the security of your wallet while also ensuring quicker access. Simply enter your password twice, and your setup will be complete!',
      secretCodeFieldPlaceholder: 'Enter the password',
      secretCode2FieldPlaceholder: 'Enter the password',
      nextButtonTitle: 'Set my password & finish',
      secretCodeFieldLabel: 'Password',
      secretCode2FieldLabel: 'Repeat your password',
    },

    changePasswordDrawer: {
      title: 'Change Password',
      passwordPlaceholder: 'Enter your current password',
      newPassword: 'New password',
      newPasswordPlaceholder: 'Enter the new password',
      repeatPassword: 'Repeat password',
      repeatPasswordPlaceholder: 'Repeat the new password',
      successTitle: 'Password changed!',
      successDescription: 'That worked',
    },

    restoreWithSecretCodeScreen: {
      description: 'Enter your Password',
      restoreWallet: 'Restore Existing Wallet',
      invalidSecretCode: 'Invalid password',
    },

    signUpPage: {
      flowSwitch: {
        title: 'Create your wallet',
        seedPhraseFlowTitle: 'Seed Phrase',
        seedPhraseFlowDescription:
          'Create a seed phrase of 24 words to secure your wallet. We recommend to store it not digitally for safe keeping.',
      },
      securityCheck: {
        createYourWallet: 'create your wallet',
        securityCheck: 'Security check',
        goDirectly: 'Go directly to your wallet',
        goDirectlySubtitle: 'Secure your wallet later with your seed phrase.',
        goDirectlyCheckbox:
          'I acknowledge not saving my seed phrase now might result in losing my wallet and assets in the future.',
        secure: 'Secure your seed phrase',
        secureSubtitle: 'Enter the 24 words of your seed phrase in the correct order to back up your wallet.',
        confirmAndNext: 'confirm & next',
        walletAlert: 'Please write down and save the seed phrase in order to back up your MELDapp wallet!',
        secureSeed: 'Secure Seed Phrase',
        secureYourWallet: 'Secure your wallet',
        secureSeedDescription:
          'Here is your seed phrase of 24 words to secure your wallet. We recommend writing it down on paper and not storing it digitally.',
        firstPageButton: 'verify seed phrase',
        seedPhraseVerification: 'Seed Phrase Verification',
        verificationSuccesful: 'Verification Successful',
        phraseVerificationDescription:
          'Fill the missing words of your Seed Phrase to confirm you stored it. This step is order and spelling sensitive.',
        walletSecured: 'You secured your wallet!',
        securePhrase: 'secure now',
      },
      navigation: {
        leftSideText: 'If you close this tab and abandon the process, your progress will be lost. ',
        nextButtonTitle: 'Confirm & Next',
      },
      screenNavigation: {
        screen1Description: 'Create your wallet',
        screen2Description: 'Wallet creation',
        screen3Description: 'Encrypting the wallet pieces',
        screen4Description: 'Finishing process',
        btnNext: 'Next',
        btnBack: 'Back',
      },

      registerDeviceScreen: {
        title: 'Register This Device',
        secondaryTitle: 'Create Your Wallet',
        description:
          'Allow us to detect and save your device as one of our security measures. Read and confirm our policies.',
        allowDevice: 'Allow Device',
        deviceDetected: 'Device detected and saved',
        tos1: 'I have read the ',
        tos2: 'MELD Terms of Service',
        tos3: 'and',
        tos4: 'MELD Privacy Policy',
      },
      passwordScreen: {
        title: 'Wallet Password',
        secondaryTitle: 'Create Your Wallet',
        description:
          'Create a password of 8 characters including one capital letter and a special character. This allow you to recover your wallets in other devices.',
        passwordFieldPlaceholder: 'Enter the password',
        password2FieldPlaceholder: 'Enter the password again',
      },
      securityQuestionScreen: {
        title: 'Secret Question',
        secondaryTitle: 'Create Your Wallet',
        description:
          'Select one question and type the answer. This allow you to recover your wallets in other devices.',
        selectQuestionDropdown: 'Select a security question',
        answerQuestion: 'Answer your question',
      },
      socialEncryptionScreen: {
        title: 'Social Encryption',
        secondaryTitle: 'Create Your Wallet',
        description:
          'Use your social login process to encrypt part of your wallet. The social network knows nothing about this wallet and nothing is stored with the social network.',
        loginWithGoogle: 'Log in with Google account',
        userExists: 'Wallet already exists. Please go to',
        loginPage: 'Login page',
        nextButtonTitle: 'Finish',
      },
      walletCreationScreen: {
        title: 'Wallets are being created',
        subtitle: 'Do not close the window ',
        description:
          'Your wallets are being created. Do not close this window.\nThis process will be finish in no time.',
      },
      walletCreationSuccessScreen: {
        title: 'Wallets created!',
      },
      walletCreationSuccessDialog: {
        title: 'CONGRATS!',
        text1: `You've just unlocked your very own crypto wallet.`,
        text2: 'Welcome to the future of finance!',
      },
      seedPhraseScreen: {
        title: 'Seed Phrase',
        secondaryTitle: 'Create Your Wallet',
        description:
          'Here is your seed phrase of 24 words to secure your wallet and recover it when needed. We recommend writing it down on paper and not storing it digitally.',
      },

      seedPhraseConfirmationScreen: {
        title: 'Seed Phrase Verification',
        secondaryTitle: 'Create Your Wallet',
        description:
          'Fill the missing words of your Seed Phrase to confirm you stored it. This step is order and spelling sensitive.',
        invalidSeedPhraseError1: 'Seed Phrase not valid.',
        invalidSeedPhraseError2: 'Check the spelling and order of your words.',
      },
    },

    upgradeToSeedPhrasePage: {
      seedPhraseScreen: {
        secondaryTitle: 'Upgrade',
      },
      confirmationScreen: {
        nextButtonTitle: 'Confirm & Finish',
      },
    },

    overview: 'Overview',
    supplyAndBorrow: 'Supply & Borrow',
    governance: 'Governance',
    connectWalletLabel: 'Connect wallet',

    // CreateWallet page, Welcome:
    welcomeLabel: 'Welcome to',

    // CreateWallet page, ControlsPanel buttons:
    importWallet: 'import wallet',
    createWallet: 'create wallet',
    back: 'back',
    next: 'next',
    finish: 'finish',

    // CreateWallet page, ControlsPanel toasts:
    defaultErrorMessage: 'Something went wrong...',

    address: 'address',
    blockchain: 'blockchain',
    blockchains: 'blockchains',

    chains: {
      meld: 'Meld',
      ethereum: 'Ethereum',
      avalanche: 'Avalanche',
      cardano: 'Cardano',
    },

    iUnderstand: 'I understand',
    yes: 'YES',
    no: 'NO',

    createWalletPage: {
      walletSetupLabel: 'wallet setup',
      step: 'step',
      of: 'of',
      setupSeedLabel: 'Setup Seed Phrase',
      seedPhraseLabel:
        'Please write down the seed phrase below on paper or in a non-digital format. Place it in a safe place.',
      verifySeedPhraseLabel: 'Verify Seed Phrase',
      confirmationSeedPhraseLabel: 'Please confirm the seed phrase by filling in the missing phases for each number.',
      walletCreatedLabel: 'Your Wallet is Created!',
      congratulationsLabel: 'Congratulations, you just finished! Now you can give your wallet a name.',
      showSeedLabel: 'show seed phrase',
      savedLabel: 'Saved!',
      nameYourWalletLabel: 'Name your wallet',
      save: 'save',
      walletAddressLabelFrom: 'from wallet address',
      walletAddressLabelTo: 'to wallet address',
      copyActionLabel: 'Copied',
    },

    profilePage: {
      title: 'Profile',
      settingsAndSecurity: 'SETTINGS & SECURITY',
      wallets: 'WALLETS',
      avatar: 'AVATAR',

      password: 'Password',
      changePassword: 'CHANGE PASSWORD',

      seedPhrase: 'Seed phrase',
      viewPhrase: 'VIEW PHRASE',
      upgrade: 'UPGRADE',

      comingSoon: 'COMING SOON',

      airdropCampaignTitle: 'MELD AIRDROP CAMPAIGN',
      airdropCampaignSubtitle: 'BOOST YOUR ONCHAIN ACTIVITY',
      airdropCampaignButton: 'EXPLORE',
    },

    supplyBorrowTiles: {
      supply: {
        title: 'SUPPLY YIELD',
        title2: 'SUPPLY POWER',
        apy: 'UP TO {{value}}/M',
        personalApy: '{{value}}/M',
        at: '@ {{value}}',
      },
      borrow: {
        title: 'BORROW RATE',
        title2: 'BORROW POWER',
        title3: 'BORROW',
        availableLoan: 'AVAILABLE LOAN',
        apy: '{{value}} APY',
        on: 'ON {{value}}',
        at: '@ {{value}}',
      },
    },

    //WALLET PAGE
    walletPage: {
      assets: 'ASSETS',
      nfts: 'NFT',
      supportedNFTCollections: 'Check supported NFT collections',
      checkSupportedTokens: 'Check supported tokens',

      summary: 'Summary',
      wallet: 'WALLET',
      totalValue: 'TOTAL VALUE',
      networks: 'NETWORKS',

      totalValueTooltipQuestion: 'WHAT IS TOTAL VALUE?',
      totalValueTooltipAnswer:
        'It provides an overview of the overall worth of your crypto holdings at a given moment, helping you track the performance of your assets. It is calculated by multiplying the quantity of each asset by its current market price.',

      portfolioPerformanceTooltipQuestion: 'PORTFOLIO PERFORMANCE',
      portfolioPerformanceTooltipAnswer: `A label indicating the increase or\ndecrease in your portfolio's value\nover a selected time frame.`,

      description: 'DESCRIPTION',
      allAssets: 'All Assets',
      allNfts: 'All NFTs',
      numberOfNfts: `{{value}} items`,
      numberOfNftsSingular: `{{value}} items`,
      refresh: 'REFRESH BALANCE',
      refreshing: 'REFRESHING BALANCE...',
      refreshStaking: 'REFRESH STAKING DATA',
      refreshingStaking: 'REFRESHING STAKING DATA...',
      refreshAgain: 'REFRESH AGAIN IN ',
      supportedTokens: 'SUPPORTED TOKENS',
      emptyNfts: `You don't have any NFTs yet`,
      emptyAssets: `YOU DON'T HAVE ANY CRYPTO ASSETS YET`,

      nftImageError: 'WE COULDN’t DISPLAY YOUR IMAGE',

      //Wallet page, central column:
      detailsCapitalLabel: 'Details',
      receive: 'receive',
      send: 'send',

      selectAsset: 'ASSET',
      searchAsset: 'Search asset...',
      selectNetwork: 'NETWORK',

      //Wallet page, right column:
      activityCapitalLabel: 'Activity',
      info: 'info',
      activity: 'activity',
      transactionsCapitalLabel: 'Transactions',
      transactions: 'transactions',
      records: 'records',
      volume: 'volume',
      circSupplyLabel: 'circ. supply',
      maxSupplyLabel: 'max. supply',
      exchangeAssetsLabel: 'Exchange assets',
      poweredByLabel: 'POWERED BY',
      supplied: 'supplied',
      borrowed: 'borrowed',
      apy: 'apy',
      staked: 'staked',
      effectiveYield: 'effective yield',
      economicHealth: 'economic health',
      in: 'in',
      investment: 'investment',
      investments: 'investments',
      interestEarnedLabel: 'interest earned',
      lending: 'lending',
      asset: 'asset',

      supplying: 'supplying',
      borrowing: 'borrowing',
      exchangeCapitalLabel: 'Exchange',
      bridgeCapitalLabel: 'Bridge',
      bridgeCampaignLabel: 'Bridge tokens into MELD network & claim your NFT.',
      details: 'details',
      purchased: 'Purchased',
      properties: 'Properties',
      address: 'Address',
      typeOrPasteAddressPlaceholder: 'Type or paste the address',
      searchAssetPlaceholder: 'Search asset',

      naLabel: 'N/A',
      verySafeLabel: 'VERY SAFE',
      safeLabel: 'SAFE',
      mediumRiskLabel: 'MEDIUM RISK',
      highRiskLabel: 'HIGH RISK',
      cautionLabel: 'CAUTION!',
      liquidationLabel: 'LIQUIDATION',

      policy: 'Policy',
      assetId: 'Asset ID',
      detailsTx: 'DETAILS TX',
      contract: 'Contract',

      marketInfoTile: {
        title: 'Market info',
        volume: '24h volume',
        marketCap: 'market cap',
        price: 'price',
        change: '% change',
      },

      bankSignupWidget: {
        text: 'Get your access for MELD.FI\na new kind of banking for crypto.',
        title: 'Neobank Early Access.',
        button: 'Get it now!',
      },

      bankSignupDrawer: {
        stepper: {
          step1: 'Select Payment',
          step2: 'Pay $1.00',
          step3: 'Get 100 $MELD welcome bonus',
        },
        accessCodeScreen: {
          title: 'Neobank Early Access',
          tooltipTitle: 'What is access code?',
          tooltipText:
            'If you verified your personal details at www.meld.fi you should have received the Access Code via email. Drop it here!',
          placeholder: 'Type access code',
          errorMessageInvalid: 'Invalid access code. Try entering it again.',
          errorMessageUsed: 'Access code already used.',
          link: 'Sign up to get access code',
        },
        paymentSelectionScreen: {
          title: 'Reserve Your Spot',
          subtitle: 'Pay $1 in any supported token and get 100 $MELD as our welcome bonus for you.',
          connectWalletSectionTitle: 'Connect wallet',
          supportedTokens: 'Supported tokens',
          availableBalanceSectionTitle: 'Available balance',
          insufficientBalanceSectionTitle: 'Insufficient balance',
          insufficientBalance: 'Insufficient balance',
          walletNotInstalled: 'Wallet extension not installed in your browser',
        },
        payScreen: {
          title: 'Reserve Your Spot',
          subtitle: 'Pay $1 in any supported token and get 100 $MELD as our welcome bonus for you.',
          amount: 'Amount',
          transactionFee: 'Transaction fee',
          time: 'Time',
          confirmButton: 'Confirm & Pay',
        },
        successScreen: {
          title: 'Congrats, spot reserved!',
          subtitle: "You will receive a confirmation email once we've processed your application.",
          prize: '100 MELD Tokens!',
          prize2: 'Rewards arriving into your wallet soon.',
          txDetails: 'See Details TX',
        },
        errorScreen: {
          title: 'Neobank Early Access',
          errorMessage1: 'Transaction failed',
          errorMessage2: 'You couldn’t send',
          button: 'Try again',
        },
      },
    },

    updateStakingDialog: {
      title: 'Staking Update',
      description: `We have improved the staking mechanism and require all users to update their staking NFTs. Please upgrade your NFTs and claim your accrued rewards to continue enjoying the benefits.`,
      errorMessage: `An error occured during the transaction process.\nPlease make sure you have enough gMELD in your wallet and try again.\nIf the issue persists, please contact support.`,
      completed: 'COMPLETED',
      buttonBack: 'RETURN TO WALLET',
      buttonUpgrade: 'UPGRADE NFTS',
      notEnoughFunds: 'You need more gMELD to complete the transaction.',
    },

    stakingPage: {
      summary: 'Staking Summary',

      stakableAssetsLabel: 'stakeable assets',
      stakedAssetsLabel: 'staked assets',

      rewardsComingSoon: 'Rewards for Epoch {{epoch}} will be distributed soon!',
      stakingErrorLoadingDataTitle: `Failed to load {{network}} staking data for {{walletName}} wallet`,
      stakingErrorLoadingDataMessage: `Something went wrong loading the data. Please do not worry as your funds are safe. \nTry refetching the page and if that does not work please contact support.`,

      selectPool: 'SELECT POOL',

      assets: 'ASSETS',
      poolAvailable: 'POOL AVAILABLE',
      pools: 'POOLS',
      poolsAvailable: 'POOLS AVAILABLE',
      networks: 'NETWORKS',
      asset: 'ASSET',
      pool: 'POOL',
      network: 'NETWORK',
      earnUpTo: 'EARN UP TO',
      earning: 'EARNING',
      full: 'FULL',
      filled: 'FILLED',
      ispo: 'ISPO',
      ispoRewardsPerEpoch: 'REWARDS PER MELD PER EPOCH',

      assetStaked: 'ASSET STAKED',
      assetsStaked: 'ASSETS STAKED',
      noAssets: 'NO ASSETS STAKED',

      poolInUse: 'POOL IN USE',
      poolsInUse: 'POOLS IN USE',
      noPools: 'NO POOLS IN USE',

      allStakedLabel: 'All Assets',
      allPoolsLabel: 'All Pools',
      stake: 'STAKE',
      stakingMeld: 'STAKING MELD',

      poolSize: 'POOL SIZE',

      realYield: 'REAL YIELD',
      offeredYield: 'OFFERED YIELD',

      livePoolStatus: 'LIVE POOL STATUS',
      availableRewards: 'AVAILABLE REWARDS',
      nextRewards: 'ESTIMATED NEXT REWARDS',
      rewardsHistory: 'REWARDS HISTORY',

      noRewards: 'YOU HAVE NO REWARDS YET',
      youStakedForPlural: 'YOU STAKED FOR {{value}} FULL EPOCHS',
      youStakedForSingular: 'YOU STAKED FOR {{value}} FULL EPOCH',

      topYieldingTokens: 'TOP YIELDING TOKENS',
      highestTVLPools: 'HIGHEST TVL POOLS',

      totalValueLocked: 'TOTAL VALUE LOCKED',
      yieldUpTo: 'YIELD UP TO',
      staking: 'staking',
      stakingSummary: 'Summary',
      stakingPool: 'staking pool',
      overview: 'Overview',
      totalStaked: 'TOTAL STAKED',
      staked: 'STAKED',
      totalValueStaked: 'total value staked',
      dailyRewards: 'DAILY REWARDS',
      totalRewards: 'TOTAL REWARDS',
      earnedRewards: 'earned rewards',
      currentYield: 'CURRENT YIELD',
      effectiveYield: 'EFFECTIVE YIELD',
      averageYieldText: 'AVERAGE YIELD',
      averageYield: '30-DAY AVERAGE YIELD',
      averageYieldShort: '30D AVG.',
      apr: 'APR',
      apy: 'APY',
      blocksLabel: 'Blocks',
      performance: 'PERFORMANCE',
      nextRewardsIn: 'NEXT REWARDS IN ',
      nextEpochIn: 'NEXT EPOCH IN ',
      unstakeIn: 'UNSTAKE IN ',
      claim: 'CLAIM',
      poolChangeIn: 'POOL CHANGE IN ',
      searchPools: 'Search pools',

      approveTokenAccess: 'APPROVE TOKEN ACCESS',

      pushingHard: 'PUSHING HARD TO LOAD STAKING DATA.\nPLEASE WAIT.',
      pushingHardLAndB: 'PUSHING HARD TO LOAD SUPPLY & BORROWING DATA.\nPLEASE WAIT.',

      rewardsCalculator: 'STAKING REWARDS CALCULATOR',
      rewardsCalculatorTooltip:
        'This tool lets you estimate potential returns from staking based on pool yield, amount of tokens staked, and duration.',
      poolYield: 'POOL YIELD',
      estRewards: 'EST. REWARDS',
      amountOfTokens: 'AMOUNT OF TOKENS',
      duration: 'DURATION (MONTHS)',
      compounding: 'COMPOUNDING',
      notCompounding: 'NOT COMPOUNDING',
      stakingAdaWarningText:
        'You will stake all your ADA. The rewards will be autocompounded. It initially takes 3 epochs until you get the rewards for staked ADA.',
      changeAdaPoolTooltip:
        'When you redelegate to another pool you will continue to receive staking rewards from your current pool until you start receiving rewards from your new stake pool.',
      stakeAdaTooltip:
        'Staking rewards will be directly paid to your wallet. You can change a staking pool any time. Rewards accrue after a full epoch, with distribution following two epochs later.',
      stakeMeldTooltip:
        'There are 4 tiers to choose from, each with different lock-up durations. Rewards can be claimed after a full epoch, and you have the flexibility to change your staking pool at any time.',
      unstakeWarningText:
        'You’ll unstake all your ADA and claim earned rewards from staking pool. You will no longer continue staking.',

      unstakeMeldWarningText:
        'Please be aware that unstaking now means you lose the rewards for the ongoing epoch. Rewards are earned only for full epochs.',

      visitPoolWebsiteLabel: 'visit pool’s site',

      stakedListEmpty: 'Your staked assets list is empty',
      stakedListEmptySubtitle: "You haven't staked any asset yet",

      poolsListEmpty: 'Your pools list is empty',
      poolsListSubtitle: "You haven't staked any assets in any pool yet",

      emptySingleAsset: `You don’t have THIS asset to stake`,
      emptyStakeableAssets: `You don't have any assets to stake`,
      emptyStakedAssets: `You haven't staked any assets yet`,

      checkStakeableAssets: 'CHECK STAKEABLE ASSETS',

      emptyAvailablePoolsTitle: '',
      emptyAvailablePoolsDescription: '',

      emptyYourPools: 'You haven’t staked any assets in any pool yet',

      emptySearchTitle: 'No staking pools found',
      emptySearchDescription: `Try refining your search terms or exploring different keywords to find the staking pool you're looking for. `,

      emptyDescription: `Currently there is no description available for this pool.\n\nTry to visit pool's website for more information.`,

      nextEpoch: 'NEXT {{value}} EPOCH STARTS IN',

      unstake: 'UNSTAKE',
      changePool: 'CHANGE POOL',

      unstaking: 'UNSTAKING',
      endsIn: 'ENDS IN',
      changingPool: 'CHANGING POOL',
      stakingStarts: 'EARNING STARTS',
      preparingToStake: 'PREPARING TO EARN',

      claimRewards: 'claim rewards',
      claiming: 'CLAIMING',

      //Staking page, right column:
      yourPoolsLabel: 'your pools',
      availablePoolsLabel: 'available pools',
      afterADayLabel: 'after a day',
      untitled: 'untitled',

      poolDetails: 'POOL DETAILS',
      saturation: 'saturation',
      stakedLabel: 'staked',
      inCurrentEpochLabel: 'in current epoch',
      delegators: 'delegators',

      avgPerEpoch: 'avg. per epoch',

      rewards: 'rewards',

      forecastedRewards: 'forecasted rewards',
      currentEpoch: 'CURRENT EPOCH',
      slashed: 'SLASHED',
      inactive: 'INACTIVE',
      earningStopped: 'EARNING STOPPED',
      ispoEnded: 'ISPO ENDED',
      nodeSecondWarning1: ` has two warnings and is in danger of being slashed.`,
      nodeSecondWarning2: ` If the pool is slashed, you are at risk of losing your staked assets.`,
      nodeIspoEnded: ` has ended. This pool has been removed.`,
      nodeSlashed: ` has been slashed.`,
      stakeSlashed: ` Your stake has been slashed by {{value}}%.`,
      stakeNotAffected: ` Your stake has not been affected.`,
      nodeInactive1: ` left the MELD network. This pool has been removed.`,
      nodeInactive2: ` Please stake to another pool.`,
      reasonForSlashing: 'REASON FOR SLASHING',
      warning: 'Warning #{{value}}: ',
      slashedBy: 'SLASHED BY',

      poolInfo: 'pool info',
      poolMargin: 'pool margin',
      poolFixedFee: 'pool fixed fee',
      rewardsFee: 'rewards fee',
      pledge: 'PLEDGE',
      yourShare: 'YOUR SHARE',
      yieldByTiers: 'YIELD BY TIERS',
      tier: 'tier',
      liquid: 'Liquid (no lockup)',
      noLockup: 'No lockup',

      // Dev
      oneDay: '1 Day',
      threeDays: '3 Days',
      sixDays: '6 Days',
      oneDayEpochText: '1-DAY EPOCH',
      // Prod
      sixMonths: '6 months',
      oneYear: '1 year',
      fiveYears: '5 years',
      fiveDayEpochText: '5-DAY EPOCH',

      warnings: 'WARNINGS',
      pledgeLockup: 'PLEDGE LOCKUP',
      uptime: 'UPTIME',
      identityVerified: 'IDENTITY VERIFIED',
      followers: 'FOLLOWERS',
      location: 'LOCATION',
      epoch: 'epoch',
      potentialRewardsPerEpoch: 'potential rewards per epoch',
      last30Days: 'LAST 30 DAYS',
      greatPerformance: 'Great performance',
      fairPerformance: 'Fair performance',
      riskOfSlashing: 'Risk of slashing',
      poolReputation: 'POOL REPUTATION',
    },

    //SUPPLY & BORROW PAGE
    supplyAndBorrowPage: {
      supply: 'supply',
      supplyMore: 'supply more',
      borrow: 'borrow',
      borrowMore: 'borrow more',
      withdraw: 'withdraw',
      manageCollateral: 'manage collateral',

      // Summary/overview card
      summary: 'Summary',
      assetsSupplied: `{{value}} assets supplied`,
      activeLoans: `{{value}} active loans`,
      netWorth: 'net worth',
      effectiveYield: 'effective yield',
      aggregatedYield: 'aggregated yield',
      ltv: 'LTV',
      daily: 'DAILY',
      temporarilyPaused: 'Temporarily paused',
      vaultClosed: 'Vault closed',

      totalSuppliedLabel: 'total supplied',
      supplyBreakdown: 'supply breakdown',
      supplyBreakdownTooltip:
        'Visual representation of your total supplied assets, highlighting the top 3 assets based on their value.',

      youCanBorrow: 'YOU CAN BORROW',
      riskModel: 'RISK MODEL',
      riskModelTooltip1:
        'Specific values assigned to each asset within the MELD protocol, influencing their supply and borrowing dynamics.  Borrowing involves over-collateralization with different assets, each subject to its own volatility.',
      riskModelTooltip2:
        'These parameters help manage market risks associated with the supported assets. Visit MELD docs for more details.',

      supplyTvl: 'SUPPLY TVL',
      supplyApy: 'SUPPLY APY',
      borrowTvl: 'BORROW TVL',
      borrowInt: 'BORROW INT.',

      maxLtv: 'MAX LTV',
      liqThreshold: 'LIQ. THRESHOLD',
      penalty: 'PENALTY',
      learnMore: 'LEARN MORE',
      vaultSaturation: 'vault saturation',
      vaultSaturationTooltip:
        "The total supplied funds compared to the vault's maximum capacity. At 100% saturation, the vault can't accept additional deposits.",
      vaultUtilization: 'vault utilization',
      vaultUtilizationTooltip:
        'The amount borrowed compared to the total supplied assets. Utilization of 100% indicates that all available funds have been borrowed.',
      vaultPerformance: 'vault performance',
      supplyYield: 'supply yield',
      supplyYieldTooltip:
        'Each asset has its own market of supply and demand with its own yield. The higher the utilization of a Vault the higher the yield for suppliers.',
      borrowInterest: 'borrow interest',
      borrowInterestTooltip1:
        'Each asset has its own market of supply and demand with its own interest rates. The lower the utilization of a Vault, the lower the rates for borrowers, to incentivize taking loans.',
      borrowInterestTooltip2:
        'The stable rate, if offered, provides predictability for the borrower. However, it comes at a cost, as the interest rates are higher than the variable rate.',
      upToMonthly: 'up to {{- value}}/monthly',
      variableRate: 'variable rate',
      stableRate: 'stable rate',
      interestRate: 'interest rate',
      monthly: 'monthly',
      supplied: 'supplied',
      vault: 'Vault',
      switchTo: 'switch to {{walletName}}',
      loanHistory: 'loan history',
      totalCost: 'total cost',

      //Supply&Borrow page, right column:
      canSupplyLabel: 'can supply',
      canBorrowLabel: 'can borrow',
      repay: 'repay',
      variableYieldRateLabel: 'variable yield rate',
      allBorrowedLabel: 'All Borrowed',
      totalBorrowed: 'total borrowed',
      yield: 'yield',
      collateral: 'collateral',
      collateralTooltip:
        'The total value of your supplied assets as collateral that you can borrow against. You can choose which assets to use as collateral, helping you manage risk.',
      availableToSupply: 'available to supply',
      availableToBorrow: 'available to borrow',
      noAssetsToSupply: "You don't have any assets to supply",
      noAssetsToBorrow: "You don't have any assets to borrow",

      historicalBorrowInterest: 'historical borrow interest',
      last90Days: 'in the last 90 days',

      historicalSupplyYield: 'historical supply yield',

      liquidationNotification90_1: 'You are close to being liquidated! ',
      liquidationNotification90_2: 'Please supply more collateral or repay a portion of your active loans.',
      liquidationNotification100_1: 'You were affected by a liquidation! ',
      liquidationNotification100_2:
        'Part of your collateral was seized to repay active loans when your risk factor reached 100/100. ',
      liquidationNotification100_3: 'Read more',

      interest: 'interest',
      riskFactorLabel: 'risk factor',
      riskFactorTooltip1:
        'The numeric representation of the safety of your supplied collateral against the borrowed assets and its underlying value.',
      riskFactorTooltip2:
        'The closer your Risk Factor is to 100, the closer you are to liquidation. This might happen when the collateral decreases in value or total borrowed increases in value against each other.',
      asset: 'asset',
      supplyRate: 'supply rate',
      borrowVarRate: 'borrow var. rate',
      borrowStRate: 'borrow st. rate',
      borrowPower: 'borrow power',
      borrowPowerTooltip:
        'The maximum loan amount you can take against your supplied collateral, up to 100% of the power.',
      borrowPowerTooltip2:
        'The maximum loan value you can borrow from this Vault against your total collateral locked.',
      quickView: 'quick view',
      borrowed: 'Borrowed',
      collateralUpper: 'Collateral',
      liquidation: 'liquidation',
      liquidationPoint: 'liquidation point',
      liquidationPointTooltipTitle: 'liquidation point',
      liquidationPointTooltipDescription1:
        'Indicates the max threshold that your LTV can reach until liquidation. The point moves regarding:',
      liquidationPointTooltipBullet1: 'Increase in value of the assets you borrowed',
      liquidationPointTooltipBullet2: 'Decrease in value of your collateral supplied',
      liquidationPointTooltipDescription2:
        'Your current loan-to-value based on your collateral worth supplied. Mind the liquidation threshold.',
      yourLtv: 'Your LTV:',
      averageLiquidationThreshold: 'LIQUIDATION:',
      readMore: 'Read more',
      quickViewTooltip1:
        'The visualized balance between all of your assets supplied as the collateral and the $ value of all active loans taken against it, with a projected liquidation threshold.',
      quickViewTooltip2: 'Make sure you keep an eye on the Risk Factor.',
      marketPrice: 'market price',
      liquidationPrice: 'liquidation price',
      last24h: 'last 24h',
      stats: 'stats',
      since: 'since',
      youEarned: 'you earned',
      assetPerformance: 'asset performance',
      assetStatus: 'asset status',
      assetStatusTooltip1:
        'Toggle to enable or disable this asset as collateral. Only assets that are locked as collateral can be used to borrow.',
      assetStatusTooltip2: 'Please mind the risk of liquidation and monitor the Risk Factor.',
      locked: 'Locked',
      notLocked: 'Not locked',
      asCollateral: 'as collateral',
      totalSuppliedCollateral: 'Total supplied collateral',
      suppliedAsCollateral: '{{value}} supplied as collateral',
      totalBorrowed2: 'Total borrowed',
      assetBorrowed: '{{value}} borrowed',
      supplyToBorrowTooltip: 'Supply collateral to borrow',
      noAssetToRepayTooltip: 'Insufficient wallet balance',
    },

    // *** //

    //GOVERNANCE PAGE
    governancePage: {
      'upcoming mip': 'upcoming mip',

      //Governance page, central column:
      against: 'against',
      for: 'for',
      summaryCapitalLabel: 'Summary',
      abstract: 'Abstract',
      motivation: 'Motivation',
      mipVotedLabel: 'mip voted',
      meldPointsLabel: 'meld points',
      rankCapitalLabel: 'Rank',
      rank: 'rank',
      votes: 'votes',
      points: 'points',
      votingPowerLabel: 'voting power',
      propositionPowerLAbel: 'proposition power',

      //Governance page, right column:
      votingHistoryLabel: 'voting history',
      proposition: 'proposition',
      writeAPropositionLabel: 'Write a proposition',
      earnMeldPointLabel: 'Earn MELD points writing a proposition',
      propositionsMadeLabel: 'propositions made',
      pointsEarnedLAbel: 'points earned',
    },
    //Governance page, left column:

    // *** //

    // DRAWERS

    // all drawers components:
    risk: 'risk',
    yieldBoostLabel: 'yield boost',
    APY: 'APY',
    collateralization: 'collateralization',
    healthFactorLabel: 'health factor',
    transactionFeeLabel: 'transaction fee',
    transactionSuccessful: 'TRANSACTION SUCCESSFUL',
    transactionFailed: 'TRANSACTION FAILED',
    transactionInProgress: 'TRANSACTION IN PROGRESS',
    transactionNotice: 'TRANSACTION NOTICE',

    notEnoughAssets: `It seems you don't have enough assets to cover the transaction fee.`,
    notEnoughTokens: `You don't have enough {{token}} in your wallet.`,
    notEnoughAssetsForAction: `Insufficient {{value}}. Please add more {{value}} to your wallet.`,
    notEnoughBalanceForAction: `You have not supplied enough {{value}} to perform this action.`,
    declinedTransaction: "You've declined to sign the transaction.",
    fromExtWallet: 'from {{value}}',
    you: 'you',
    detailsInCapitalLabel: 'DETAILS',
    availableInCapitalLabel: 'AVAILABLE',
    available: 'available',
    maxInCapitalLabel: 'MAX',
    unknownError: 'Unknown error. Please try again.',
    approvalFee: 'Approval fee',

    yield: 'yield',

    // Borrow drawer:
    confirm: 'confirm',

    exceedsBalanceError: 'Exceeds balance',
    maxNumberOfDecimalsError: 'Maximum {{value}} decimal places allowed',

    // ***if this message is updated please update the function getBetterErrorMessage()***
    notEnoughNativeToken: 'More {{value}} needed to cover transaction fees',
    invalidAddress: 'Invalid address',
    wrongNetwork: 'Please connect {{value2}} to {{value}}',
    overSaturation: 'The maximum available to stake in this pool is {{amount}}.',
    tooLowForStakingTier: 'The minimum amount to stake is {{amount}}.',

    // Stake drawer:
    stake: 'stake',
    stakeNow: 'stake now',
    stakingPool: 'staking pool',
    successStake: 'You initiated delegation to {{pool}} staking pool.',
    successClaimRewards: 'You claimed {{value}} {{token}}',
    failureStake: 'You couldn’t stake {{value}} {{token}}',
    failureClaim: 'You couldn’t claim {{token}}',
    failureClaimFewer: 'You couldn’t claim {{token}}. Please try selecting fewer NFTs.',
    failureChangePool: 'You couldn’t change pool delegation to {{pool}}',
    successUnstake: 'You unstaked from {{pool}} staking pool',
    successUnstakeMeld: 'You unstaked {{value}} {{token}}',
    stakingNFTBurned: 'Your staking NFT has been burned',
    verifying: 'Verifying...',
    locked: 'LOCKED',
    failureUnstake: 'You didn’t unstake from {{pool}} staking pool',
    successChangePool: 'You initiated restake to {{pool}} staking pool',
    successChangePoolMeld: 'You changed staking pool to {{pool}}',
    availableRewards: 'AVAILABLE REWARDS',
    stopStakingIn: 'Stop staking in',
    stakingKeyDeposit: 'Staking key deposit',
    immediately: 'Immediately',
    pendingRewards: 'Pending Rewards',
    claimRewardsWarning:
      'Rewards from staking ADA auto-compound every epoch. We recommend you only claim rewards if you wish to withdraw.',
    pendingRewardsWarning:
      'You will lose pending rewards, which otherwise would be paid to your wallet in the next 2 epochs.',
    registerWalletForStaking: 'Register wallet & stake',
    registrationInProgress: 'Registration in progress',
    changeDelegationIn: 'Change Delegation In',
    delegationInfo: 'The change of delegation will take effect after the current and next epoch have finished.',
    usedAsCollateralLabel: 'used as collateral',
    total: 'total',
    poolInfoLabel: 'pool info',
    cooldown: 'cooldown',
    earnings: 'earnings',
    selectPool: 'Select pool',
    registrationFees: 'REGISTRATION FEES',
    fiveDays: '5 D',
    thirtyDays: '30 D',
    ninetyDays: '90 D',

    rewardPeriod: 'REWARD PERIOD (EPOCH)',
    currentYield: 'Current Yield',
    startStakingIn: 'Start Staking In',
    estimateRewards: 'ESTIMATE REWARDS',
    youCanGet: 'You can get',
    // Transactions drawer:
    transactionDetails: 'Transaction details',
    amount: 'amount',
    youReceive: 'you receive',
    date: 'date',
    fees: 'fees',
    searchByAddressAssetLabel: 'Search by address, asset...',
    noTransactionsFoundLabel: 'No transactions found',

    // Send drawer:
    seeTransactionDetails: 'SEE DETAILS TX',
    youSent: 'You sent {{amount}} {{token}}',
    sendInCapitalLabel: 'SEND',
    transactionFeeInCapitalLabel: 'TRANSACTION FEE',
    timeLabel: 'TIME',
    amountLabel: 'AMOUNT',
    totalInCapitalLabel: 'TOTAL',
    nameLabel: 'NAME',
    selectAsset: 'Select an asset',
    stakedAmount: 'STAKED AMOUNT',
    claimFewerNfts: 'Please try again selecting fewer NFTs.',

    // Withdraw drawer:
    withdrawn: 'withdrawn',
    remainingSupply: 'remaining supply',

    // ADA collateral info (user menu)
    collateralMenuAdd: 'Set ADA Collateral',
    collateralMenuSet: 'ADA Collateral',
    collateralMenuTooltipQuestion: 'What is ada collateral?',
    collateralMenuTooltipAnswer:
      'In order to interact safely with smart contracts on Cardano you need to add some collateral. The recommended collateral is 5 ADA which will be part of your main balance. You do not lock any assets.',
    collateralMenuSettingCollateral: 'Setting collateral',

    // Repay drawer:
    repayed: 'repaid',

    youDoNotHaveEnoughToRepay:
      'You dont have enough {{token}} to repay your loan. Please try again with a lower amount.',

    adaCollateralDrawer: {
      title: 'Set collateral',
      btnReview: 'Review',
      amount: 'Amount',
      reviewDescription:
        'Setting collateral to interact with smart contracts on Cardano Blockchain (e.g. swap, bridge, send, migrate MELD tokens) is a way to keep your funds safe and protect the network.',
      oneMin: '1 min',
      btnAddCollateral: 'Set collateral',
      successMsg: 'You set 5.00 ADA',
      insufficientBalance: 'insufficient balance',
      btnContinueToMigration: 'Continue to migration',
      waitForTx: 'Please wait a moment while we redirect you to migrate your old MELD tokens.',
    },

    // Token migration drawer
    tokenMigrationTitle: 'Token Migration',
    return: 'RETURN',
    approveMigration: 'Approve Migration',
    tokensMigrateSuccess: 'You migrated your tokens',
    tokensMigrateFail: 'You couldn’t migrate your tokens',
    transactionCost: "we'll deduct {{value1}} ADA and repay you {{value2}} ADA afterwards",
    transactionFeeMessage: 'Estimated fee will be deducted\nin advance, and unused refunded afterwards',
    tryAgain: 'TRY AGAIN',

    // Token migration Dialog
    tokenMigrationDialog: {
      title: 'Migrate MELD tokens',
      alt: 'Token migration',
      description:
        'We are upgrading the MELD token to be supported on multiple blockchains (ADA, ETH, AVAX, MATIC, BSC, MELD). When you upgrade your tokens they will be converted 1 to 1 for the new token. This upgrade will cost around 2.3 ADA per wallet but we will return 1.8 ADA per wallet to you on completion.',
      btn_upgrade: 'Upgrade Now',
      oldMeldDetected:
        'You have the old MELD token in your Cardano wallet. We are going to upgrade it to be supported on multiple blockchains.',
    },

    // Cardano Wallet Upgrade Dialog
    cardanoWalletUpgrade: {
      title: 'Cardano Wallet Upgrade',

      dialog: {
        description:
          'We have upgraded the Cardano wallet. Now you need to migrate all of your assets to the new wallet in order to unlock more upcoming features like staking and rewards yield.',
        btn_upgrade: 'MIGRATE NOW',
      },
      widget: {
        description1: 'Migrate ',
        description2: 'all your assets ',
        description3: 'on the Cardano\nnetwork to the new upgraded wallet.',
      },
    },

    walletUpgradeDrawer: {
      title: 'TOKEN MIGRATION',
      button: 'MIGRATE NOW',
    },

    // Token migration Widget
    tokenMigrationWidget: {
      title: 'Meld Token Upgrade',
      description: '1 for 1 upgrade your MELD tokens\nto support multiple blockchains.',
      btn_upgrade: 'Upgrade Now',
      btn_upgrading: 'Upgrading',
      btn_settingCollateral: 'Setting collateral',
    },

    pendingTransactionsDrawer: {
      title: 'Transactions Queue',
    },

    sendNftDrawer: {
      sent: 'You sent ',
      total: 'NFT',
      couldntSend: `You couldn't send 1 NFT`,
      selectNft: 'SELECT NFT',
    },

    lendingAndBorrowingDrawer: {
      meldWallet: 'meld wallet',
      // supply
      supplyAPY: 'SUPPLY APY',
      useAsCollateral: 'USE AS COLLATERAL',
      useAsCollateralTooltip:
        'Toggle to enable or disable this asset as collateral. Only assets that are locked as collateral can be used to borrow against.',
      riskFactor: 'RISK FACTOR',
      riskFactorTooltip:
        'All actions taken, such as supplying assets, withdrawing funds, borrowing, or repaying loans, may affect your overall Risk Factor. This factor is crucial for maintaining the health of your collateral and avoid liquidation.',
      suppliedSuccessful: 'You supplied {{value}} {{token}}',
      suppliedFailed: 'You couldn’t supply {{token}}',

      loan: 'LOAN',
      supplied: 'SUPPLIED',
      maxAvailable: 'MAX AV.',

      // risk factor
      riskFactorWarning:
        'Your remaining collateral will be close to the liquidation threshold. Please proceed with caution!',
      riskFactorTooHigh:
        'Your risk factor will be too high and your collateral might be liquidated. Try to {{action}} a lower amount, if possible.',
      riskFactorTooHighCollateral:
        'Your risk factor will be too high and your collateral might be liquidated. You can’t turn off this asset as a collateral.',

      // borrow
      interestType: 'INTEREST TYPE',
      variable: 'VARIABLE',
      stable: 'STABLE',
      stableBorrowingNotEnabled: 'Stable borrowing is not enabled for this asset.',
      notEnoughLiquidity: 'The inputted amount is greater than the available liquidity.',
      noLiquidity: 'No liquidity available in this Vault. Please try again later.',
      collateralCannotCoverNewBorrow: 'You dont have enough collateral to borrow this asset.',
      borrowInterest: 'BORROW INTEREST',
      borrowedSuccessful: 'You borrowed {{value}} {{token}}',
      borrowedFailed: 'You couldn’t borrow {{token}}',

      // repay
      repaySuccessful: 'You repaid {{value}} {{token}}',
      repayFailed: 'You couldn’t repay {{token}}',

      // withdraw
      withdrawSuccessful: 'You withdrew {{value}} {{token}}',
      withdrawFailed: 'You couldn’t withdraw {{token}}',

      // manage collateral
      updateCollateral: 'UPDATE COLLATERAL',
      updateMoreCollateralSuccessful: 'Your {{token}} is now used as a collateral',
      updateLessCollateralSuccessful: 'Your {{token}} is no longer used as a collateral',
      updateCollateralFailed: 'You couldn’t update your {{token}} collateral',
    },

    bridgeDrawer: {
      title: 'BRIDGE',
      toExternalAddress: 'TO EXTERNAL ADDRESS',
      enterExternalAddress: 'Enter external address',
      invalidAddress: 'Invalid address',
      review: 'REVIEW',
      approveMessage:
        'You need to grant permission to our partner ChainPort to bridge your token balance. This action will set a spending limit.',
      approveButton: 'APPROVE BRIDGE AMOUNT',
      recipient: 'RECIPIENT',
      bridgingCost: 'BRIDGING COST',

      bridging: 'You’re bridging {{value}}',
      bridged: 'You bridged {{value}}',
      bridgent: 'You couldn’t bridge ',
      externalAddress: 'External address',
      userWallet: 'Your {{value}} wallet',
      upTo5Minutes: 'Please wait while we transfer your assets to the\nselected network. It may take several minutes.',
      confirmations: 'Confirmations {{value}}/{{value2}}',
      congrats: 'Congrats!',
      collectReward: 'Collect your reward.',
      claimNft: 'CLAIM NFT',
      bridgingInProgress: 'Bridging in progress!',
      bridgingCompleted: 'Bridging completed!',
      clickToSeeMore: 'Click to see more details.',
      emptyState: 'You don’t have any assets to bridge',
      youMustHaveAtLeastToBridge: 'You must have at least {{amount}} {{token}} to bridge.',
      checkAssets: 'Check bridgeable assets ',

      supportedTokens: 'SUPPORTED TOKENS',
      supportedTokensDescription: 'You can find the full list of tokens for which we support bridging ',
      supportedTokensDescription2: 'here',
      liquidityWarning: 'Significant bridge request',
      liquidityNotice:
        'You are trying to bridge a large amount of MELD tokens and there is no sufficient liquidity on the {{destinationChain}} network. Please be aware that accepting your bridging request requires a multi-sig approval from both the ChainPort and MELD Teams. It can take up to 2 business days.',
      otcDealBtnText: 'CONTINUE WITHOUT OTC DEAL',
      otcDealDescription:
        'To the MELD 🐋s fishing around for a good bridge deal, we have opened a private channel to bridge amounts of 1M+ MELD with us. We’re offering favorable bridging fees. Request by sending an email here 👉 <1>{{email}}</1> and we will support you there.',
    },

    meldWrapperDrawer: {
      wrapGmeld: 'WRAP gMELD',
      unwrapMeld: 'UNWRAP MELD',
      couldntWrap: `You couldn't wrap gMELD`,
      couldntUnwrap: `You couldn't unwrap MELD`,
      received: 'You received ',

      tooltipTitleWrap: 'WHAT IS WRAPPING?',
      tooltipSubtitleWrap:
        'It converts your gMELD into MELD, adding more utilities! gMELD is used for transaction fees, while MELD allows you to stake, bridge, borrow, buy NFTs, etc.',
      tooltipLink: 'Read more',
      tooltipTitleUnwrap: 'WHAT IS UNWRAPPING?',
      tooltipSubtitleUnwrap:
        'It converts your MELD back into gMELD, allowing you to use gMELD for transaction fees, while MELD remains available for staking, bridging, etc.',
    },

    wrap: 'wrap',
    unwrap: 'unwrap',

    showSeedPhraseDialog: {
      secretCode: 'Log in to show seed phrase',
      placeholder: 'Enter your password',

      description:
        'Here is your seed phrase of 24 words to secure your wallet and recover it when needed.\nWe recommend writing it down on paper and not storing it digitally.\n\nNever disclose your backup phrase!',
    },

    // *** //

    showSeedPhraseLabel: 'Show seed phrase',
    seedPhrase: 'Seed phrase',

    showSeedPhraseTooltipQuestion: 'WHAT IS SEED PHRASE?',
    showSeedPhraseTooltipAnswer:
      'Seed phrase is the most critical aspect of your crypto wallet. Forgetting your password or losing your device could result in permanent loss of wallet access and all your assets - you may only recover your account by entering the 24-word seed phrase.',

    refreshWallet: 'Manually refresh wallet balance',

    upgradeToSelfCustody: 'Enable self-custody',
    upgradeToSelfCustodyTooltipQuestion: 'WHAT DOES IT MEAN?',
    upgradeToSelfCustodyTooltipAnswer:
      'This process will improve your crypto wallet security and transition from social sign-in to self custody. Self custody will provide you with full control over your wallet by requiring you to generate and securely store a 24-word seed phrase.',
    // COMPONENTS

    // GovernanceCard, Mip:
    endsInLabel: 'ends in',
    share: 'share',

    // GovernanceCard, Overview:
    interestRateLabel: 'interest rate',

    // MipStatus:
    active: 'active',
    cancelled: 'cancelled',
    executed: 'executed',
    pending: 'pending',
    rejected: 'rejected',

    // NFTOverviewCard:
    on: 'on',
    network: 'network',
    networks: 'networks',

    // SupplyAndBorrowOverviewCard, Overview:
    netWorthLabel: 'net worth',
    netApy: 'net apy',
    riskFactor: 'risk factor',
    assetsLentLabel: 'Assets lent',
    assetsBorrowedLabel: 'Assets borrowed',

    // SupplyAndBorrowOverviewCard, Supply:

    // VotingStatus:
    votingRights: 'Voting Rights',
    proposalRights: 'Proposal Rights',
    For: 'For',
    Against: 'Against',

    // Validation

    eightCharactersLabel: 'Eight characters',
    oneUppercaseLabel: 'One uppercase',
    oneNumberLabel: 'One number',
    specialCharacterLabel: 'One special character',
    matchesEnteredSecretCode: 'Matches entered password',
    matchesEnteredPassword: 'Matches new password',
    differentThanCurrentPassword: 'Different than current password',
    canDecryptPasswordPrivateKey: 'Decrypts private key',

    passwordRuleLabel:
      'Must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character',
    securityQuestionRuleLabel: 'Answer is required',
    codeRuleLabel: 'Access code is required',

    fee: 'FEE',
    review: 'REVIEW',

    price: 'PRICE',
    plusFee: ' + Fee',
    minusFee: ' - Fee',

    from: 'FROM',
    to: 'TO',
    in: 'IN',

    balance: 'Balance',
    reject: 'REJECT',
    accept: 'ACCEPT',

    asset: 'ASSET',
    assets: 'ASSETS',
    pool: 'POOL',
    pools: 'POOLS',

    transactionsInfoTitle_one: '{{count}} Transaction pending',
    transactionsInfoTitle_other: '{{count}} Transactions pending',
    transactionsInfoSubTitle: 'Open queue',

    externalWallets: {
      selectCorrectNetwork: `Please select the correct network ({{value}}) in {{value2}}`,
      connectAgain: ` and then connect again.`,

      loadingData: `Loading data for your external {{value}} wallet ({{value2}}).`,
      loadedData: `Loaded data for your external {{value}} wallet ({{value2}}).`,

      connectEternl: 'Please connect an account within Eternl first and then try to connect again.',
      changedEternl: `We've detected an account change in Eternl, please reconnect manually once you've selected a new account.`,
    },
  },
};
