import { useStore } from '@store/store';
import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';

export const useIsBridging = () => {
  const { startedAt, completedStep, bridgeFailed } = useStore((state) => state.bridgeData, shallow);

  const isBridging = useMemo(
    () => startedAt && completedStep !== 3 && !bridgeFailed,
    [startedAt, completedStep, bridgeFailed],
  );

  return isBridging;
};
