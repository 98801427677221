import { CSSProperties, FC, ReactElement, ReactNode } from 'react';

import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import { mdiInformationOutline } from '@mdi/js';
import { Icon } from '@mdi/react';

type TooltipComponentProps = {
  content: ReactNode;
  withArrow: boolean;
  arrowPlacement: TooltipProps['placement'];
  backgroundElement?: ReactNode;
  backgroundElementStyles?: CSSProperties;
  disabled?: boolean;
  maxWidth?: CSSProperties['maxWidth'];
  children?: ReactElement;
};

export const Tooltip: FC<TooltipComponentProps> = ({
  content,
  withArrow = true,
  arrowPlacement = 'left',
  backgroundElement,
  backgroundElementStyles,
  disabled = false,
  children,
}) => {
  const tooltipContent = (
    <div style={{ position: 'relative' }}>
      {backgroundElement && (
        <div
          style={
            backgroundElementStyles
              ? backgroundElementStyles
              : { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }
          }
        >
          {backgroundElement}
        </div>
      )}
      <div style={{ position: 'relative' }}>{content}</div>
    </div>
  );

  return (
    <MuiTooltip
      slotProps={{
        tooltip: {
          className:
            'bg-meldblack rounded-lg p-[18px] text-meldwhite/80 font-semibold text-[11px] max-w-[190px] tracking-[0.44px]',
        },
        arrow: { className: 'text-meldblack' },
      }}
      disableHoverListener={disabled}
      title={tooltipContent}
      arrow={withArrow}
      placement={arrowPlacement}
    >
      {children ? children : <Icon path={mdiInformationOutline} className="h-[16px] w-[16px]" />}
    </MuiTooltip>
  );
};
