import { useEffect } from 'react';

export const useOverrideWcColors = () => {
  useEffect(() => {
    // override some web3modal colors
    document.documentElement.style.setProperty('--wui-color-fg-100', '#ffffff');
    document.documentElement.style.setProperty('--wui-gray-glass-002', '#ffffff1f');
    document.documentElement.style.setProperty('--wui-gray-glass-005', '#ffffff3f');
    document.documentElement.style.setProperty('--wui-gray-glass-010', '#ffffff78');
    document.documentElement.style.setProperty('--wui-color-fg-200', '#ffffffc9');
    document.documentElement.style.setProperty('--wui-color-error-100', 'white');
    document.documentElement.style.setProperty('--wui-color-fg-275', '#ffffff94');
    document.documentElement.style.setProperty('--wui-color-bg-200', '#ffffff94');
  }, []);
};
