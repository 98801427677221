import { sbAssetsObj } from '@utils/assets-helper';
import getUserLocale from 'get-user-locale';
import { useStore } from '@store/store';

/**
 * Default number of decimals for fiat amounts
 */
const DEFAULT_FIAT_DECIMALS = 2;

/**
 * Max significant amount of digits to display token prices - shows 0.00001212 vs 0.00001
 */
const FIAT_AMOUNT_MAX_SIGNIFICANT_DIGITS = 4;

/**
 * Max amount of decimals to display token prices
 *
 * @remarks
 * Bypasses FIAT_AMOUNT_MAX_SIGNIFICANT_DIGITS if needed, as all numbers we would otherwise
 * display for token prices will be truncated to have at most the number of decimals specified here.
 */
const MAX_TOKEN_PRICE_DECIMALS = 12;

/**
 * Max number of decimals to use for crypto amounts in different parts of the app
 */

const MAX_DECIMALS_ASSETS_LIST = 5;
/**
 * Max used for crypto amounts in other app locations
 * */
const MAX_TOKEN_AMOUNT_DECIMALS = 6;
/**
 * Default number of decimals to use for crypto amounts
 */
const DEFAULT_TOKEN_AMOUNT_DECIMALS = 2;

const getMostDecimalsToUse = () => {
  return MAX_DECIMALS_ASSETS_LIST;
};

/**
 * @param value number to format
 * @returns formatted number.
 *
 * @example
 * This function returns a value with at most 12 decimal places. Ex: $0.000000000032
 * It returns a value with 2 decimal places if the provided param is larger than 1. Ex: $1.32
 * It returns a value for a param smaller than 1 using up to 4 significant digits. Ex: 0.0000003245
 */
const formatMarketPrice = (value: number): string => {
  const numberOfZerosUntilValue = calculateZerosAfterDecimalPoint(value);

  const decimalsToUse =
    numberOfZerosUntilValue >= DEFAULT_FIAT_DECIMALS || value < 1
      ? numberOfZerosUntilValue + FIAT_AMOUNT_MAX_SIGNIFICANT_DIGITS
      : DEFAULT_FIAT_DECIMALS;

  const valueToFixed = toFixedNoRounding(value, decimalsToUse);

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    // 21 is the max: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Precision_range
    //19 works fine for us as usually the max amount of decimals is at most 18
    maximumFractionDigits: Math.max(decimalsToUse, MAX_TOKEN_PRICE_DECIMALS),
  }).format(valueToFixed);
};

/**
 * @param value number to format
 * @returns The provided param with up to 2 decimals or <$0.01 if the given param is less than 0.01.
 */
const formatCurrency = (value: number): string => {
  const valueToFixed = toFixedNoRounding(value, DEFAULT_FIAT_DECIMALS);

  const formmatedValue = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: DEFAULT_FIAT_DECIMALS,
  }).format(value);

  if (valueToFixed == 0 && value < 0.01 && value !== 0) {
    return '< $0.01';
  }

  return formmatedValue;
};

/**
 * @param value number to format
 * @returns The provided param with up to 2 decimals or <$0.01 if the given param is less than 0.01.
 *
 * @todo refactor to use tokenId instead of the tokenSymbol
 */
const formatCryptoBalance = (
  value: number | string,
  token?: string,
  displayToken?: boolean,
  maxNumberOfDecimals?: number,
) => {
  const internalValue = +value;

  if (isNaN(internalValue)) {
    return '-';
  }

  if (internalValue === 0) return `0.00${token && displayToken ? ` ${token}` : ''}`;

  const numberOfZerosUntilValue = calculateZerosAfterDecimalPoint(internalValue);

  /**
   * decimals to use are DEFAULT_TOKEN_AMOUNT_DECIMALS if the location is generic
   * decimals to use are what calling getMostDecimalsToUse(location) returns if the provided value is smaller than 1
   * decimals to use are the specified number of decimals to use for a specific token according to the API (/tokens) OR
   * DEFAULT_TOKEN_AMOUNT_DECIMALS if no number of decimals is specified for that token
   */
  let decimalsToUse =
    Math.abs(internalValue) < 1
      ? getMostDecimalsToUse()
      : token
        ? sbAssetsObj?.[token]?.amountDecimals ?? DEFAULT_TOKEN_AMOUNT_DECIMALS
        : DEFAULT_TOKEN_AMOUNT_DECIMALS;

  /**
   * In case the amount we calculated above surpasses the amount of decimals the tokens has
   * then we use the amount of decimals the token has
   */
  if (token && sbAssetsObj?.[token]?.decimals < decimalsToUse) {
    decimalsToUse = sbAssetsObj?.[token]?.decimals;
  }

  // truncate number of decimals on the provided value
  let valueToFixed = toFixedNoRounding(internalValue, decimalsToUse);

  // if the above is 0 then try again using MAX_TOKEN_AMOUNT_DECIMALS if its larger than "decimalsToUse"
  if (!valueToFixed && decimalsToUse < MAX_TOKEN_AMOUNT_DECIMALS) {
    decimalsToUse = MAX_TOKEN_AMOUNT_DECIMALS;
    valueToFixed = toFixedNoRounding(internalValue, MAX_TOKEN_AMOUNT_DECIMALS);
  }

  /**
   * 0.012 would display as 0.01 so here we add an extra decimal to better represent the number
   *
   * @remarks
   * we only add the extra number if the location is "generic" and the resulting number of decimals does not go over the predefined
   * MAX_TOKEN_AMOUNT_DECIMALS or if a "token" is passed, does not go over its own specified "amountDecimals" if any
   */
  if (
    (numberOfZerosUntilValue === DEFAULT_TOKEN_AMOUNT_DECIMALS - 1 ||
      numberOfZerosUntilValue === MAX_TOKEN_AMOUNT_DECIMALS - 1) &&
    (token ? decimalsToUse + 1 <= sbAssetsObj?.[token]?.amountDecimals : true)
  ) {
    decimalsToUse = decimalsToUse + 1;
    valueToFixed = toFixedNoRounding(internalValue, decimalsToUse);
  }

  /**
   * if the number of zeros until the significant digit is larger than then
   * amount of decimals we decided to use, then we'll represent then number as 0.00000001 (number of zeros after decimal point being dynamic).
   * so that we can display the final number as <0.00000001 (number of zeros after decimal point being dynamic)
   */
  const isValueUnderMaxNumberOfDecimals = numberOfZerosUntilValue > decimalsToUse || valueToFixed === 0;

  let toFormatValue = valueToFixed;

  /**
   * format number as the minimum we can display
   * @example 0.00000001 (number of zeros after decimal point being dynamic)
   */
  if (isValueUnderMaxNumberOfDecimals) {
    toFormatValue = 1 / 10 ** decimalsToUse;
  }

  const formattedValue = `${new Intl.NumberFormat(getUserLocale(), {
    // 21 is the max: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Precision_range
    //19 works fine for us as usually the max amount of decimals is at most 18
    maximumFractionDigits: Math.max(decimalsToUse, 19),
  }).format(toFormatValue)} ${displayToken ? token ?? '' : ''}`.trim();

  /**
   * return min amount we can display as "< amount"
   */
  if (isValueUnderMaxNumberOfDecimals) {
    return `< ${formattedValue}`;
  }

  const numberOfDecimals =
    formattedValue.split(useStore.getState().numberFormatting.decimalSeparator)?.[1]?.length ?? 0;

  return typeof maxNumberOfDecimals === 'number' && numberOfDecimals > maxNumberOfDecimals
    ? formattedValue.substring(
        0,
        formattedValue.length - Math.abs(maxNumberOfDecimals - numberOfDecimals - (maxNumberOfDecimals === 0 ? 1 : 0)),
      )
    : formattedValue;
};

/**
 *
 * @param value number to count number of zeros after decimal point
 * @returns number of 0s after decmal point until a significant digit is found for values < 1
 * @example for 0.001 it returns 2
 * @example for 0.00001 it returns 4
 * @example for 1.00001 it returns 0
 */
const calculateZerosAfterDecimalPoint = (value: number) => {
  // for value === 0 the calculation below yields Infinity
  if (!value) return 0;

  const numberOfZeros = -Math.floor(Math.log10(Number(value)) + 1);

  return numberOfZeros ? numberOfZeros : 0;
};

/**
 *
 * @param number value to set number of fixed decimals for
 * @param decimals number of decimals
 * @returns value truncated to that number of decimals without any rounding
 *
 * @example for toFixedNoRounding(1.181, 2) returns 1.18
 * @example for toFixedNoRounding(1.189, 2) returns 1.18
 */
const toFixedNoRounding = (value: number, decimals: number) => {
  const splitted = value.toString().split('.');

  return Number(
    `${splitted[0]}${splitted.length > 1 && splitted[1].trim() !== '' && !isNaN(Number(splitted[1])) ? `.${value.toString().split('.')?.[1].substring(0, decimals)}` : ''}`,
  );
};

/**
 * converts decimals of a given number to superscript
 * @param value value to convert decimals to superscript
 * @returns value with decimals displayed in superscript
 */
const formatSuperscript = (value: string | number) => {
  if (typeof value !== 'string' && typeof value !== 'number') return value;

  let stringValue = String(value);
  const decimalIndex = stringValue.indexOf('.');

  if (decimalIndex === -1) stringValue = `${stringValue}.00`;

  const integerPart = stringValue.substring(0, decimalIndex);
  const decimalPart = stringValue.substring(decimalIndex + 1);
  const superscriptDigits = ['⁰', '¹', '²', '³', '⁴', '⁵', '⁶', '⁷', '⁸', '⁹'];

  let formattedValue = integerPart + '.';
  for (let i = 0; i < decimalPart.length; i++) {
    const digit = parseInt(decimalPart[i]);
    formattedValue += superscriptDigits[digit];
  }

  return formattedValue;
};

export { formatCurrency, formatCryptoBalance, formatSuperscript, formatMarketPrice };
