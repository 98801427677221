import { isNotProdEnv } from '@utils/environments-helper';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';

const DEV_CHAINS = [
  {
    id: 222000222,
    name: 'kanazawa',
    nativeCurrency: { decimals: 18, name: 'gMELD', symbol: 'gMELD' },
    rpcUrls: {
      public: { http: ['https://subnets.avax.network/meld/testnet/rpc'] },
      default: { http: ['https://subnets.avax.network/meld/testnet/rpc'] },
    },
    blockExplorers: { default: { name: '', url: 'https://testnet.meldscan.io' } },
  },
  {
    id: 43113,
    name: 'fuji',
    nativeCurrency: { decimals: 18, name: 'AVAX', symbol: 'AVAX' },
    rpcUrls: {
      public: { http: ['https://api.avax-test.network/ext/bc/C/rpc'] },
      default: { http: ['https://api.avax-test.network/ext/bc/C/rpc'] },
    },
    blockExplorers: { default: { name: '', url: 'https://testnet.snowtrace.io' } },
  },
];

const PROD_CHAINS = [
  {
    id: 1,
    name: 'ethereum',
    network: 'ethereum',
    nativeCurrency: { decimals: 18, name: 'ETH', symbol: 'ETH' },
    rpcUrls: {
      public: { http: ['https://ethereum-rpc.publicnode.com'] },
      default: { http: ['https://ethereum-rpc.publicnode.com'] },
    },
    blockExplorers: { default: { name: '', url: 'https://etherscan.io' } },
  },
  {
    id: 43114,
    name: 'avalanche',
    network: 'avalanche',
    nativeCurrency: { decimals: 18, name: 'AVAX', symbol: 'AVAX' },
    rpcUrls: {
      public: { http: ['https://api.avax.network/ext/bc/C/rpc'] },
      default: { http: ['https://api.avax.network/ext/bc/C/rpc'] },
    },
    blockExplorers: { default: { name: '', url: 'https://snowtrace.io' } },
  },
  {
    id: 333000333,
    name: 'meld',
    network: 'meld',
    nativeCurrency: { decimals: 18, name: 'gMELD', symbol: 'gMELD' },
    rpcUrls: {
      public: { http: ['https://rpc-1.meld.com'] },
      default: { http: ['https://rpc-1.meld.com'] },
    },
    blockExplorers: { default: { name: '', url: 'https://meldscan.io' } },
  },
];

export const WAGMI_CHAINS = isNotProdEnv ? DEV_CHAINS : PROD_CHAINS;

// 1. Get projectId at https://cloud.walletconnect.com
export const WC_PROJECT_ID = import.meta.env.VITE_APP_WALLET_CONNECT_PROJECT_ID ?? '';

// 2. Create wagmiConfig
const metadata = {
  name: 'MELD Bridge',
  description: 'Bridge tokens and NFTs to MELD and back.',
  url: 'https://meld.com',
  icons: ['https://meld-assets.fra1.cdn.digitaloceanspaces.com/meld_logo_sm.png'],
};

export const WAGMI_CONFIG = defaultWagmiConfig({
  // @ts-expect-error 2322
  chains: WAGMI_CHAINS,
  projectId: WC_PROJECT_ID,
  metadata,
});
