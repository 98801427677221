import { useCardanoConnection } from '@hooks/use-cardano-connection';
import { useEvmConnection } from '@hooks/use-evm-connection';
import { PickChainPopup } from './pick-chain-popup';
import { useCallback, useState } from 'react';
import { ConnectButton } from '@components/user-menu/connect-button-v2';
import { CardanoPopup } from './cardano-popup';
import { WalletMenu } from '@components/user-menu/wallet-menu';
import { animated, useSpring } from 'react-spring';
import { useStore } from '@store/store';
import { cn } from '@utils/cn';
import { shallow } from 'zustand/shallow';
import { useLoadedInitialBeData } from '@hooks/use-loaded-initial-be-data';
import { networks } from '@utils/networks/networks';
import { CARDANO_NETWORK } from 'src/contants/cardano';
import { useIsMobile } from '@hooks/use-is-mobile';
import { useSelectedNfts } from '@hooks/use-selected-nfts';
import { NetworkChainType } from '@api/meld-app/networks/networks.types';
import walletIcon from '../../assets/wallet.svg';
import { capture } from '@utils/metrics';
import { MetricEvents } from '@typings/metric-events';
import isMobile from 'is-mobile';

const _isMobile = isMobile();

export const ConnectButtonMenu = () => {
  const bridging = !!useStore((state) => state.bridgeData.startedAt);
  const approving = !!useStore((state) => state.bridgeData.approving);
  const bridgeData = useStore((state) => state.bridgeData.data, shallow);
  const evmUserBridges = useStore((state) => state.evmData.evmUserBridges, shallow);
  const cardanoUserBridges = useStore((state) => state.cardanoData.cardanoUserBridges, shallow);
  const setNftBridgeSelected = useStore((state) => state.setNftBridgeSelected);

  const { nftBridgeSelected } = useSelectedNfts();
  const loadedInitialBeData = useLoadedInitialBeData();
  const isMobile = useIsMobile();

  const [chain, setChain] = useState<'cardano' | 'evm' | null>(null);
  const [walletMenuOpen, setWalletMenuOpen] = useState<HTMLElement | null>(null);

  const externalWallet = useStore((state) => state.externalWalletData, shallow);

  const {
    address: cardanoAddress,
    setOpen: cardanoSetOpen,
    disconnect: cardanoDisconnect,
    isConnected: cardanoIsConnected,
    isConnecting: cardanoIsConnecting,
  } = useCardanoConnection();

  const {
    address: evmAddress,
    open: evmOpen,
    disconnect: evmDisconnect,
    isConnected: evmIsConnected,
    isConnecting: evmIsConnecting,
    network,
  } = useEvmConnection();

  const onWalletMenuClosed = useCallback(() => {
    setWalletMenuOpen(null);
  }, []);

  const onTriggerMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setWalletMenuOpen(event.currentTarget);
  }, []);

  const onBridgeClicked = useCallback(() => {
    setWalletMenuOpen(null);
    capture(nftBridgeSelected ? MetricEvents.UserClicksTokenBridge : MetricEvents.UserClicksNftBridge);
    setNftBridgeSelected(!nftBridgeSelected);
  }, [setNftBridgeSelected, nftBridgeSelected]);

  const wrapperSpring = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, delay: _isMobile ? 1200 : 2500 });

  return (
    <div className={cn(!loadedInitialBeData && 'pointer-events-none opacity-0')}>
      <animated.div
        className={cn((bridging || approving) && !bridgeData && 'pointer-events-none')}
        style={wrapperSpring}
      >
        <ConnectButton
          cardanoIcon={externalWallet.chainType === NetworkChainType.CARDANO ? walletIcon : undefined}
          evmIcon={externalWallet.chainType === NetworkChainType.EVM ? walletIcon : undefined}
          menuOpen={!!walletMenuOpen}
          cardanoAddress={
            externalWallet.chainType === NetworkChainType.CARDANO ? (externalWallet.address as string) : cardanoAddress
          }
          evmAddress={
            externalWallet.chainType === NetworkChainType.EVM ? (externalWallet.address as string) : evmAddress
          }
          onClickConnect={(e: React.MouseEvent<HTMLElement>) => {
            // on mobile just open wallet connect, cardano does not work on mobile
            if (isMobile) {
              evmOpen();
              return;
            }
            if (cardanoAddress) evmOpen();
            else if (evmAddress) cardanoSetOpen(true);
            else onTriggerMenu(e);
          }}
          onClickCardanoAddress={(e) => {
            setChain('cardano');
            onTriggerMenu(e);
          }}
          onClickEvmAddress={(e) => {
            setChain('evm');
            onTriggerMenu(e);
          }}
          connectingCardano={cardanoIsConnecting}
          connectingEvm={evmIsConnecting}
        />
        <div className="absolute">
          <CardanoPopup />
          <PickChainPopup
            handleClose={onWalletMenuClosed}
            open={chain === null ? walletMenuOpen : null}
            onWallectConnectClicked={() => {
              evmOpen();
              onWalletMenuClosed();
            }}
            onCardanoClicked={() => {
              cardanoSetOpen(true);
              onWalletMenuClosed();
            }}
          />
          <WalletMenu
            key={chain?.toString() ?? 'wallet-menu'}
            paperAddressConnected={!!externalWallet.address}
            isPaperAddress={
              (chain === NetworkChainType.EVM && externalWallet.chainType === NetworkChainType.EVM) ||
              (chain === NetworkChainType.CARDANO && externalWallet.chainType === NetworkChainType.CARDANO)
            }
            bridgeButtonText={nftBridgeSelected ? 'meld bridge' : 'meld nft bridge'}
            onBridgeClicked={onBridgeClicked}
            walletExplorerUrl={
              chain === 'evm'
                ? `${networks[network]?.explorerUrl}/address/${externalWallet.chainType === NetworkChainType.EVM ? externalWallet.address : evmAddress}`
                : `${networks[CARDANO_NETWORK]?.explorerUrl}/address/${externalWallet.chainType === NetworkChainType.CARDANO ? externalWallet.address : cardanoAddress}`
            }
            data={chain === 'evm' ? evmUserBridges ?? [] : cardanoUserBridges ?? []}
            open={chain !== null ? walletMenuOpen : null}
            handleClose={onWalletMenuClosed}
            address={
              chain === 'cardano'
                ? (cardanoAddress as string) ?? externalWallet.address
                : evmAddress ?? externalWallet.address
            }
            isConnected={(chain === 'cardano' && cardanoIsConnected) || chain === 'evm' || evmIsConnected}
            onDisconnect={() => {
              chain === 'cardano' ? cardanoDisconnect?.() : evmDisconnect();
              setChain(null);
            }}
            onConnectCardano={
              !cardanoAddress
                ? () => {
                    cardanoSetOpen(true);
                    onWalletMenuClosed();
                  }
                : undefined
            }
            onConnectEvm={
              !evmAddress
                ? () => {
                    evmOpen();
                    onWalletMenuClosed();
                  }
                : undefined
            }
          />
        </div>
      </animated.div>
    </div>
  );
};
