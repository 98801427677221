import { useStore } from '@store/store';
import evmContractUtils from '@utils/evm/evmContractUtils';
import { BigNumber, ethers, providers } from 'ethers';
import { MELD_NETWORK } from 'src/contants/meld';
import MeldBridgeReceiverABI from '../../abi/MeldBridgeReceiver.json';
import MeldBridgePanopticABI from '../../abi/MeldBridgePanoptic.json';
import { formatEther, parseUnits } from 'ethers/lib/utils';
import {
  approveErc20,
  approveErc721,
  estimateApproveErc20,
  estimateApproveErc721,
  isErc721Approved,
  hasAllowanceErc20,
} from '@utils/evm';
import { CARDANO_NETWORK } from 'src/contants/cardano';
import { getAddressDetails } from 'lucid-cardano/dist';
import { GetBridgeFeeReturnType } from '@api/bridge/get-fee';
import { getNetworkId } from '@utils/get-network-id';
import { networks } from '@utils/networks/networks';
import { NetworkChainType } from '@api/meld-app/networks/networks.types';

export const bridgeEvm = async ({
  amount,
  wallet,
  maxFeePerGas,
  maxPriorityFeePerGas,
  setFeeData,
  destinationNetwork,
  onCompleteApproval,
  isTransferingFullNativeAmount,
  contract,
  tokenId,
  bridgeFeeData,
}: {
  amount: string;
  wallet: providers.JsonRpcSigner;
  destinationNetwork?: string;
  onCompleteApproval?: () => void;
  // values received from estimating the cost
  maxFeePerGas?: BigNumber;
  maxPriorityFeePerGas?: BigNumber;
  isTransferingFullNativeAmount?: boolean;
  contract: string;
  tokenId?: string; // used for NFTs (only 1 can be bridged per tx)
  bridgeFeeData?: Omit<GetBridgeFeeReturnType, 'feeToken' | 'amount'>;
  // pass if estimating cost
  setFeeData?: (data: { maxFeePerGas: BigNumber; maxPriorityFeePerGas: BigNumber }) => void;
}) => {
  const { selectedWalletToken, bridgeContracts } = useStore.getState();

  // if calculating the cost then we generate the fee data, else we expect it to be passed to the function as param
  const feeData = setFeeData
    ? await wallet.getFeeData()
    : {
        maxFeePerGas,
        maxPriorityFeePerGas,
      };

  const amountWei = parseUnits(amount, selectedWalletToken?.decimals ?? 18).toString();

  let cost = BigNumber.from(0);

  // bridging from MELD
  if (selectedWalletToken?.network === MELD_NETWORK || tokenId) {
    const needsAllowance = tokenId
      ? !(await isErc721Approved({
          signer: wallet,
          spender: bridgeContracts?.panoptic.contract ?? '',
          tokenAddress: contract,
          tokenId,
        }))
      : !(await hasAllowanceErc20({
          signer: wallet,
          spender: bridgeContracts?.panoptic.contract ?? '',
          value: amountWei,
          tokenAddress: contract,
        }));

    // approve erc20 transfer
    if (onCompleteApproval) {
      const approveTx = tokenId
        ? await approveErc721({
            signer: wallet,
            spender: bridgeContracts?.panoptic.contract ?? '',
            tokenAddress: contract,
            tokenId,
            ...feeData,
          })
        : await approveErc20({
            signer: wallet,
            spender: bridgeContracts?.panoptic.contract ?? '',
            value: ethers.constants.MaxUint256.toString(),
            tokenAddress: contract,
            ...feeData,
          });

      if (approveTx) {
        onCompleteApproval();
        return { approved: true };
      }
    } else {
      // check if user has allowance
      const approvalCost = needsAllowance
        ? tokenId
          ? await estimateApproveErc721({
              signer: wallet,
              spender: bridgeContracts?.panoptic.contract ?? '',
              tokenAddress: contract,
              tokenId,
            })
          : await estimateApproveErc20({
              signer: wallet,
              spender: bridgeContracts?.panoptic.contract ?? '',
              value: ethers.constants.MaxUint256.toString(),
              tokenAddress: contract,
            })
        : null;

      // needs to approve, calculate costs
      if (needsAllowance && approvalCost) {
        if (setFeeData) {
          setFeeData({
            maxFeePerGas: feeData.maxFeePerGas ?? BigNumber.from(0),
            maxPriorityFeePerGas: feeData.maxPriorityFeePerGas ?? BigNumber.from(0),
          });
        }
        const transactionCost = formatEther(approvalCost.mul(feeData.maxFeePerGas as BigNumber));

        // return costs
        return {
          requiresApproval: true,
          cost: transactionCost,
        };
      }
    }

    // user has approval
    const panopticContract = await evmContractUtils.connect(
      wallet,
      bridgeContracts?.panoptic.contract ?? '',
      MeldBridgePanopticABI,
    );

    const networkId = getNetworkId(destinationNetwork as string);

    const evmAddress = useStore.getState().evmData.evmAddress;
    const cardanoAddress = useStore.getState().cardanoData.cardanoAddress;
    const externalWallet = useStore.getState().externalWalletData;

    const feeInfo = {
      feeAmount: bridgeFeeData?.feeInfo.feeAmount,
      deadline: bridgeFeeData?.feeInfo.deadline,
      signature: bridgeFeeData?.feeInfo.signature,
    };

    const destinationAddress =
      networks[destinationNetwork as string].chainType === NetworkChainType.CARDANO
        ? `0x${getAddressDetails(externalWallet.chainType === NetworkChainType.CARDANO ? (externalWallet.address as string) : (cardanoAddress as string)).address.hex}`
        : externalWallet.chainType === NetworkChainType.EVM
          ? (externalWallet.address as string)
          : (evmAddress as string);

    // calculate tx cost
    if (setFeeData) {
      cost = tokenId ? BigNumber.from('193543') : BigNumber.from('109901');
    } else {
      // bridge
      const tx = tokenId
        ? await panopticContract['bridgeERC721'](contract, tokenId, networkId, destinationAddress, feeInfo, {
            value: amountWei,
            gasLimit: '10000000',
          })
        : await panopticContract['bridge'](contract, amountWei, networkId, destinationAddress, feeInfo, {
            gasLimit: '10000000',
          });
      return { hash: tx.hash };
    }
  } else {
    const receiverContract =
      bridgeContracts?.receiver.find((receiver) =>
        tokenId ? CARDANO_NETWORK : receiver.network === selectedWalletToken?.network,
      )?.contract ?? '';

    const bridgeContract = await evmContractUtils.connect(wallet, receiverContract, MeldBridgeReceiverABI);

    const needsAllowance = selectedWalletToken?.isNative
      ? false
      : !(await hasAllowanceErc20({
          signer: wallet,
          spender: receiverContract,
          value: amountWei,
          tokenAddress: contract,
        }));

    // approve erc20 transfer
    if (onCompleteApproval) {
      const approveTx = await approveErc20({
        signer: wallet,
        spender: receiverContract,
        value: ethers.constants.MaxUint256.toString(),
        tokenAddress: contract,
        ...feeData,
      });

      if (approveTx) {
        onCompleteApproval();
        return { approved: true };
      }
    } else {
      // check if user has allowance
      const approvalCost = needsAllowance
        ? await estimateApproveErc20({
            signer: wallet,
            spender: receiverContract,
            value: Number.MAX_SAFE_INTEGER.toString(),
            tokenAddress: contract,
          })
        : null;

      // needs to approve, calculate costs
      if (needsAllowance && approvalCost) {
        if (setFeeData) {
          setFeeData({
            maxFeePerGas: feeData.maxFeePerGas ?? BigNumber.from(0),
            maxPriorityFeePerGas: feeData.maxPriorityFeePerGas ?? BigNumber.from(0),
          });
        }
        const transactionCost = formatEther(approvalCost.mul(feeData.maxFeePerGas as BigNumber));

        // return costs
        return {
          requiresApproval: true,
          cost: transactionCost,
        };
      }
    }

    if (setFeeData) {
      // get cost estimation
      cost =
        !tokenId && selectedWalletToken?.isNative
          ? await bridgeContract.estimateGas['bridgeNative']({ value: amountWei })
          : await bridgeContract.estimateGas['bridge'](contract, amountWei);
    } else {
      // generate tx
      const tx =
        !tokenId && selectedWalletToken?.isNative
          ? await bridgeContract['bridgeNative']({ value: amountWei, ...feeData })
          : await bridgeContract['bridge'](contract, amountWei, { ...feeData });

      return { hash: tx.hash };
    }
  }

  const transactionCost = formatEther(cost.mul(feeData.maxFeePerGas as BigNumber));

  setFeeData?.({
    maxFeePerGas: feeData.maxFeePerGas ?? BigNumber.from(0),
    // if we dont set this then we can't empty the wallet essentially, in case the user is transfering the full amount
    maxPriorityFeePerGas:
      (isTransferingFullNativeAmount ? feeData.maxFeePerGas : feeData.maxPriorityFeePerGas) ?? BigNumber.from(0),
  });

  return { cost: transactionCost };
};
