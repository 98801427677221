import { useStore } from '@store/store';
import { parseUnits } from 'ethers/lib/utils';
import { Assets, Data, Lucid, Script, fromText, toUnit } from 'lucid-cardano/dist';
import { ManagerSpend, RequesterWithdraw } from './plutus';
import { calculateCardanoFee } from '@utils/assets-helper';
import { NetworkChainType } from '@api/meld-app/networks/networks.types';

function remove0xForEvmAddress(evmAddress: string) {
  return evmAddress.replace(/^(0x)/, '');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(BigInt.prototype as any).toJSON = function () {
  return this.toString();
};

export type LockData = {
  amount: bigint;
  policyId: string;
  assetName: string;
  destinationAddress: string;
};

export function getPaymentCredentialFromAddress(lucid: Lucid, userAddress: string) {
  const userPaymentCredential =
    lucid.utils.getAddressDetails(userAddress).paymentCredential?.type == 'Key'
      ? ({
          VerificationKeyCredential: [lucid.utils.getAddressDetails(userAddress).paymentCredential?.hash] as [string],
        } as Credential)
      : ({
          ScriptCredential: [lucid.utils.getAddressDetails(userAddress).paymentCredential?.hash] as [string],
        } as Credential);
  return userPaymentCredential;
}

export function getStakeCredentialFromAddress(lucid: Lucid, userAddress: string) {
  const userStakeCredential =
    lucid.utils.getAddressDetails(userAddress).stakeCredential == undefined
      ? null
      : {
          Inline: [
            lucid.utils.getAddressDetails(userAddress).stakeCredential?.type == 'Key'
              ? {
                  VerificationKeyCredential: [lucid.utils.getAddressDetails(userAddress).stakeCredential?.hash] as [
                    string,
                  ],
                }
              : {
                  ScriptCredential: [lucid.utils.getAddressDetails(userAddress).stakeCredential?.hash] as [string],
                },
          ] as [Credential],
        };
  return userStakeCredential;
}

function getRequest(lucid: Lucid, lockRequest: LockData, userAddress: string) {
  const userPaymentCredential = getPaymentCredentialFromAddress(lucid, userAddress);
  const userStakeCredential = getStakeCredentialFromAddress(lucid, userAddress);
  const lockAssetNameHex = lockRequest.assetName == '' ? '' : fromText(lockRequest.assetName);
  // const curStakeCredential = lucid.utils.stakeCredentialOf(curAddress)
  const destinationAddress = remove0xForEvmAddress(lockRequest.destinationAddress);

  return {
    lockSourceAddress: {
      paymentCredential: userPaymentCredential,
      stakeCredential: userStakeCredential,
    },
    lockDestinationAddress: destinationAddress,
    lockPolicyId: lockRequest.policyId,
    lockAssetName: lockAssetNameHex,
    lockAmount: lockRequest.amount,
  };
}

function getLockAssets(lockRequests: LockData[], minAda: bigint) {
  const lockAssets: Assets = {};
  for (const { assetName, policyId, amount } of lockRequests) {
    const lockAsset = policyId == '' ? 'lovelace' : toUnit(policyId, fromText(assetName));
    if (lockAsset in lockAssets) {
      lockAssets[lockAsset] += amount;
    } else {
      lockAssets[lockAsset] = amount;
    }
    if (lockAsset != 'lovelace') {
      if ('lovelace' in lockAssets) {
        lockAssets['lovelace'] += minAda;
      } else {
        lockAssets['lovelace'] = minAda;
      }
    }
  }
  return lockAssets;
}

export const bridgeCardano = async ({
  amount,
  estimateCost,
  nfts,
  assetName = '',
  policyId = '',
}: {
  amount: string;
  estimateCost?: boolean;
  nfts?: Array<{ assetName: string; policyId: string }>;
  assetName?: string;
  policyId?: string;
}) => {
  const cardanoWallet = useStore.getState().cardanoData.cardanoWallet;
  const bridgeContracts = useStore.getState().bridgeContracts;
  const evmAddress = useStore.getState().evmData.evmAddress as string;
  const externalWallet = useStore.getState().externalWalletData;

  const amountBigInt = parseUnits(amount || '0', 6).toBigInt();

  if (cardanoWallet && bridgeContracts) {
    // Get manager utxo and datum by address and authToken
    const managerAuthToken = toUnit(
      bridgeContracts?.cardano.accessControlAuthTokenMintingPolicy,
      fromText(bridgeContracts?.cardano.managerAuthTokenName),
    );
    const managerUtxos = await cardanoWallet.utxosAtWithUnit(bridgeContracts?.cardano.managerAddress, managerAuthToken);

    const curAddress = await cardanoWallet.wallet.address();
    const managerDatum: ManagerSpend['_datum'] = Data.from<ManagerSpend['_datum']>(
      managerUtxos[0].datum!,
      ManagerSpend['_datum'],
    );

    const lockRequests: Array<LockData> = nfts
      ? nfts.map(({ assetName, policyId }) => ({
          assetName,
          policyId,
          amount: 1n,
          destinationAddress:
            externalWallet.chainType === NetworkChainType.EVM ? (externalWallet.address as string) : evmAddress,
        }))
      : [
          {
            policyId,
            assetName,
            amount: amountBigInt,
            destinationAddress:
              externalWallet.chainType === NetworkChainType.EVM ? (externalWallet.address as string) : evmAddress,
          },
        ];

    const requests = lockRequests.map((lockRequest) => getRequest(cardanoWallet, lockRequest, curAddress));
    const lockAssets = getLockAssets(lockRequests, managerDatum.minAda);

    const requesterWithdrawRedeemer: RequesterWithdraw['redeemer'] = {
      Lock: {
        requests,
      },
    };

    const lockerAddress = bridgeContracts?.cardano.stakers[0].lockerAddress;
    const tx = await cardanoWallet
      .newTx()
      .payToContract(lockerAddress, { inline: Data.void() }, lockAssets)
      .readFrom(managerUtxos)
      .attachCertificateValidator(bridgeContracts?.cardano.requesterWithdraw.script as Script)
      .withdraw(
        bridgeContracts?.cardano.requesterWithdraw.rewardAddress,
        0n,
        Data.to(requesterWithdrawRedeemer, RequesterWithdraw.redeemer),
      )
      .complete({ nativeUplc: false });

    if (estimateCost) {
      return calculateCardanoFee(tx?.fee ?? 0).toString();
    }

    const txSigned = await tx.sign().complete();
    const txHash = await txSigned.submit();
    return txHash;
  } else throw Error('Something went wrong.');
};
