
import { axiosInstance } from '@api/axios';
import { GetNFTsQueryParams, Nft, NftResponse } from './nfts-query.types';

export const getNFTSQuery = async <T extends Nft>(params: GetNFTsQueryParams): Promise<NftResponse<T>> => {
  const response = await axiosInstance.get<NftResponse<T>>('/nfts', { params });
  return response.data;
};

export const GET_NFTS_QUERY_KEY = 'GET_NFTS_QUERY_KEY';
export const GET_NFTS_SHORT_ADDRESS_QUERY_KEY = 'GET_NFTS_SHORT_ADDRESS_QUERY_KEY';
export const GET_EVM_NFTS_QUERY_KEY = 'GET_EVM_NFTS_QUERY_KEY';
export const GET_EXT_CARDANO_NFTS_QUERY_KEY = 'GET_EXT_CARDANO_NFTS_QUERY_KEY';
export const GET_EXT_EVM_NFTS_QUERY_KEY = 'GET_EXT_EVM_NFTS_QUERY_KEY';
