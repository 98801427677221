import { AvailableToken } from '@api/meld-app/available-tokens/available-tokens.types';
import { networks } from './networks/networks';
import { ReactNode } from 'react';

export let sbAssets: AvailableToken[] = [];
// this object shouldn't be needed as everything should rely on tokenId
export let sbAssetsObj: Record<string, AvailableToken> = {};
export let sbAssetsObjByTokenId: Record<string, AvailableToken> = {};
export let sbAssetsObjByContract: Record<string, AvailableToken> = {};

// these are used within assets-slice, once we set the available-tokens there (from getting them from the BE we set the variables above so they can be accessed within the app)
// the idea would be to remove the variables above altogether but the entire codebase uses them and that would be a big refactoring
export const setSbAssets = (newSbAssets: AvailableToken[]) => (sbAssets = newSbAssets);
export const setSbAssetsObj = (newSbAssetsObj: Record<string, AvailableToken>) => (sbAssetsObj = newSbAssetsObj);
export const setSbAssetsByTokenId = (newSbAssetsByTokenId: Record<string, AvailableToken>) =>
  (sbAssetsObjByTokenId = newSbAssetsByTokenId);
export const setSbAssetsByContract = (newSbAssetsByContract: Record<string, AvailableToken>) =>
  (sbAssetsObjByContract = newSbAssetsByContract);

export const getNetworkIcon = (network: string) => networks[network ?? 'cardano' ?? 'preprod']?.['darkIcon'];

export const getTokenIcon = (assetName: string | undefined, isChain?: boolean) => {
  const Wrapper = ({ children }: { children: ReactNode }) => (
    <div className="[&_svg]:w-full' flex h-full w-full items-center justify-center rounded-full [&_img]:h-full [&_img]:w-full">
      {children}
    </div>
  );

  // in DEV the BE returns "tokenName" as MELD instead of tMELD and that causes issue finding the token, so we compare also the slug which seems to match correctly
  const iconUrl = isChain
    ? networks[assetName ?? 'cardano' ?? 'preprod']?.['darkIcon']
    : sbAssets.find(
        (item) =>
          (item.name === assetName || item.slug === assetName) &&
          !(item.description.includes('[old]') || item.description.includes('(old)')),
      )?.tokenIcon;

  if (!iconUrl)
    return (
      <Wrapper>
        <p>{assetName?.charAt(0).toUpperCase()}</p>
      </Wrapper>
    );

  return (
    <Wrapper>
      <img src={iconUrl} />
    </Wrapper>
  );
};

export const getTokenIconByUrl = (iconUrl: string) => {
  return <img src={iconUrl} />;
};

// TODO - rename to "formatCardanoAmount"
export const calculateCardanoFee = (fee: number) => {
  return Number(fee) / 10 ** 6;
};
