export const MELD_TOKEN_ADDRESS =
  import.meta.env.VITE_APP_ENV !== 'production'
    ? '0x22200025a5bc2c7da9c8ed6c051a58e12efa7501'
    : '0x333000333528b1e38884a5d1ef13615b0c17a301';

export const MELD_TOKEN_ID =
  import.meta.env.VITE_APP_ENV == 'development' ? 26953 : import.meta.env.VITE_APP_ENV == 'staging' ? 15193 : 78434;

export const MELD_CHAIN_ID = import.meta.env.VITE_APP_ENV == 'development' ? 222000222 : 333000333;

export const MELD_NETWORK = import.meta.env.VITE_APP_ENV == 'development' ? 'kanazawa' : 'meld';
