import { useStore } from '@store/store';
import { useMemo } from 'react';
import { CARDANO_NETWORK } from 'src/contants/cardano';
import { shallow } from 'zustand/shallow';
import { useSelectedNfts } from './use-selected-nfts';
import { useCalculateTxFee } from './use-calculate-tx-fee';
import { useUnsupportedExtNetwork } from './use-unsupported-ext-network';
import { NetworkChainType } from '@api/meld-app/networks/networks.types';

export const useCantBridge = () => {
  const { transactionCost, notEnoughToken } = useStore((state) => state.bridgeData, shallow);
  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);
  const { inputError, amount } = useStore((state) => state.inputData, shallow);
  const { evmAddress } = useStore((state) => state.evmData, shallow);
  const { nftBridgeSelected, selectedNftObjects } = useSelectedNfts();
  const { isLoading } = useCalculateTxFee();
  const { wrongEvmNetwork } = useUnsupportedExtNetwork();
  const externalWalletData = useStore((state) => state.externalWalletData, shallow);

  const cantBridge = useMemo(
    () =>
      (!nftBridgeSelected &&
        (!transactionCost ||
          notEnoughToken ||
          !selectedWalletToken ||
          amount === '' ||
          +amount === 0 ||
          inputError ||
          isLoading ||
          wrongEvmNetwork ||
          (selectedWalletToken.network === CARDANO_NETWORK &&
            !evmAddress &&
            externalWalletData?.chainType !== NetworkChainType.EVM))) ||
      (nftBridgeSelected &&
        (!transactionCost || !selectedNftObjects.length || isLoading || wrongEvmNetwork || inputError)),
    [
      selectedWalletToken,
      amount,
      inputError,
      evmAddress,
      transactionCost,
      nftBridgeSelected,
      selectedNftObjects,
      isLoading,
      wrongEvmNetwork,
      notEnoughToken,
      externalWalletData,
    ],
  );

  return cantBridge;
};
