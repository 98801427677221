import { formatEther } from '@ethersproject/units';
import { BigNumber, Contract, Wallet, ethers } from 'ethers';

import ERC20ABI from '../../abi/ERC20.json';
import { evmNetworks } from '../networks/networks';

import evmProviderUtils from './evmProviderUtils';

const getBalance = async ({
  wallet,
  contractAddress,
}: {
  wallet: Wallet;
  contractAddress?: string;
  walletAddress?: string;
}) => {
  let balance;
  if (contractAddress) {
    const contract = new Contract(contractAddress, ERC20ABI, wallet);
    balance = await contract.balanceOf(wallet.address);
  } else {
    balance = await wallet.getBalance();
  }
  return Number(formatEther(balance));
};

export const getErc20Balance = async ({
  wallet,
  contractAddress,
  walletAddress,
}: {
  wallet: Wallet | ethers.providers.JsonRpcSigner;
  contractAddress: string;
  walletAddress: string;
}): Promise<BigNumber> => {
  const contract = new Contract(contractAddress, ERC20ABI, wallet);
  const balance = await contract.balanceOf(walletAddress);

  return balance;
};

/**
 * Fetches balances of all EVM networks we support
 * @param walletAddress evm wallet address
 * @remarks
 * This is currently needed because upon connecting an external wallet the BE is not instantly ready
 * with native EVM balances, like they are with balances of ERC-20 tokens.
 */
const getNativeTokenBalances = async (walletAddress: string) =>
  await Promise.all(
    evmNetworks.map(async (network) => {
      const provider = evmProviderUtils.connectToRPC(network.publicRpcUrls[0]);
      return {
        balance: (await provider.getBalance(walletAddress)).toString(),
        tokenSymbol: network.defaultTokenSymbol,
      };
    }),
  );

const evmWalletUtils = {
  getBalance,
  getNativeTokenBalances,
};

export default evmWalletUtils;
