export type PostWalletRegistrationQueryType = {
  status: string;
  error?: string;
};

export type GetWalletRegistrationQueryType = Array<{
  address: string;
  createdAt: string;
  origin: string;
}>;

export enum OriginTypes {
  WEB_APP = 'webapp',
  BRIDGE_APP = 'bridge-app',
  EXTERNAL = 'external',
}
