import { useStore } from '@store/store';
import { getExternalCardanoReference } from '@utils/get-external-cardano-reference';
import { useAccount, useAccountEffect } from 'wagmi';

import {
  getWalletRegistrationQuery,
  postWalletRegistrationQuery,
} from '@api/meld-app/wallet-registration/wallet-registration-query';
import { useWalletRegisterAndSynced } from './use-wallet-register-and-synced';
import { SupportedWalletBE } from '@typings/wallet-asset.types';
import { useCallback, useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { NetworkChainType } from '@api/meld-app/networks/networks.types';
import { CARDANO_NETWORK } from 'src/contants/cardano';
import { useSelectedNfts } from './use-selected-nfts';
import { useIsBridging } from './use-is-bridging';
import { capture } from '@utils/metrics';
import { MetricEvents } from '@typings/metric-events';

export const useWalletsRegisterAndSynced = () => {
  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);
  const setInputData = useStore((state) => state.setInputData);
  const setCardanoData = useStore((state) => state.setCardanoData);
  const setBridgeData = useStore((state) => state.setBridgeData);

  const unselectAllEvmNfts = useStore((state) => state.unselectAllEvmNfts);

  const { evmAddress } = useStore((state) => state.evmData, shallow);
  const userTokens = useStore((state) => state.userTokens, shallow);
  const { bridgingCardano } = useStore((state) => state.bridgeData, shallow);
  const setSelectedWalletToken = useStore((state) => state.setSelectedWalletToken);
  const { selectedNftNetwork, nftBridgeSelected } = useSelectedNfts();
  const { cardanoWalletName, cardanoNotBroadcastedAddress } = useStore((state) => state.cardanoData, shallow);
  const isBridging = useIsBridging();

  useWalletRegisterAndSynced({
    notBroadcastedAddress: cardanoNotBroadcastedAddress as string,
    postWalletRegistrationQuery,
    getWalletRegistrationQuery,
    setAddressRegistered: (flag) => setCardanoData({ cardanoWalletRegistered: flag }),
    setWalletAddress: (address) => {
      setCardanoData({ cardanoAddress: address, cardanoConnecting: false });
      // if we connect a cardano wallet without having an evm wallet then preselect cardano
      if (!evmAddress) {
        const adaToken = userTokens.find((token) => token.network === CARDANO_NETWORK && token.isNative);
        setSelectedWalletToken(adaToken);
      }
    },
    // TODO - perhaps use this to fetch the native token (not needed for Cardano)
    walletSyncedQuery: (address: string) => new Promise((resolve) => resolve({ ready: true, address })),
    walletName: cardanoWalletName ? getExternalCardanoReference(cardanoWalletName).name : '',
    walletType: SupportedWalletBE.CARDANO,
  });

  const notBroadcastedExternalEVMWalletAddress = useStore(
    (state) => state.evmData.notBroadcastedExternalEVMWalletAddress,
    shallow,
  );

  const setEvmData = useStore((state) => state.setEvmData);

  const { connector } = useAccount();

  const resetEvmRelatedData = useCallback(() => {
    if (isBridging && !bridgingCardano) {
      location.reload();
    }
    if (
      (nftBridgeSelected && selectedNftNetwork === NetworkChainType.EVM) ||
      (!nftBridgeSelected && selectedWalletToken?.chainType === NetworkChainType.EVM)
    ) {
      unselectAllEvmNfts();
      setInputData({ inputError: null, amount: '' }, true);
      setEvmData({ evmRequiresApproval: false });
      setBridgeData({ transactionCost: '', feeAmount: '', realBridgeAmount: '', notEnoughToken: false });
    }
  }, [
    unselectAllEvmNfts,
    setInputData,
    setEvmData,
    setBridgeData,
    isBridging,
    nftBridgeSelected,
    selectedNftNetwork,
    selectedWalletToken?.chainType,
    bridgingCardano,
  ]);

  useAccountEffect({
    onConnect(data) {
      if (!data.isReconnected) {
        capture(MetricEvents.UserConnectsEVMWallet, {
          wallet: data?.connector?.name ?? 'No-name',
        });
      }
      setEvmData({
        evmWalletName: data.connector.name,
        evmConnectedChainId: data.chainId,
        notBroadcastedExternalEVMWalletAddress: data.address,
      });
    },
    onDisconnect() {
      resetEvmRelatedData();
      setEvmData({ evmWalletName: '', evmConnectedChainId: undefined, evmAddress: null });
    },
  });

  useEffect(() => {
    const handleConnectorUpdate = (
      data: {
        accounts?: readonly `0x${string}`[] | undefined;
        chainId?: number | undefined;
      } & {
        uid: string;
      },
    ) => {
      if (data.accounts) {
        if (notBroadcastedExternalEVMWalletAddress !== data.accounts[0]) {
          setEvmData({ notBroadcastedExternalEVMWalletAddress: data.accounts[0] as string });
          resetEvmRelatedData();
        }
      } else if (data.chainId) {
        setEvmData({ evmConnectedChainId: data.chainId });
      }
    };

    if (connector) {
      connector?.emitter?.on('change', handleConnectorUpdate);
    }

    return () => connector?.emitter?.off('change', handleConnectorUpdate);
  }, [
    connector,
    notBroadcastedExternalEVMWalletAddress,
    setEvmData,
    unselectAllEvmNfts,
    isBridging,
    selectedNftNetwork,
    selectedWalletToken,
    setInputData,
    nftBridgeSelected,
    resetEvmRelatedData,
  ]);

  useWalletRegisterAndSynced({
    notBroadcastedAddress: notBroadcastedExternalEVMWalletAddress as string,
    postWalletRegistrationQuery,
    getWalletRegistrationQuery,
    setAddressRegistered: (flag) => setEvmData({ evmAddressRegistered: flag }),
    setWalletAddress: (address) => setEvmData({ evmAddress: address }),
    walletSyncedQuery: (address: string) =>
      new Promise((resolve) => resolve({ ready: true, address: address.toLowerCase() })),

    walletName: 'MetaMask', // TODO - refactor later if we support more web3 connectors
    walletType: SupportedWalletBE.EVM,
  });
};
