import { BridgeContracts } from '@typings/api';
import { Network, NetworkChainType, NetworksResponse } from '../../../api/meld-app/networks/networks.types';

export const parseNetworks = (networks: NetworksResponse, bridgeContracts: BridgeContracts) => {
  return networks.reduce<Record<string, Network>>((prev, curr) => {
    // off the networks the meld app supports, only show networks the bridge supports
    if (
      bridgeContracts.receiver.filter((a) => a.network === curr.name).length ||
      bridgeContracts.panoptic.network === curr.name ||
      curr.chainType === NetworkChainType.CARDANO ||
      curr.chainType === NetworkChainType.EVM
    ) {
      prev[curr.name] = {
        ...curr,
      };
    }
    return prev;
  }, {});
};
