import { useStore } from '@store/store';
import { getNetworkIcon } from '@utils/assets-helper';
import { useMemo } from 'react';
import { CARDANO_NETWORK } from 'src/contants/cardano';
import { IS_DEVELOPMENT } from 'src/contants/is-development';
import { MELD_NETWORK } from 'src/contants/meld';
import { shallow } from 'zustand/shallow';
import { useSelectedNfts } from './use-selected-nfts';
import { SupportedWallet } from '@typings/wallet';

export const useBridgeTokenData = () => {
  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);
  const bridgeContracts = useStore((state) => state.bridgeContracts, shallow);
  const { selectedNftWallet, nftBridgeSelected } = useSelectedNfts();

  const tokenData = useMemo(() => {
    if (nftBridgeSelected) {
      if (selectedNftWallet === SupportedWallet.EVM) {
        return {
          destinationToken: { icon: getNetworkIcon(CARDANO_NETWORK), network: CARDANO_NETWORK },
          sourceToken: { icon: getNetworkIcon(MELD_NETWORK), network: MELD_NETWORK },
        };
      } else {
        return {
          sourceToken: { icon: getNetworkIcon(CARDANO_NETWORK), network: CARDANO_NETWORK },
          destinationToken: { icon: getNetworkIcon(MELD_NETWORK), network: MELD_NETWORK },
        };
      }
    }

    if (selectedWalletToken && !selectedWalletToken.toBridgeBack) {
      return {
        sourceToken: { icon: getNetworkIcon(selectedWalletToken?.network ?? ''), network: selectedWalletToken.network },
        destinationToken: { icon: getNetworkIcon(MELD_NETWORK), network: MELD_NETWORK },
      };
    } else if (selectedWalletToken) {
      const network = bridgeContracts?.supportedTokens
        .filter((token) => token.token === selectedWalletToken?.contract)
        .shift()?.network;
      return {
        destinationToken: { icon: getNetworkIcon(network ?? CARDANO_NETWORK), network: network },
        sourceToken: {
          icon: getNetworkIcon(selectedWalletToken?.network ?? ''),
          network: selectedWalletToken.network,
        },
      };
    }

    return {
      destinationToken: { icon: getNetworkIcon(MELD_NETWORK), network: MELD_NETWORK },
      sourceToken: {
        icon: getNetworkIcon(IS_DEVELOPMENT ? 'fuji' : 'ethereum'),
        network: IS_DEVELOPMENT ? 'fuji' : 'ethereum',
      },
    };
  }, [selectedWalletToken, bridgeContracts, nftBridgeSelected, selectedNftWallet]);

  return { tokenData, meldData: { icon: getNetworkIcon(MELD_NETWORK), network: MELD_NETWORK } };
};
