import { BigNumberish, Contract, ContractInterface, Signer, Wallet, ethers } from 'ethers';

/**
 * Function that connects to a EVM Smart Contract
 * @param provider - the provider instance
 * @param contractAddress - the contract address
 * @param abi - the contract abi
 * @returns the contract instance
 */
// TODO: add specific types for the abi
const connect = async (
  wallet: Wallet | Signer | ethers.providers.JsonRpcProvider,
  contractAddress: string,
  abi: ContractInterface,
) => {
  const contract = new Contract(contractAddress, abi, wallet);

  return contract;
};

/**
 * Function that approves a spender to spend a certain amount of tokens
 * @param contract - the contract instance
 * @param spenderAddress - the address of the spender (contract address or wallet address)
 * @param amount - the amount of tokens to approve
 * @returns the transaction
 */
const approve = async (contract: Contract, spenderAddress: string, amount: BigNumberish) => {
  const transaction = await contract.approve(spenderAddress, amount);
  await transaction.wait(1);

  return transaction;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const decodeData = async (contract: Contract, abi: any, data: string) => {
  const decoded = contract.interface.decodeFunctionData(abi, data);

  return decoded;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const encodeData = async (contract: Contract, abi: any, data: any) => {
  const encoded = contract.interface.encodeFunctionData(abi, data);

  return encoded;
};

const evmContractUtils = {
  connect,
  approve,
  decodeData,
  encodeData,
};

export default evmContractUtils;
