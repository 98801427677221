import { NetworkChainType } from '@api/meld-app/networks/networks.types';
import { queryClient } from '@api/query-client';
import { useBridgeTokenData } from '@hooks/use-bridge-token-data';
import { useEthersSigner } from '@hooks/use-ethers-signer';
import { Box, Button } from '@mui/material';
import { BridgeContracts, UserBridge } from '@typings/api';
import { bridgeCardano } from '@utils/bridge-cardano';
import { bridgeEvm } from '@utils/bridge-evm';
import { cn } from '@utils/cn';
import { MOCK_DATA, fetchBridgeInfo } from '@utils/fetch-bridge-info';
import { useCallback, useMemo, useState } from 'react';
import { IS_MOCK_ENV } from 'src/contants/is-mock-env';
import { AnimatedEllipsis } from './animated-ellipsis';
import { useStore } from '@store/store';
import { shallow } from 'zustand/shallow';
import { useIsBridging } from '@hooks/use-is-bridging';
import { useCantBridge } from '@hooks/use-cant-bridge';
import { parseCardanoAsset } from '@utils/parse-cardano-asset';
import {
  GET_EXT_CARDANO_WALLET_TOKENS_QUERY_KEY,
  GET_METAMASK_WALLET_TOKENS_QUERY_KEY,
} from '@api/meld-app/wallet-tokens/wallet-tokens-query';
import { useSelectedNfts } from '@hooks/use-selected-nfts';
import { SupportedWallet } from '@typings/wallet';
import { CardanoNft, EvmNft } from '@api/meld-app/nfts/nfts-query.types';
import { GET_EVM_NFTS_QUERY_KEY, GET_NFTS_QUERY_KEY } from '@api/meld-app/nfts/nfts-query';
import { CARDANO_NETWORK } from 'src/contants/cardano';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { CALCULATE_FEE_QUERY_KEY } from 'src/contants/calculate-fee';
import { useNativeToken } from '@hooks/use-native-token';
import { MELD_NETWORK } from 'src/contants/meld';
import { Tooltip } from './tooltip';
import { uppercaseFirstLetter } from '@utils/format-string.util';
import { checkHasLiquidity } from '@utils/check-has-liquidity';
import { WalletToken } from '@typings/wallet-asset.types';
import { useUnsupportedExtNetwork } from '@hooks/use-unsupported-ext-network';
import { capture, captureError } from '@utils/metrics';
import { MetricEvents } from '@typings/metric-events';

export const BridgeButton = () => {
  const { evmAddress, evmTxFeeData, evmRequiresApproval } = useStore((state) => state.evmData, shallow);
  const { cardanoAddress } = useStore((state) => state.cardanoData, shallow);
  const {
    bridgeAgain,
    completedStep,
    bridgeFailed,
    approving,
    initiatedBridge,
    data: bridgeData,
    signature,
    deadline,
    feeAmount,
    liquidityData,
  } = useStore((state) => state.bridgeData, shallow);
  const detailsKey = useStore((state) => state.detailsKey);
  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);
  const { amount, realAmount, isTransferingFullNativeAmount } = useStore((state) => state.inputData, shallow);
  const externalWalletData = useStore((state) => state.externalWalletData, shallow);
  const [disableButton, setDisableButton] = useState(false);
  const unselectAllCardanoNfts = useStore((state) => state.unselectAllCardanoNfts, shallow);
  const unselectAllEvmNfts = useStore((state) => state.unselectAllEvmNfts, shallow);
  const bridgeContracts = useStore((state) => state.bridgeContracts, shallow);

  const setDetailsKey = useStore((state) => state.setDetailsKey);
  const setBridgeData = useStore((state) => state.setBridgeData);
  const setInputData = useStore((state) => state.setInputData);
  const addUserBridge = useStore((state) => state.addUserBridge);
  const setSelectedWalletToken = useStore((state) => state.setSelectedWalletToken);

  const { selectedNftWallet, nftBridgeSelected, selectedNftObjects } = useSelectedNfts();
  const nativeToken = useNativeToken({ network: nftBridgeSelected ? MELD_NETWORK : selectedWalletToken?.network });

  const extEvmWallet = useEthersSigner();

  const { tokenData } = useBridgeTokenData();

  const isBridging = useIsBridging();
  const cantBridge = useCantBridge();
  const { wrongEvmNetwork } = useUnsupportedExtNetwork();

  const onBridgeCompleted = useCallback(
    (userBridge: UserBridge) => {
      addUserBridge(userBridge);
      if (nftBridgeSelected) {
        if (userBridge.originalNetwork.network !== CARDANO_NETWORK) unselectAllEvmNfts();
        else unselectAllCardanoNfts();

        queryClient.invalidateQueries([GET_EVM_NFTS_QUERY_KEY]);
        queryClient.invalidateQueries([GET_NFTS_QUERY_KEY]);
      } else {
        // refetch user balances
        queryClient.invalidateQueries([GET_EXT_CARDANO_WALLET_TOKENS_QUERY_KEY]);
        queryClient.invalidateQueries([GET_METAMASK_WALLET_TOKENS_QUERY_KEY]);
      }
    },
    [addUserBridge, nftBridgeSelected, unselectAllCardanoNfts, unselectAllEvmNfts],
  );

  const onApprovalCompleted = useCallback(() => {
    // bit of a delay to ensure estimation goes through
    setTimeout(() => {
      // recalculate fee
      queryClient.invalidateQueries([CALCULATE_FEE_QUERY_KEY]);
      useStore.getState().setEvmData({ evmRequiresApproval: false });
      setBridgeData({ approving: false, transactionCost: '', feeAmount: '' });
    }, 2000);
  }, [setBridgeData]);

  const handleBridgeClicked = useCallback(async () => {
    // going to bridge or approve
    if (completedStep === 0 && !bridgeFailed) {
      // reset request counter in case we're running a mock test
      if (IS_MOCK_ENV) MOCK_DATA.requests = 0;
      // if its cardano
      const enoughLiquidity =
        nftBridgeSelected || liquidityData.amount
          ? true
          : await checkHasLiquidity({
              selectedWalletToken: selectedWalletToken as WalletToken,
              amount,
              setBridgeData,
              bridgeContracts: bridgeContracts as BridgeContracts,
              destinationNetwork: tokenData.destinationToken.network as string,
            });

      if (!enoughLiquidity) {
        setDisableButton(true);
        setTimeout(() => setDisableButton(false), 2500);
        return;
      }

      if (nftBridgeSelected && !evmRequiresApproval) {
        capture(MetricEvents.UserCreatesNftTransaction);
      } else if (!evmRequiresApproval) {
        capture(MetricEvents.UserCreatesTokenTransaction);
      }

      if (enoughLiquidity && liquidityData.amount) {
        setBridgeData({ liquidityData: { amount: '', destinationNetwork: '' } });
      }

      if (
        (!nftBridgeSelected && selectedWalletToken?.chainType === NetworkChainType.CARDANO) ||
        (nftBridgeSelected && selectedNftWallet !== SupportedWallet.EVM)
      ) {
        try {
          setBridgeData({ initiatedBridge: true });

          const assetData =
            nftBridgeSelected || selectedWalletToken?.isNative
              ? {}
              : parseCardanoAsset(selectedWalletToken?.contract ?? '');

          MOCK_DATA.hash = Date.now().toString();
          const hash = IS_MOCK_ENV
            ? MOCK_DATA.hash
            : await bridgeCardano({
                amount: +realAmount > 0 ? realAmount : amount,
                ...assetData,
                nfts:
                  nftBridgeSelected && selectedNftWallet !== SupportedWallet.EVM
                    ? (selectedNftObjects as Array<CardanoNft>).map((a) => parseCardanoAsset(a.contract))
                    : undefined,
              });

          fetchBridgeInfo({
            hash,
            walletAddress: cardanoAddress as string,
            destinationNetwork: tokenData?.destinationToken.network as string,
            onComplete: onBridgeCompleted,
            updateGlobalState: true,
            amount: parseUnits(amount || '0', 6).toString(),
            network: tokenData.sourceToken.network,
            tokenName: nftBridgeSelected
              ? parseCardanoAsset(selectedNftObjects[0].contract).assetNameHex
              : selectedWalletToken?.isNative
                ? ''
                : parseCardanoAsset(selectedWalletToken?.contract ?? '').assetNameHex,
            token: selectedWalletToken?.contract ?? '',
            numberOfNfts: nftBridgeSelected ? selectedNftObjects.length : 0,
          });
        } catch (err) {
          captureError(err);
          // @ts-expect-error 18046 this is what the cardano wallet returns when the user rejects the tx
          if (err.code !== 2) {
            setBridgeData({ bridgeFailed: true });
          }
          setBridgeData({ initiatedBridge: false });
        }
      }
      // if its evm
      else if (extEvmWallet) {
        // update with "approving state"
        if (evmRequiresApproval) setBridgeData({ approving: true });
        else setBridgeData({ initiatedBridge: true });

        try {
          MOCK_DATA.hash = Date.now().toString();

          const isBridgingBackNFT = nftBridgeSelected && selectedNftObjects[0].network !== CARDANO_NETWORK;

          const amountToBridge =
            selectedWalletToken?.toBridgeBack && !isBridgingBackNFT
              ? parseUnits(amount, selectedWalletToken.decimals).toBigInt()
              : isBridgingBackNFT
                ? BigInt(feeAmount)
                : parseUnits(
                    isTransferingFullNativeAmount ? realAmount : amount,
                    selectedWalletToken?.decimals,
                  ).toBigInt();

          const { hash } = IS_MOCK_ENV
            ? { hash: MOCK_DATA.hash }
            : await bridgeEvm({
                amount: formatUnits(
                  amountToBridge,
                  isBridgingBackNFT ? nativeToken?.decimals : selectedWalletToken?.decimals,
                ),
                wallet: extEvmWallet,
                destinationNetwork: tokenData.destinationToken.network as string,
                onCompleteApproval: evmRequiresApproval ? onApprovalCompleted : undefined,
                maxFeePerGas: evmTxFeeData?.maxFeePerGas,
                maxPriorityFeePerGas: evmTxFeeData?.maxPriorityFeePerGas,
                isTransferingFullNativeAmount,
                contract: nftBridgeSelected ? selectedNftObjects[0].contract : selectedWalletToken?.contract ?? '',
                tokenId: nftBridgeSelected ? (selectedNftObjects[0] as EvmNft).tokenId : undefined,
                bridgeFeeData:
                  selectedWalletToken?.toBridgeBack || isBridgingBackNFT
                    ? { feeInfo: { signature, deadline, feeAmount } }
                    : undefined,
              });

          if (hash) {
            fetchBridgeInfo({
              hash,
              walletAddress: evmAddress as string,
              destinationNetwork: tokenData?.destinationToken.network as string,
              onComplete: onBridgeCompleted,
              updateGlobalState: true,
              amount: nftBridgeSelected ? '0' : parseUnits(amount, selectedWalletToken?.decimals ?? 18).toString(),
              token: nftBridgeSelected
                ? selectedNftObjects[0].contract
                : selectedWalletToken?.isNative
                  ? '0x0000000000000000000000000000000000000000'
                  : selectedWalletToken?.contract ?? '',
              network: tokenData.sourceToken.network,
              tokenName: nftBridgeSelected ? (selectedNftObjects[0] as EvmNft).tokenId : undefined,
              numberOfNfts: nftBridgeSelected ? selectedNftObjects.length : 0,
            });
          }
        } catch (err) {
          captureError(err);
          if (String(err).includes('user rejected transaction')) {
            setBridgeData({ initiatedBridge: false, approving: false });
          } else {
            setBridgeData({ initiatedBridge: false, approving: false, bridgeFailed: true });
          }
        }
      }
    } else if (completedStep === 3 || bridgeFailed) {
      if (completedStep === 3) {
        capture(completedStep === 3 ? MetricEvents.UserClicksBridgeAgain : MetricEvents.UserClicksTryAgain);
      }
      setBridgeData({
        completedStep: 0,
        startedAt: undefined,
        bridgeAgain: false,
        bridgeFailed: false,
        initiatedBridge: false,
        bridgingCardano: false,
        feeAmount: '',
        data: undefined,
        liquidityData: { amount: '', destinationNetwork: '' },
      });
      setDetailsKey(detailsKey + 1);
      setInputData({ amount: '', realAmount: '' }, true);
      setSelectedWalletToken(undefined);
    }
  }, [
    amount,
    bridgeFailed,
    cardanoAddress,
    completedStep,
    deadline,
    detailsKey,
    evmAddress,
    evmRequiresApproval,
    evmTxFeeData?.maxFeePerGas,
    evmTxFeeData?.maxPriorityFeePerGas,
    extEvmWallet,
    feeAmount,
    isTransferingFullNativeAmount,
    nativeToken?.decimals,
    nftBridgeSelected,
    onApprovalCompleted,
    onBridgeCompleted,
    realAmount,
    selectedNftObjects,
    selectedNftWallet,
    setBridgeData,
    setDetailsKey,
    setInputData,
    setSelectedWalletToken,
    signature,
    tokenData.destinationToken.network,
    tokenData.sourceToken.network,
    bridgeContracts,
    selectedWalletToken,
    liquidityData,
  ]);

  const getButtonText = useCallback(() => {
    return bridgeFailed ? (
      'TRY AGAIN'
    ) : liquidityData.amount && !wrongEvmNetwork ? (
      evmRequiresApproval ? (
        'I UNDERSTAND, APPROVE ANYWAY'
      ) : (
        'I UNDERSTAND, BRIDGE ANYWAY'
      )
    ) : approving ? (
      <>
        APPROVING <AnimatedEllipsis />
      </>
    ) : evmRequiresApproval ? (
      nftBridgeSelected && selectedNftWallet === SupportedWallet.EVM ? (
        `APPROVE NFT${selectedNftObjects.length > 1 ? 'S' : ''}`
      ) : (
        'APPROVE BRIDGE AMOUNT'
      )
    ) : bridgeAgain ? (
      'BRIDGE AGAIN'
    ) : completedStep === 3 ? (
      'BRIDGED!'
    ) : initiatedBridge ? (
      <>
        BRIDGING <AnimatedEllipsis />
      </>
    ) : (
      `BRIDGE TO ${tokenData.destinationToken.network}`
    );
  }, [
    approving,
    bridgeAgain,
    bridgeFailed,
    completedStep,
    evmRequiresApproval,
    initiatedBridge,
    nftBridgeSelected,
    selectedNftObjects.length,
    selectedNftWallet,
    tokenData.destinationToken.network,
    liquidityData,
    wrongEvmNetwork,
  ]);

  const showWarningTooltip = useMemo(() => {
    let destinationNetwork = '';
    if (
      tokenData.destinationToken.network === CARDANO_NETWORK &&
      !cardanoAddress &&
      externalWalletData.chainType !== NetworkChainType.CARDANO
    )
      destinationNetwork = uppercaseFirstLetter(CARDANO_NETWORK);
    if (
      tokenData.destinationToken.network !== CARDANO_NETWORK &&
      !evmAddress &&
      externalWalletData.chainType !== NetworkChainType.EVM
    )
      destinationNetwork = 'EVM';

    return destinationNetwork &&
      ((nftBridgeSelected && selectedNftObjects.length) || (!nftBridgeSelected && +amount > 0))
      ? `Please connect your ${destinationNetwork} wallet or provide recipient wallet address to continue.`
      : null;
  }, [amount, evmAddress, cardanoAddress, tokenData, externalWalletData, nftBridgeSelected, selectedNftObjects.length]);

  const button = useMemo(
    () => (
      <Button
        onClick={handleBridgeClicked}
        className={cn(
          'h-[59px] w-full rounded-lg bg-meldred font-semibold uppercase tracking-[0.5px] text-white hover:bg-meldred/80',
          (isBridging || cantBridge || showWarningTooltip || approving || initiatedBridge || disableButton) &&
            !bridgeData &&
            !bridgeFailed &&
            'pointer-events-none',
          (cantBridge || showWarningTooltip || disableButton) && !bridgeData && !bridgeFailed && 'opacity-60',
          (isBridging || approving || initiatedBridge) && !bridgeData && 'animate-pulse',
          bridgeFailed && 'bg-meldblack text-meldwhite hover:bg-meldblack/80',
        )}
      >
        {getButtonText()}
      </Button>
    ),
    [
      approving,
      bridgeData,
      bridgeFailed,
      cantBridge,
      getButtonText,
      handleBridgeClicked,
      initiatedBridge,
      isBridging,
      showWarningTooltip,
      disableButton,
    ],
  );

  const toReturn = useMemo(
    () =>
      showWarningTooltip ? (
        <Tooltip withArrow arrowPlacement="top" content={showWarningTooltip}>
          <Box>{button}</Box>
        </Tooltip>
      ) : (
        button
      ),
    [button, showWarningTooltip],
  );

  return toReturn;
};
