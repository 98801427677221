import { Button } from '@mui/material';
import { shortenAddress } from '../../utils/shorten-address';
import { cn } from '../../utils/cn';
import { useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import { CONNECT_BUTTON_DEFAULT_STYLE } from 'src/contants/connect-button';
import { AnimatedEllipsis } from '@components/animated-ellipsis';
import { TokenIcon } from '@components/token-icon';
import { networks } from '@utils/networks/networks';
import { CARDANO_NETWORK } from 'src/contants/cardano';
import { MELD_NETWORK } from 'src/contants/meld';
import isMobile from 'is-mobile';

type Props = {
  onTriggerMenu: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClickCardanoAddress: (e: React.MouseEvent<HTMLElement>) => void;
  onClickEvmAddress: (e: React.MouseEvent<HTMLElement>) => void;
  onClickConnect: (e: React.MouseEvent<HTMLElement>) => void;
  connectingCardano: boolean;
  connectingEvm: boolean;
  className?: string;
  cardanoAddress?: string | null;
  evmAddress?: string;
  menuOpen: boolean;
  cardanoIcon?: string;
  evmIcon?: string;
};

const _isMobile = isMobile();

export const ConnectButton = ({
  cardanoAddress,
  evmAddress,
  onClickCardanoAddress,
  onClickEvmAddress,
  onClickConnect,
  connectingCardano,
  connectingEvm,
  cardanoIcon,
  evmIcon,
}: Props) => {
  const cardanoEl = useRef<HTMLButtonElement>(null);

  // this button shows as soon as a wallet is connected
  const secondButtonStates =
    !_isMobile && (evmAddress || cardanoAddress)
      ? {
          width: connectingEvm ? 220 : 183,
          opacity: 1,
        }
      : { width: 0, opacity: 0 };

  const secondButtonSpring = useSpring(secondButtonStates);

  // this button always shows, either as "CONNECT" to connect any wallet or as a Cardano button
  const firstButtonStates = {
    width: _isMobile
      ? connectingEvm
        ? 165
        : 145
      : connectingCardano || (!cardanoAddress && connectingEvm)
        ? 220
        : 183,
  };

  const firstButtonSpring = useSpring(firstButtonStates);

  const separatorStates = cardanoAddress || evmAddress ? { width: 1, opacity: 0.4 } : { width: 0, opacity: 0 };

  const separatorSpring = useSpring(separatorStates);

  return (
    <div className="flex h-max items-center">
      <div
        className={cn(
          'relative flex h-max items-center overflow-hidden rounded-lg',
          (cardanoAddress || evmAddress) && 'shadow-[inset_0px_0px_0px_1px_#D9D9D9]',
        )}
      >
        <animated.div style={firstButtonSpring}>
          <Button
            onClick={
              _isMobile
                ? evmAddress
                  ? onClickEvmAddress
                  : onClickConnect
                : cardanoAddress
                  ? onClickCardanoAddress
                  : onClickConnect
            }
            variant="outlined"
            className={cn(
              CONNECT_BUTTON_DEFAULT_STYLE,
              (cardanoAddress || evmAddress) && 'rounded-r-none border-0 lowercase',
              (connectingCardano ||
                (!cardanoAddress && connectingEvm) ||
                (!cardanoAddress && evmAddress) ||
                (!evmAddress && !cardanoAddress)) &&
                'uppercase',
            )}
          >
            {(connectingCardano || connectingEvm || cardanoAddress || evmAddress) && (
              <TokenIcon
                boxShadow={false}
                innerWrapperClassName="h-[24px] w-[24px] -translate-x-1"
                imgSrc={
                  !_isMobile && cardanoIcon
                    ? cardanoIcon
                    : !_isMobile && (cardanoAddress || connectingCardano || evmAddress)
                      ? networks[CARDANO_NETWORK]?.darkIcon ?? ''
                      : networks[MELD_NETWORK]?.darkIcon ?? ''
                }
              />
            )}
            {connectingCardano || (!cardanoAddress && connectingEvm) ? (
              <>CONNECTING {_isMobile ? '' : <AnimatedEllipsis />}</>
            ) : (!cardanoAddress && !_isMobile) || (_isMobile && !evmAddress) ? (
              'CONNECT'
            ) : (
              shortenAddress(_isMobile ? (evmAddress as string) : cardanoAddress ?? '', 4, 5)
            )}
          </Button>
        </animated.div>
        <animated.div style={separatorSpring} className={'h-[48px] bg-white md:h-[58px]'} />
        <animated.div style={secondButtonSpring}>
          <Button
            ref={cardanoEl}
            onClick={evmAddress ? onClickEvmAddress : onClickConnect}
            variant="outlined"
            className={cn(
              CONNECT_BUTTON_DEFAULT_STYLE,
              'border-0 ',
              'border-transparent',
              'rounded-l-none',
              (connectingEvm || !evmAddress) && 'uppercase',
            )}
          >
            <TokenIcon
              imgSrc={evmIcon ? evmIcon : networks[MELD_NETWORK]?.darkIcon ?? ''}
              boxShadow={false}
              innerWrapperClassName="h-[24px] w-[24px] -translate-x-[2px]"
            />
            {connectingEvm ? (
              <>
                CONNECTING <AnimatedEllipsis />
              </>
            ) : evmAddress ? (
              shortenAddress(evmAddress, 4, 5)
            ) : (
              'CONNECT'
            )}
          </Button>
        </animated.div>
      </div>
    </div>
  );
};
