import { useStore } from '@store/store';
import { cn } from '@utils/cn';
import { animated, config, useSpring, useSprings } from 'react-spring';
import { useEffect, useMemo, useRef, useState } from 'react';
import Flicking from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import arrowIcon from '../../assets/arrow.svg';
import Checkbox from '@mui/material/Checkbox';
import { NftDisplay } from '@components/nft-display';
import { useNFTS } from '@hooks/use-NFTS';
import { useSelectedNfts } from '@hooks/use-selected-nfts';
import { BridgeFee } from '@components/bridge-fee';
import { SupportedWallet } from '@typings/wallet';
import { useCalculateTxFee } from '@hooks/use-calculate-tx-fee';
import { useIsBridging } from '@hooks/use-is-bridging';
import { shallow } from 'zustand/shallow';
import { NetworkChainType } from '@api/meld-app/networks/networks.types';

type Props = {
  hidden?: boolean;
  onHideNfts?: () => void;
};

/**
 * @remarks
 * please note NFTs are displayed centered if allNfts.length <= 2
 */
export const NFTSelector = ({ hidden, onHideNfts }: Props) => {
  const flickingRef = useRef<Flicking | null>(null);
  const [unmounting, setUnmounting] = useState(false);
  const nftBridgeSelected = useStore((state) => state.nftBridgeSelected);
  const [animateTitle, setAnimateTitle] = useState(false);
  const [currIndex, setCurrIndex] = useState(0);
  const { transactionCost, feeAmount, notEnoughToken, approving } = useStore((state) => state.bridgeData, shallow);
  const { evmRequiresApproval } = useStore((state) => state.evmData, shallow);
  const { inputError } = useStore((state) => state.inputData, shallow);

  const { isLoading: isLoadingTransactionFee } = useCalculateTxFee();
  const isBridging = useIsBridging();

  const { isLoading: isLoadingNfts } = useNFTS();
  const {
    allNfts,
    nativeNftToken,
    numberOfSelectedCardanoNfts,
    selectedNfts,
    setSelectedNfts,
    selectedNftWallet,
    selectedNftObjects,
  } = useSelectedNfts();

  const [trails] = useSprings(allNfts.length, (index: number) => ({
    from: {
      width: 16,
      height: 16,
      x: 300,
      y: -92,
      opacity: 0,
    },
    to: { x: 0, y: 0, height: 90, width: 90, opacity: 1 },
    config: config.slow,
    delay: index * 50,
    onChange: (e) => {
      if (e.value.opacity > 0.9) {
        setAnimateTitle(true);
      }
    },
  }));

  const titleStates = animateTitle ? { opacity: 1 } : { opacity: 0 };
  const titleSpring = useSpring(titleStates);

  const refVal = flickingRef?.current;
  const handleChanged = (data: { index: number }) => {
    setCurrIndex(data.index);
  };
  refVal?.on('changed', handleChanged);

  useEffect(() => {
    setTimeout(() => {
      setCurrIndex(0);
    }, 200);
  }, [allNfts.length]);

  useEffect(() => {
    if (!nftBridgeSelected && onHideNfts) {
      setUnmounting(true);
    }
  }, [nftBridgeSelected, onHideNfts]);

  const unmountingSpring = useSpring(
    unmounting
      ? {
          opacity: 0,
          onChange: (val) => {
            if (val.value.opacity < 0.1) onHideNfts?.();
          },
          config: config.slow,
        }
      : { opacity: 1 },
  );

  const noNftsOrLoading = isLoadingNfts || allNfts.length === 0;

  const noNftOrLoadingSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 300,
    onChange: (e) => {
      if (e.value.opacity > 0.9) {
        setAnimateTitle(true);
      }
    },
  });

  const content = useMemo(
    () =>
      trails.map((props, index) => {
        const cannotSelectNft =
          (allNfts[index].chainType === NetworkChainType.CARDANO &&
            numberOfSelectedCardanoNfts >= 5 &&
            !selectedNftObjects.filter((a) => a.nftId === allNfts[index].nftId).length) ||
          (selectedNftWallet && selectedNftWallet !== allNfts[index].wallet) ||
          (selectedNftWallet === SupportedWallet.EVM &&
            selectedNftObjects.length &&
            allNfts[index].nftId !== selectedNftObjects[0].nftId);

        return (
          <animated.div
            key={index}
            style={props}
            className={cn('panel relative mr-3 [&_img]:h-full [&_img]:w-full', index === trails.length - 1 && 'mr-0')}
            onClick={() => (cannotSelectNft ? undefined : setSelectedNfts(allNfts[index].nftId, allNfts[index].wallet))}
          >
            <div className={cn('opacity-100 transition-opacity duration-300', cannotSelectNft && '!opacity-60')}>
              <NftDisplay {...allNfts[index]} />
              <div className="absolute top-0 h-full w-full bg-[linear-gradient(207deg,#00000070,transparent_25%)]" />
              <animated.div style={titleSpring}>
                <Checkbox
                  checked={!!selectedNfts[allNfts[index].nftId]}
                  disableRipple
                  classes={{ checked: 'text-meldwhite' }}
                  className={cn('absolute -right-[10px] -top-[10px] text-meldwhite [&_svg]:text-[28px]')}
                />
              </animated.div>
            </div>
          </animated.div>
        );
      }),
    [
      trails,
      allNfts,
      selectedNftObjects,
      selectedNftWallet,
      selectedNfts,
      setSelectedNfts,
      numberOfSelectedCardanoNfts,
      titleSpring,
    ],
  );

  return (
    <animated.div style={unmountingSpring} className={cn(' z-10', hidden && 'pointer-events-none absolute !opacity-0')}>
      <animated.p
        style={titleSpring}
        className="m-0 mb-[6px] font-semibold text-[11px] uppercase tracking-[0.44px] text-meldwhite"
      >
        Bank Managers{' '}
        {selectedNftObjects.length > 1 && (
          <span
            className={cn(
              'text-[10px] text-meldwhite/50 opacity-0 transition-opacity duration-300',
              selectedNftObjects.length && 'opacity-100',
            )}
          >
            ({selectedNftObjects.length || 1} selected)
          </span>
        )}
      </animated.p>
      <div className="relative flex justify-center">
        <animated.div
          key={allNfts.length.toString()}
          style={noNftsOrLoading ? noNftOrLoadingSpring : undefined}
          className={cn(
            'flex w-max min-w-[294px] max-w-[70vw] cursor-grab select-none rounded-[4px] border-dashed border-transparent px-2 py-[9px] transition-colors duration-300 md:max-w-[600px]',
            (animateTitle || noNftsOrLoading) && 'border-meldwhite',
            (isBridging || approving) && 'pointer-events-none opacity-50',
          )}
        >
          {noNftsOrLoading ? (
            <div className="flex w-full flex-row justify-center gap-2">
              {isLoadingNfts ? (
                <NftDisplay isLoading={isLoadingNfts} noNfts={allNfts.length === 0} />
              ) : (
                <>
                  <NftDisplay noNfts noNftsLabel={false} />
                  <NftDisplay noNfts />
                  <NftDisplay noNfts noNftsLabel={false} />
                </>
              )}
            </div>
          ) : allNfts.length > 2 ? (
            <Flicking renderOnSameKey ref={flickingRef} circular={false} align="prev">
              {content}
            </Flicking>
          ) : (
            <div className="flex w-full flex-row justify-center">{content}</div>
          )}
        </animated.div>
        <div
          className={cn('absolute -bottom-[18px] flex w-full justify-end font-semibold text-[11px] text-meldwhite/50')}
        >
          <animated.div style={titleSpring} className={cn('flex w-full items-center gap-2')}>
            <BridgeFee
              inputError={selectedNftWallet && !isLoadingTransactionFee ? inputError : null}
              selectedWalletToken={selectedNftWallet === SupportedWallet.EVM ? undefined : undefined}
              nativeToken={nativeNftToken}
              isLoadingTransactionFee={isLoadingTransactionFee}
              amount={selectedNftObjects.length.toString()}
              transactionCost={transactionCost}
              noAbsolutePositioning
              bridgeBackFee={nftBridgeSelected ? feeAmount : undefined}
              needsApproval={evmRequiresApproval ?? true}
              bridgingBack={selectedNftWallet === SupportedWallet.EVM}
              bridgingNft
              // notEnoughToken should be reset in some circunstances...
              notEnoughToken={selectedNftWallet === SupportedWallet.EVM && notEnoughToken}
            />
          </animated.div>
        </div>
        <img
          src={arrowIcon}
          className={cn(
            'pointer-events-none absolute -left-[54px] top-[50%] mx-[-5px] h-[60px] w-[60px] -translate-y-1/2 rotate-180 cursor-pointer opacity-0 transition-all duration-300',
            currIndex > 0 && allNfts.length > 3 && 'pointer-events-auto opacity-100',
            (isBridging || approving) && 'pointer-events-none',
          )}
          onClick={() => flickingRef?.current?.moveTo(currIndex - 2)}
        />
        <animated.img
          style={titleSpring}
          src={arrowIcon}
          className={cn(
            'absolute -right-[54px] top-[50%] mx-[-5px] h-[60px] w-[60px] -translate-y-1/2 cursor-pointer transition-all duration-300',
            (currIndex >= trails.length - 2 || allNfts.length <= 3) && 'pointer-events-none !opacity-0',
            (isBridging || approving) && 'pointer-events-none',
          )}
          onClick={() => flickingRef?.current?.moveTo(currIndex + 2)}
        />
      </div>
    </animated.div>
  );
};
