import { Nft } from '@api/meld-app/nfts/nfts-query.types';
import { useStore } from '@store/store';
import { useEffect, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

export const useSelectedNfts = () => {
  const nftBridgeSelected = useStore((state) => state.nftBridgeSelected);
  const selectedNfts = useStore((state) => state.selectedNfts, shallow);
  const cardanoData = useStore((state) => state.cardanoData, shallow);
  const evmData = useStore((state) => state.evmData, shallow);
  const setSelectedNfts = useStore((state) => state.setSelectedNfts);
  const userTokens = useStore((state) => state.userTokens, shallow);
  const setBridgeData = useStore((state) => state.setBridgeData);
  const setInputData = useStore((state) => state.setInputData);

  const { selectedNftObjects, numberOfSelectedCardanoNfts } = useMemo(() => {
    const selectedCardanoNfts = cardanoData.cardanoNfts.filter((a) => selectedNfts[a.nftId]) as Array<Nft>;
    return {
      selectedNftObjects: selectedCardanoNfts.concat(evmData.evmNfts.filter((a) => selectedNfts[a.nftId])),
      numberOfSelectedCardanoNfts: selectedCardanoNfts.length,
    };
  }, [selectedNfts, cardanoData.cardanoNfts, evmData.evmNfts]);

  const allNfts = useMemo(
    () => (cardanoData.cardanoNfts as Array<Nft>).concat(evmData.evmNfts),
    [cardanoData.cardanoNfts, evmData.evmNfts],
  );

  useEffect(() => {
    if (selectedNftObjects.length === 0) {
      setInputData({ inputError: null });
    }
  }, [selectedNftObjects.length, setBridgeData, setInputData]);

  const { selectedNftWallet, selectedNftNetwork } = useMemo(
    () => ({
      selectedNftWallet: selectedNftObjects?.[0]?.wallet,
      selectedNftNetwork: selectedNftObjects?.[0]?.network,
    }),
    [selectedNftObjects],
  );

  const nativeNftToken = useMemo(
    () =>
      nftBridgeSelected ? userTokens.filter((a) => a.network === selectedNftNetwork && a.isNative).shift() : undefined,
    [nftBridgeSelected, userTokens, selectedNftNetwork],
  );

  return {
    setSelectedNfts,
    cardanoNfts: cardanoData.cardanoNfts,
    selectedNftObjects,
    nftBridgeSelected,
    selectedNfts,
    allNfts,
    selectedNftWallet,
    nativeNftToken,
    selectedNftNetwork,
    numberOfSelectedCardanoNfts,
  };
};
