import { RefObject, memo, useEffect, useRef, useState } from 'react';
import { cn } from '../utils/cn';
import shareIcon from '../assets/share.svg';
import { Box } from '@mui/material';
import { Timeout } from 'react-number-format/types/types';
import dayjs from 'dayjs';

const ALL_STEPS = [{ name: 'sent' }, { name: 'confirmed' }, { name: 'deposited' }];

type StepsProps = {
  explorerUrl?: string;
  completedStep?: number;
};

const Steps = ({ explorerUrl, completedStep = -1 }: StepsProps) => {
  const firstWordRef = useRef<HTMLDivElement>(null);
  const firstCircleRef = useRef<HTMLDivElement>(null);
  const middleCircleRef = useRef<HTMLDivElement>(null);
  const lastCircleRef = useRef<HTMLDivElement>(null);
  const [lineData, setLineData] = useState({ width: 0, offsetLeft: 0, firstWidthPercent: 0 });

  useEffect(() => {
    const drawLine = () => {
      if (firstCircleRef.current && lastCircleRef.current && firstWordRef.current && middleCircleRef.current) {
        const { x } = firstCircleRef.current.getBoundingClientRect();
        const { x: middleX } = middleCircleRef.current.getBoundingClientRect();
        const { x: lastX } = lastCircleRef.current.getBoundingClientRect();
        const { x: firstWordX } = firstWordRef.current.getBoundingClientRect();

        const width = lastX - x;
        const diffBetweenFirstAndSecond = middleX - x;

        setLineData({
          width,
          offsetLeft: x - firstWordX,
          firstWidthPercent: (diffBetweenFirstAndSecond * 100) % width,
        });
      }
    };

    drawLine();

    window.addEventListener('resize', drawLine);

    return () => window.removeEventListener('resize', drawLine);
  }, []);

  return (
    <div className="flex gap-2 xm:gap-3 md:gap-8">
      {ALL_STEPS.map(({ name }, index) => (
        <div className="relative" key={name}>
          <div
            ref={index === 0 ? firstCircleRef : index === ALL_STEPS.length - 1 ? lastCircleRef : middleCircleRef}
            className={cn(
              'relative left-[50%] mb-4 h-[7px] w-[7px] -translate-x-2/4 rounded-full bg-meldgray transition-all',
              completedStep > index && 'bg-meldgreen',
            )}
          />
          {index === 0 && (
            <Box
              className={cn(
                'absolute top-[2.5px] z-[-1] h-[2px] bg-meldgray',
                // after
                "after:relative after:z-0 after:block after:h-full after:w-0 after:bg-meldgreen after:transition-all after:duration-1000 after:content-['_']",
                !!completedStep && 'after:w-[45%]',
                completedStep > 1 && 'after:w-[100%]',
              )}
              style={{ width: lineData.width, left: lineData.offsetLeft }}
              sx={{
                '&:after': {
                  width: completedStep >= 2 ? `${lineData.firstWidthPercent}%` : '0%',
                },
              }}
            />
          )}
          <div
            ref={index === 0 ? firstWordRef : undefined}
            className={cn(
              'relative font-semibold text-[11px] tracking-[0.44px] text-meldwhite/50 transition-all',
              completedStep > index && 'text-meldgreen',
            )}
          >
            {name}
            {index === 0 && (
              <a href={explorerUrl} target="_blank">
                <img
                  src={shareIcon}
                  className={cn(
                    'absolute -bottom-3 left-[50%] h-3 w-3 -translate-x-2/4 cursor-pointer opacity-0 transition-all delay-1000',
                    explorerUrl && 'opacity-100',
                  )}
                />
              </a>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

type TimeAgoProps = {
  startedAt?: number; // timestamp
  bridged?: boolean;
  bridgeFailed: boolean;
};

const TimeAgo = ({ startedAt, bridged, bridgeFailed }: TimeAgoProps) => {
  const [active, setActive] = useState(!!startedAt);
  const [data, setData] = useState({ minutes: 0, seconds: 0 });
  const intervalRef = useRef<Timeout | undefined>(undefined);

  useEffect(() => {
    setActive(!!startedAt);

    if (startedAt) {
      intervalRef.current = setInterval(() => {
        const secondsDiff = dayjs().diff(dayjs(startedAt), 'seconds');
        setData({ seconds: secondsDiff % 60, minutes: Math.floor(secondsDiff / 60) });
      }, 1000);
    }

    return () => clearInterval(intervalRef.current);
  }, [startedAt]);

  useEffect(() => {
    if (bridged || bridgeFailed) {
      clearInterval(intervalRef.current);
    }
  }, [bridged, bridgeFailed]);

  return (
    <div className={cn('flex gap-2 text-meldwhite xm:gap-3 md:gap-5', !active && 'text-meldwhite/50')}>
      <div className="flex flex-col items-center">
        <div className="text-[24px]">{data.minutes}</div>
        <div className={cn('font-semibold text-[11px] uppercase tracking-[0.44px]')}>
          {data.minutes === 1 ? 'Minute' : 'Minutes'}
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div className="text-[24px]">{data.seconds}</div>
        <div className={cn('font-semibold text-[11px] uppercase tracking-[0.44px]')}>
          Second<span className={data.seconds === 1 ? 'opacity-0' : 'opacity-100'}>s</span>
        </div>
      </div>
    </div>
  );
};

type Props = {
  explorerUrl?: string;
  componentRef: RefObject<HTMLDivElement>;
} & TimeAgoProps &
  StepsProps;

export const Details = memo(({ startedAt, explorerUrl, completedStep, bridged, bridgeFailed, componentRef }: Props) => {
  return (
    <div
      ref={componentRef}
      className="mt-[26px] box-border flex w-full items-end justify-center gap-3 rounded-lg bg-meldwhite/20 pb-[21px] pl-3 pr-3 pt-[28px] uppercase xm:gap-4 md:max-w-[451px] md:gap-6 md:pl-10 md:pr-8"
    >
      <Steps completedStep={completedStep} explorerUrl={explorerUrl} />
      <TimeAgo startedAt={startedAt} bridged={bridged} bridgeFailed={bridgeFailed} />
    </div>
  );
});
