import { CARDANO_NETWORK } from 'src/contants/cardano';

export type PendingBridgeStorage = {
  hash: string;
  walletAddress: string;
  destinationNetwork: string;
  amount: string;
  token: string;
  network: string;
  tokenName?: string;
};

export const addPendingBridgeToLocalStorage = (data: PendingBridgeStorage) => {
  const storedData = localStorage.getItem('pending-bridges');
  const parsedStoredData: Array<PendingBridgeStorage> = storedData ? JSON.parse(storedData) : [];
  const existingBridge = parsedStoredData.filter(({ hash }) => hash === data.hash).shift();

  if (!existingBridge) {
    parsedStoredData.push(data);
    localStorage.setItem('pending-bridges', JSON.stringify(parsedStoredData));
  }
};

export const removePendingBridgeFromLocalStorage = (data: PendingBridgeStorage) => {
  const storedData = localStorage.getItem('pending-bridges');
  const parsedStoredData: Array<PendingBridgeStorage> = storedData ? JSON.parse(storedData) : [];
  const newParsedStoredData = parsedStoredData.filter(({ hash }) => hash !== data.hash);

  localStorage.setItem('pending-bridges', JSON.stringify(newParsedStoredData));
};

export const getPendingBridgesFromLocalStorage = (evm: boolean) => {
  const storedData = localStorage.getItem('pending-bridges');
  const parsedStoredData = storedData ? JSON.parse(storedData) : [];

  return (parsedStoredData as Array<PendingBridgeStorage>).filter((a) =>
    evm ? a.network !== CARDANO_NETWORK : a.network === CARDANO_NETWORK,
  );
};
