import SVG from 'react-inlinesvg';

import { Network } from '@api/meld-app/networks/networks.types';

import { uppercaseFirstLetter } from '@utils/format-string.util';
import { isNotProdEnv } from '@utils/environments-helper';

export type Networks = Record<string, Network>;

export type INetworkNames = {
  // mainnet
  avalanche: string;
  cardano: string;
  ethereum: string;
  meld: string;
  // testnet
  kanazawa: string;
  fuji: string;
  mumbai: string;
  preprod: string;
};

/**
 * Use this to get the proper network key (e.g. networkNames.meld for "meld" / "kanazawa").
 */
export let networkNames: INetworkNames = {
  // mainnet
  avalanche: '',
  cardano: '',
  ethereum: '',
  meld: '',
  // testnet
  kanazawa: '',
  fuji: '',
  mumbai: '',
  preprod: '',
};

export const testNetworkMap = {
  kanazawa: 'meld',
  fuji: 'avalanche',
  mumbai: 'ethereum',
  preprod: 'cardano',
};

/**
 * EX: { preprod: networkObject, fuji: networkObject, ...etc}
 *
 * - to access this correctly when possible just pass the network name from API responses (e.g. networks[selectedNFT.network], networks[selectedToken.chainName])
 * - if you want to access it manually use networkNames -> network[networkNames.cardano]
 */
export let networks: Networks = {};

/**
 * Array with all EVM networks.
 */
export let evmNetworks: Network[] = [];

export const mapNetworkToDropdown = (network: Network) => {
  return {
    label: uppercaseFirstLetter(network.name),
    value: network.name,
    chainId: network.chainId,
    symbol: network.defaultTokenSymbol,
    bgColor: 'grey',
    token: network.defaultTokenSymbol,
    icon: <SVG uniquifyIDs src={network.lightIcon} />,
  };
};

// these are used within assets-slice, once we set the networks there (from getting them from the BE we set the variables above so they can be accessed within the app)
// the idea would be to remove the variables above altogether but the entire codebase uses them and that would be a big refactoring
export const setNetworks = (newNetworks: Networks) => (networks = newNetworks);
export const setNetworkNames = (newNetworkNames: INetworkNames) => (networkNames = newNetworkNames);
export const setEvmNetworks = (newEvmNetworks: Network[]) => (evmNetworks = newEvmNetworks);

export const isMeldNetwork = (networkName = '') =>
  networkName.toLowerCase() === (isNotProdEnv ? networkNames.kanazawa : networkNames.meld);
