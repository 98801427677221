import { useCallback, useEffect, useMemo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { ValueInputError } from '../../types/ValueInputError';
import { WalletToken } from '@typings/wallet-asset.types';
import { useStore } from '@store/store';
import { cn } from '@utils/cn';
import { CARDANO_NETWORK } from 'src/contants/cardano';

type Props = {
  amount: string;
  handleAmountChange: (amount: string, error?: ValueInputError) => void;
  selectedToken: WalletToken | undefined;
  customMaxAmount: string;
  placeholder?: string;
  thousandsSeparator: string;
  decimalSeparator: string;
};

export const Input = ({
  amount,
  handleAmountChange,
  thousandsSeparator,
  selectedToken,
  customMaxAmount,
  placeholder,
  decimalSeparator,
}: Props) => {
  const setInputData = useStore((state) => state.setInputData);
  const bridgeFailed = useStore((state) => state.bridgeData.bridgeFailed);

  const [inputKey, setInputKey] = useState(0);
  const inputError = useStore((state) => state.inputData.inputError);

  const enteredAmount = useMemo(() => {
    const numericValue = parseFloat(amount.replaceAll(',', '.'));

    return !Number.isNaN(numericValue) ? numericValue : 0;
  }, [amount]);

  const maxTokenDecimals = useMemo(() => selectedToken?.decimals ?? 18, [selectedToken?.decimals]);

  const handleChange = useCallback(
    (inputValue: string) => {
      let error: ValueInputError | undefined;

      if (inputValue === '') {
        handleAmountChange('');
        setInputData({ inputError: null });
        return;
      }

      const decimals = inputValue.split(/[,.]/)[1];

      if (
        ((selectedToken?.network === CARDANO_NETWORK && selectedToken.isNative) ||
          (selectedToken?.toBridgeBack && (selectedToken.symbol === 'tADA' || selectedToken.symbol === 'ADA'))) &&
        +inputValue < 1
      ) {
        error = ValueInputError.UNDER_MIN_CARDANO;
        setInputData({ inputError: ValueInputError.UNDER_MIN_CARDANO });
      } else if (decimals && decimals.length > maxTokenDecimals) {
        error = ValueInputError.TOO_MANY_DECIMALS;
        setInputData({ inputError: ValueInputError.TOO_MANY_DECIMALS });
      } else if (inputValue && +inputValue > +customMaxAmount) {
        error = ValueInputError.ABOVE_MAX;
        setInputData({ inputError: ValueInputError.ABOVE_MAX });
      } else if (inputError !== ValueInputError.NOT_ENOUGH_NATIVE_TOKEN) setInputData({ inputError: null });

      handleAmountChange(inputValue, error);
    },
    [customMaxAmount, handleAmountChange, maxTokenDecimals, setInputData, selectedToken, inputError],
  );

  useEffect(() => {
    // reset input when no selected token exists
    // for some reason setting the "amount" to "" doesn't reset the input...
    setInputKey((key) => key + 1);
  }, [selectedToken?.tokenId]);

  return (
    <div className={cn('w-full')}>
      <NumericFormat
        key={inputKey}
        value={enteredAmount === 0 ? undefined : enteredAmount}
        onValueChange={(values) => {
          handleChange(values.value);
        }}
        allowNegative={false}
        thousandSeparator={thousandsSeparator}
        decimalSeparator={decimalSeparator}
        name="amount"
        placeholder={placeholder ?? thousandsSeparator === ',' ? '0.00' : '0,00'}
        className={cn(
          'relative z-[2] box-border h-[59px] w-full rounded-lg border-0 px-[16.57px] py-0 font-semibold text-xl outline-none md:w-[263px]',
          !selectedToken && 'pointer-events-none',
          bridgeFailed && 'bg-meldwhite/50',
        )}
      />
    </div>
  );
};
