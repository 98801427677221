import { animated, config, useTrail } from 'react-spring';

export const AnimatedEllipsis = () => {
  const trails = useTrail(3, {
    from: { opacity: 1 },
    to: { opacity: 0.1 },
    loop: true,
    config: config.slow,
  });

  return (
    <div className="ml-1 flex gap-1 text-xl">
      {trails.map((props) => (
        <animated.div className={'opacity-10 text-meldwhite'} style={props}>
          .
        </animated.div>
      ))}
    </div>
  );
};
