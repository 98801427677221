import { AxiosResponse } from 'axios';
import { axiosInstance } from '@api/axios';

type PaginatedApiResponse<T> = {
  data: T[];
  nextCursor?: string;
};

export const fetchPaginatedDataCursor = async <T, Y extends { cursor?: string }>(
  endpoint: string,
  params: Y,
): Promise<T[]> => {
  const allData: T[] = [];
  let currentCursor = params.cursor;

  while (true) {
    const response: AxiosResponse<PaginatedApiResponse<T>> = await axiosInstance.get(endpoint, {
      params: { ...params, cursor: currentCursor },
    });

    if (response.data.data) {
      allData.push(...response.data.data);
    }

    if (!response.data.nextCursor) {
      break;
    }

    currentCursor = response.data.nextCursor;
  }

  return allData;
};
