import { useStore } from '@store/store';
import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';

export const useNativeToken = (data: { network?: string }) => {
  const userTokens = useStore((state) => state.userTokens, shallow);

  const nativeToken = useMemo(() => {
    const nativeToken = userTokens.filter((asset) => asset.network === data.network && asset.isNative).shift();

    return nativeToken;
  }, [data.network, userTokens]);

  return nativeToken;
};
