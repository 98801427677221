import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import {
  GET_AVAILABLE_TOKENS_QUERY_KEY,
  getAvailableTokensQuery,
} from '@api/meld-app/available-tokens/available-tokens-query';
import { getNetworksQuery } from '@api/meld-app/networks/networks-query';
import { useStore } from '@store/store';
import { SUPPORTED_TOKENS_LOCALSTORAGE_KEY, NETWORKS_LOCALSTORAGE_KEY } from 'src/contants/localstorage';
import { parseAvailableTokens } from './utils/parse-available-tokens';
import { parseNetworks } from './utils/parse-networks';
import { getBridgeContracts } from '@api/bridge/contracts';

/**
 * Fetches `/tokens` and `/networks` from the BE and pushes the data to our state and saves it in local storage.
 *
 * @remarks
 * - Adds a few properties to the tokens, that it gets from `/networks` and also processes the SVG icon URL to be used accross the app.
 * - If we already have data in storage then we initialise the app with that data relying on 'placeholderData' from react-query.
 * Which allows us to tell react-query "use this data while you're fetching the latest".
 */
// TODO - add error handling
export const useInitialBEData = () => {
  const setAvailableTokens = useStore((state) => state.setAvailableTokens);
  const setNetworks = useStore((state) => state.setNetworks);
  const setBridgeContracts = useStore((state) => state.setBridgeContracts);

  const [hasFetched, setHasFetched] = useState(false);

  const { data, isLoading } = useQuery(
    [GET_AVAILABLE_TOKENS_QUERY_KEY],
    async () => {
      const [networksData, tokensData, bridgeContracts] = await Promise.all([
        getNetworksQuery(),
        getAvailableTokensQuery(),
        getBridgeContracts(),
      ]);

      setBridgeContracts(bridgeContracts);

      const networks = parseNetworks(networksData, bridgeContracts);

      const availableTokens = parseAvailableTokens(tokensData, networks);

      return { availableTokens, networks };
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setHasFetched(true);
      },
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    if (data && data.availableTokens && data.networks) {
      // store available tokens in local storage
      localStorage.setItem(SUPPORTED_TOKENS_LOCALSTORAGE_KEY, JSON.stringify(data.availableTokens));
      setAvailableTokens(data.availableTokens);

      // store networks in local storage
      localStorage.setItem(NETWORKS_LOCALSTORAGE_KEY, JSON.stringify(data.networks));
      setNetworks(data.networks);
    }
  }, [data, setAvailableTokens, setNetworks, isLoading, hasFetched]);

  return isLoading;
};
