export const parseCardanoAsset = (hex: string) => {
  const policyIdSize = 56;
  const policyId = hex.slice(0, policyIdSize);
  const assetNameHex = hex.substring(policyIdSize);
  const assetName = Buffer.from(assetNameHex, 'hex').toString();

  return {
    policyId,
    assetName,
    assetNameHex,
  };
};
