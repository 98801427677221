import { cn } from '@utils/cn';
import { memo, useEffect, useRef, useState } from 'react';
import { ReturnValue } from 'use-count-up';

type Props = {
  tokenAmount: number;
  onClick: (tokenAmount: number) => void;
  animatedValue: ReturnValue;
  isLoading: boolean;
  formattedValue: string;
};

export const Balance = memo(({ tokenAmount, onClick, animatedValue, isLoading, formattedValue }: Props) => {
  const balanceTextRef = useRef<HTMLSpanElement | null>(null);
  const [balanceLabelLeftOffset, setBalanceLabelLeftOffset] = useState(-1);

  useEffect(() => {
    if (balanceTextRef.current) {
      const { width } = balanceTextRef.current.getBoundingClientRect();
      if (tokenAmount === 0) setBalanceLabelLeftOffset(28.6640625);
      else setBalanceLabelLeftOffset(width + 5);
    }
  }, [tokenAmount]);

  return (
    <div className="relative flex justify-end font-semibold text-[11px] text-meldwhite/50">
      <span className="absolute right-0 transition-all duration-1000" style={{ right: balanceLabelLeftOffset }}>
        BALANCE
      </span>
      <span
        className={cn('text-meldwhite', tokenAmount && 'cursor-pointer', isLoading && 'animate-pulse')}
        onClick={() => onClick(tokenAmount)}
      >
        {animatedValue}
      </span>
      {/** Only used for measurements */}
      <span
        ref={balanceTextRef}
        className="pointer-events-none absolute font-semibold text-[11px] text-meldwhite opacity-0"
      >
        {formattedValue}
      </span>
    </div>
  );
});
