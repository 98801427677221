import { ValueInputError } from '@typings/ValueInputError';
import { WalletToken } from '@typings/wallet-asset.types';
import { cn } from '@utils/cn';
import { formatCryptoBalance, formatCurrency } from '@utils/format-currency';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { Tooltip } from './tooltip';
import { formatUnits } from 'ethers/lib/utils';
import { useOnClickOutside } from 'usehooks-ts';
import isMobile from 'is-mobile';

type Props = {
  inputError: ValueInputError | null;
  selectedWalletToken?: WalletToken;
  nativeToken?: WalletToken;
  isLoadingTransactionFee: boolean;
  amount: string;
  transactionCost: string;
  noAbsolutePositioning?: boolean;
  bridgeBackFee?: string;
  notEnoughToken?: boolean;
  needsApproval: boolean;
  bridgingBack: boolean;
  bridgingNft?: boolean;
  hasMultipleNfts?: boolean;
};

const Arrow = ({ className }: { className?: string }) => (
  <svg className={className} focusable="false" width={20} height={20} aria-hidden="true" viewBox="0 0 24 24">
    <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6z" fill="currentColor"></path>
  </svg>
);

const TxFeeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
    <path
      d="M10.2236 3.32935L10.2297 3.32305L7.97511 0.98083L7.33268 1.64824L8.61147 2.97676C8.04177 3.20342 7.63571 3.77009 7.63571 4.44379C7.63571 5.31268 8.3145 6.01787 9.15086 6.01787C9.36905 6.01787 9.56905 5.9675 9.75693 5.88564V10.4253C9.75693 10.7716 9.4842 11.0549 9.15086 11.0549C8.81753 11.0549 8.5448 10.7716 8.5448 10.4253V7.59194C8.5448 6.89935 7.99935 6.33268 7.33268 6.33268H6.72662V1.92527C6.72662 1.23268 6.18117 0.666016 5.5145 0.666016H1.87814C1.21147 0.666016 0.666016 1.23268 0.666016 1.92527V11.9993H6.72662V7.27713H7.63571V10.4253C7.63571 11.2942 8.3145 11.9993 9.15086 11.9993C9.98723 11.9993 10.666 11.2942 10.666 10.4253V4.44379C10.666 4.00935 10.4963 3.61268 10.2236 3.32935ZM5.40096 7.27713V10.7401H1.99935V6.43003H5.40096V7.27713ZM5.33268 5.07342H1.99935V1.99935H5.33268V5.07342ZM9.15086 5.07342C8.81753 5.07342 8.5448 4.79009 8.5448 4.44379C8.5448 4.0975 8.81753 3.81416 9.15086 3.81416C9.4842 3.81416 9.75693 4.0975 9.75693 4.44379C9.75693 4.79009 9.4842 5.07342 9.15086 5.07342Z"
      fill="white"
      fill-opacity="0.6"
    />
  </svg>
);

const BridgeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M4.66602 9.33268V7.27268C4.18602 7.05268 3.73935 6.78602 3.33268 6.47268V9.33268H4.66602ZM3.33268 11.9993H1.99935V10.666H0.666016V9.33268H1.99935V4.66602H3.33268V5.61935C4.53268 6.66602 6.17935 7.33268 7.99935 7.33268C9.81935 7.33268 11.466 6.66602 12.666 5.61935V4.66602H13.9993V9.33268H15.3327V10.666H13.9993V11.9993H12.666V10.666H3.33268V11.9993ZM11.3327 7.27268V9.33268H12.666V6.47268C12.2593 6.78602 11.8127 7.05268 11.3327 7.27268ZM10.666 9.33268V7.54602C10.2393 7.69935 9.79268 7.81268 9.33268 7.89268V9.33268H10.666ZM8.66602 9.33268V7.97268L7.99935 7.99935L7.33268 7.97268V9.33268H8.66602ZM6.66602 9.33268V7.89268C6.20602 7.81268 5.75935 7.69935 5.33268 7.54602V9.33268H6.66602Z"
      fill="white"
      fill-opacity="0.6"
    />
  </svg>
);

const ReceiptIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M12.1995 2H3.80112C3.73981 1.99991 3.67908 2.01284 3.6224 2.03805C3.56573 2.06326 3.51423 2.10027 3.47085 2.14695C3.42746 2.19363 3.39304 2.24906 3.36956 2.31009C3.34607 2.37111 3.33398 2.43652 3.33398 2.50258V14L5.20052 12.6586L6.60058 13.6645L8.00065 12.6586L9.40072 13.6645L10.8008 12.6586L12.6673 13.9993V2.50258C12.6673 2.43658 12.6553 2.37123 12.6318 2.31025C12.6084 2.24928 12.574 2.19387 12.5307 2.1472C12.4874 2.10053 12.436 2.06351 12.3794 2.03826C12.3228 2.013 12.2621 2 12.2009 2H12.1995ZM8.46712 9.54086H5.19985V8.53498H8.46645V9.54086H8.46712ZM8.00065 7.02724C7.62933 7.02724 7.27322 6.86831 7.01065 6.58542C6.74809 6.30253 6.60058 5.91885 6.60058 5.51878C6.60058 5.11871 6.74809 4.73503 7.01065 4.45214C7.27322 4.16925 7.62933 4.01032 8.00065 4.01032C8.37197 4.01032 8.72809 4.16925 8.99065 4.45214C9.25321 4.73503 9.40072 5.11871 9.40072 5.51878C9.40072 5.91885 9.25321 6.30253 8.99065 6.58542C8.72809 6.86831 8.37197 7.02724 8.00065 7.02724ZM10.8008 9.54014H9.39938V8.53498H10.7994V9.54086L10.8008 9.54014Z"
      fill="white"
      fill-opacity="0.6"
    />
  </svg>
);

export const BridgeFee = memo(
  ({
    inputError,
    selectedWalletToken,
    nativeToken,
    isLoadingTransactionFee,
    amount,
    noAbsolutePositioning,
    transactionCost,
    bridgeBackFee,
    notEnoughToken,
    needsApproval,
    bridgingBack,
    bridgingNft,
  }: Props) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const getInputErrorText = useCallback(
      () =>
        inputError === ValueInputError.ABOVE_MAX
          ? 'Exceeds balance'
          : inputError === ValueInputError.TOO_MANY_DECIMALS
            ? `Maximum ${selectedWalletToken?.decimals} decimal places allowed`
            : inputError === ValueInputError.NOT_ENOUGH_NATIVE_TOKEN
              ? `More ${nativeToken?.symbol} needed to cover transaction fees`
              : inputError === ValueInputError.UNDER_MIN_CARDANO
                ? `Minimum is 1 ${selectedWalletToken?.symbol}`
                : null,
      [inputError, selectedWalletToken, nativeToken],
    );
    const inputErrorRef = useRef<null | string>(null);

    const inputErrorStates = inputError ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 };
    const inputErrorSpring = useSpring(inputErrorStates);

    const feeStates = inputError ? { opacity: 0, y: 10 } : { opacity: 1, y: 0 };
    const feeSpring = useSpring(feeStates);

    const ref = useRef(null);

    useEffect(() => {
      inputErrorRef.current = getInputErrorText();
    }, [inputError, getInputErrorText]);

    useOnClickOutside(ref, (a) => {
      // @ts-expect-error 2339
      const clickedText = a.target?.innerText as string;
      // ignore these, means user clicked header
      if (
        (clickedText && clickedText.startsWith('$')) ||
        (clickedText && clickedText.startsWith('BRIDGE COST')) ||
        (clickedText && clickedText.startsWith('APPROVAL COST'))
      )
        return;

      setMenuOpen(false);
    });

    const { txCost, txCostInFiat, bridgeFeeInFiat, totalAmountInFiat, totalAmountInFiatMinusTransferred } =
      useMemo(() => {
        const txCost = +amount === 0 ? '0' : transactionCost ?? '0';
        const bridgeFee =
          bridgingBack && !bridgeBackFee
            ? '0'
            : formatUnits(bridgeBackFee || '0', selectedWalletToken?.decimals ?? 18).toString() ?? '0';
        const tokenAmount = bridgingNft ? '0' : amount;

        const txCostInFiat = +txCost * +(nativeToken?.price ?? 0);
        const bridgeFeeInFiat = bridgingNft
          ? +bridgeFee * +(nativeToken?.price ?? 0)
          : +bridgeFee * +(selectedWalletToken?.price ?? 0);
        const tokenAmountInFiat = +tokenAmount * +(selectedWalletToken?.price ?? 0);

        const totalAmountInFiat = txCostInFiat + (needsApproval ? 0 : bridgeFeeInFiat) + tokenAmountInFiat;
        const totalAmountInFiatMinusTransferred = txCostInFiat + (needsApproval ? 0 : bridgeFeeInFiat);

        return {
          txCost,
          bridgeFee,
          tokenAmount,
          txCostInFiat,
          bridgeFeeInFiat,
          tokenAmountInFiat,
          totalAmountInFiat,
          totalAmountInFiatMinusTransferred,
        };
      }, [
        amount,
        bridgeBackFee,
        bridgingBack,
        bridgingNft,
        nativeToken?.price,
        needsApproval,
        transactionCost,
        selectedWalletToken,
      ]);

    return (
      <div className={cn('absolute mt-4 w-full', noAbsolutePositioning && 'relative')}>
        <div
          className={cn(
            'absolute flex w-full font-semibold text-[11px] text-meldwhite/50',
            noAbsolutePositioning && 'relative',
          )}
        >
          <animated.div style={feeSpring} className={cn('relative flex w-full items-center gap-2 whitespace-nowrap')}>
            {/* Menu */}
            <div
              ref={ref}
              className={cn(
                'pointer-events-none absolute -right-[14px] -top-[22px] z-10 rounded-lg bg-meldred py-3 pl-5 pr-[20px] opacity-0 shadow-[0px_0px_4px_0px_rgba(0,0,0,0.40)] transition-all',
                menuOpen && 'pointer-events-auto opacity-100',
                !bridgingNft && 'min-w-[calc(100%+50px)]',
                isMobile() && 'min-w-[calc(100%-12px)]',
              )}
            >
              <div className="mt-8 flex flex-col gap-3">
                {(txCost !== '' || isLoadingTransactionFee) && (
                  <div className="flex flex-row items-center justify-between">
                    <div className="mr-6 flex flex-row items-center  gap-[5px] pl-1">
                      <div className="relative -left-[1px]">
                        <TxFeeIcon />
                      </div>
                      <div>TRANSACTION FEE</div>
                      <Tooltip
                        content={
                          'MELD does not earn any profits from bridging assets to the MELD network. You only pay the transaction fee on the source chain, with no additional fees on the MELD blockchain.'
                        }
                        arrowPlacement="top"
                        withArrow
                        maxWidth={227}
                      />
                    </div>
                    <div className="flex flex-row items-center gap-[5px]">
                      <span className="text-meldwhite">
                        {formatCryptoBalance(txCost, nativeToken?.symbol ?? '', true)}
                      </span>

                      <span>| {formatCurrency(txCostInFiat)}</span>
                    </div>
                  </div>
                )}
                {!needsApproval && (
                  <div className="flex flex-row items-center justify-between">
                    <div className="mr-6 flex  flex-row  items-center gap-[5px]">
                      <BridgeIcon />
                      <div>BRIDGE FEE</div>
                      <Tooltip
                        content={
                          'This fee is dynamically calculated based on the conditions of the destination network. MELD does not make any money from this fee, and is purely used to cover the cost to send you the transaction on the destination network securely.'
                        }
                        arrowPlacement="top"
                        withArrow
                        maxWidth={227}
                      />
                    </div>

                    <div className="flex flex-row items-center gap-[5px]">
                      <span className={cn('text-meldwhite', notEnoughToken && 'text-meldblack')}>
                        {!bridgingBack
                          ? 'FREE'
                          : `${formatCryptoBalance(
                              formatUnits(bridgeBackFee || '0', selectedWalletToken?.decimals ?? 18),
                              selectedWalletToken?.symbol ?? nativeToken?.symbol ?? '',
                              true,
                            )}`}
                      </span>
                      {bridgingBack && <span>| {formatCurrency(bridgeFeeInFiat)}</span>}
                    </div>
                  </div>
                )}
                {!needsApproval && (
                  <div className="flex flex-row items-center justify-between">
                    <div className="mr-6 flex  flex-row items-center gap-[5px]">
                      <ReceiptIcon />
                      <div>TOTAL </div>
                    </div>
                    <div className="flex flex-row items-center gap-[5px]">
                      <span className={notEnoughToken ? 'text-meldblack' : 'text-meldwhite'}>
                        {needsApproval || !bridgingBack || (+amount === 0 && (bridgeBackFee === '0' || !bridgeBackFee))
                          ? `${bridgingNft ? `${amount} NFT(s)` : `${formatCryptoBalance(amount, selectedWalletToken?.symbol ?? '', true)}`}${txCost === '' && !isLoadingTransactionFee ? '' : ` + ${formatCryptoBalance(txCost, nativeToken?.symbol ?? '', true)}`}`
                          : `${bridgingNft ? `${amount} NFT(s)` : `${formatCryptoBalance(amount, selectedWalletToken?.symbol ?? '', true)}`}${txCost === '' && !isLoadingTransactionFee ? '' : ` + ${formatCryptoBalance(txCost, nativeToken?.symbol ?? '', true)}`} + ${formatCryptoBalance(
                              formatUnits(
                                bridgeBackFee || '0',
                                selectedWalletToken?.decimals ?? nativeToken?.decimals ?? 18,
                              ),
                              selectedWalletToken?.symbol ?? nativeToken?.symbol ?? '',
                              true,
                            )}`}
                      </span>
                      {'|'} {formatCurrency(totalAmountInFiat)}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* Header */}
            <div
              onClick={() => setMenuOpen((open) => !open)}
              className={cn(
                'flex-end absolute right-0 z-10 flex cursor-pointer select-none items-center justify-end gap-1',
                notEnoughToken && 'text-meldblack',
              )}
            >
              {needsApproval ? 'APPROVAL COST' : 'BRIDGE COST'}
              <span
                className={cn(
                  'text-meldwhite',
                  isLoadingTransactionFee && 'animate-pulse',
                  notEnoughToken && 'text-meldblack',
                )}
              >
                {formatCurrency(totalAmountInFiatMinusTransferred)}
              </span>
              <Arrow
                className={cn(
                  'pointer-events-none transition-all',
                  menuOpen && 'rotate-180',
                  notEnoughToken ? 'text-meldblack' : 'text-meldwhite',
                )}
              />
            </div>
          </animated.div>
        </div>
        <animated.div
          style={inputErrorSpring}
          className={cn('absolute -top-2 right-0 font-semibold text-[11px] text-meldwhite/50')}
        >
          {inputErrorRef.current ?? getInputErrorText()}
        </animated.div>
      </div>
    );
  },
);
