import { useMemo } from 'react';

import { useStore } from '@store/store';
import { shallow } from 'zustand/shallow';
import { NetworkChainType } from '@api/meld-app/networks/networks.types';
import { MELD_NETWORK } from 'src/contants/meld';
import { useSelectedNfts } from './use-selected-nfts';

export const useUnsupportedExtNetwork = () => {
  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);
  const networks = useStore((state) => state.networks, shallow);
  const chainId = useStore((state) => state.evmData.evmConnectedChainId);
  const wrongCardanoNetwork = useStore((state) => state.cardanoData.cardanoWrongNetwork);

  const { nftBridgeSelected, selectedNftObjects } = useSelectedNfts();

  const { wrongEvmNetwork, correctChainId } = useMemo(() => {
    const correctChainId = nftBridgeSelected
      ? networks?.[MELD_NETWORK]?.chainId
      : networks?.[selectedWalletToken?.network ?? MELD_NETWORK]?.chainId;

    return {
      wrongEvmNetwork:
        (nftBridgeSelected &&
          selectedNftObjects[0]?.chainType === NetworkChainType.EVM &&
          Boolean(chainId && chainId !== correctChainId)) ||
        (!nftBridgeSelected &&
          selectedWalletToken &&
          selectedWalletToken.chainType === NetworkChainType.EVM &&
          Boolean(chainId && chainId !== correctChainId)),
      correctChainId,
    };
  }, [selectedWalletToken, chainId, networks, nftBridgeSelected, selectedNftObjects]);

  return {
    wrongEvmNetwork,
    wrongCardanoNetwork,
    correctChainId,
  };
};
