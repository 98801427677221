import { NetworkChainType } from '@api/meld-app/networks/networks.types';
import * as Emurgo from '@emurgo/cardano-serialization-lib-browser';
import { isNotProdEnv } from './environments-helper';
import { ethers } from 'ethers';

const isValidCardanoAddress = (address: string) => {
  try {
    const addr = Emurgo.Address.from_bech32(address);
    const result = Boolean(addr.to_bytes());
    // this is here because this functions returns an address is valid regardless of the network we're connected to so here we check if we're dealing with a valid testnet address while in the main network
    if (result && !isNotProdEnv && address.startsWith('addr_test')) {
      return false;
    }

    return result;
  } catch (e) {
    return false;
  }
};

export const isValidAddress = (address: string, chainType: NetworkChainType) =>
  chainType === NetworkChainType.CARDANO ? isValidCardanoAddress(address) : ethers.utils.isAddress(address);
