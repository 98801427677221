import Menu from '@mui/material/Menu';
import { shortenAddress } from '../../utils/shorten-address';
import copyIcon from '../../assets/copy.svg';
import shareIcon from '../../assets/share.svg';
import disconnectIcon from '../../assets/disconnect.svg';
import closeIcon from '../../assets/close.svg';
import arrowIcon from '../../assets/arrow.svg';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Button } from '@mui/material';
import { cn } from '@utils/cn';
import { TokenIcon } from '@components/token-icon';
import { useIsMobile } from '@hooks/use-is-mobile';
import { isBridgePending } from '@utils/is-bridge-pending';

export type WalletMenuProps = {
  open: HTMLElement | null;
  handleClose: () => void;
  address?: string;
  onDisconnect: () => void;
  isConnected: boolean;
  onConnectCardano?: () => void;
  onConnectEvm?: () => void;
  data: Array<{
    fromImgSrc: string;
    toImgSrc: string;
    formattedAmount: string;
    date: string;
    explorerUrl: string;
    status: 'Submitted' | 'Confirming' | 'Pending' | 'Executed' | 'Completed' | 'Finalized' | 'Reverted';
  }>;
  walletExplorerUrl: string;
  onBridgeClicked: () => void;
  bridgeButtonText: string;
  isPaperAddress: boolean;
  paperAddressConnected: boolean;
};

export const WalletMenu = ({
  open,
  handleClose,
  address,
  onDisconnect,
  onConnectCardano,
  onConnectEvm,
  data,
  walletExplorerUrl,
  onBridgeClicked,
  bridgeButtonText,
  isPaperAddress,
  paperAddressConnected,
}: WalletMenuProps) => {
  const copyToClipboard = useCallback(() => navigator.clipboard.writeText(address as string), [address]);
  const isMobile = useIsMobile();
  const addressRef = useRef(address);
  const isPaperAddressRef = useRef(isPaperAddress);

  useEffect(() => {
    if (address) addressRef.current = address;
  }, [address]);

  useEffect(() => {
    if (isPaperAddress) isPaperAddressRef.current = isPaperAddress;
  }, [isPaperAddress]);

  const internalIsPaperAddress = isPaperAddress || isPaperAddressRef.current;

  const content = useMemo(() => {
    return (
      <div>
        <div className={cn('pt-2', isMobile && 'pt-4', data.length === 0 && 'pr-0')}>
          {/* Header */}
          <div className={cn('flex justify-between pl-5', data.length && 'pr-0')}>
            <p className={cn('font-semibold text-[18px] text-meldwhite')}>
              {address || addressRef.current ? shortenAddress(address ?? (addressRef.current as string), 4, 5) : ''}
            </p>
            <div className="flex items-center gap-5 pr-5 [&>img]:cursor-pointer">
              <img onClick={copyToClipboard} src={copyIcon} className="hover:opacity-60" />
              <img
                onClick={() => window.open(walletExplorerUrl, '_blank')}
                src={shareIcon}
                className="hover:opacity-60"
              />
              {!isPaperAddress && (
                <img
                  onClick={() => {
                    handleClose();
                    onDisconnect();
                  }}
                  src={disconnectIcon}
                  className="mr-2 hover:opacity-60"
                />
              )}
              <img
                onClick={handleClose}
                src={closeIcon}
                className={cn('relative top-[-3px] hover:opacity-60', isMobile && 'top-0')}
              />
            </div>
          </div>
          {!internalIsPaperAddress && (
            <div>
              <p className="mb-2 pl-5 font-semibold text-[11px] uppercase tracking-[0.44px] text-meldwhite opacity-50">
                Transactions
              </p>
              <div
                className={cn(
                  'flex h-[calc(100vh-280px)] flex-col overflow-y-auto pb-5 xm:h-[calc(100vh-400px)] md:max-h-[calc(100vh-600px)]',
                  isMobile && '!h-[calc(65vh_-_130px)]',
                  data.length === 0 && 'mr-0 pr-0',
                  isMobile && data.length === 0 && '!h-[calc(40v1_-_130px)]',
                )}
              >
                {data.length ? (
                  data.map(({ fromImgSrc, toImgSrc, formattedAmount, date, explorerUrl, status }) => {
                    const isPending = isBridgePending({ status });
                    return (
                      <a
                        target="_blank"
                        href={explorerUrl}
                        key={date}
                        className={cn(
                          'group flex justify-between py-[6px] pr-5 text-meldwhite no-underline',
                          isPending && 'bg-meldblack/10',
                        )}
                      >
                        <div className={cn('flex items-center pl-5')}>
                          <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full drop-shadow-lg">
                            <TokenIcon imgSrc={fromImgSrc} />
                          </div>
                          <img src={arrowIcon} className="mx-[-5px]" />
                          <div className="z-10 flex h-[30px] w-[30px] items-center justify-center rounded-full drop-shadow-lg">
                            <TokenIcon imgSrc={toImgSrc} />
                          </div>
                          <img
                            src={shareIcon}
                            className="relative -left-4 z-0 ml-2 h-4 w-4 opacity-0 transition-all duration-500 group-hover:left-0 group-hover:opacity-100"
                          />
                        </div>
                        <div className="font-semibold">
                          <div className="text-right text-xl">{formattedAmount}</div>
                          {!isPending ? (
                            <div className="text-right text-[11px]">{dayjs(date).format('MMM D, YYYY hh:mm')}</div>
                          ) : (
                            <div className="relative -top-[4px] animate-pulse font-semibold text-[11px] uppercase tracking-[0.44px] text-meldblack">
                              bridging in progress
                            </div>
                          )}
                        </div>
                      </a>
                    );
                  })
                ) : (
                  <p className="mb-[48px] mt-[35px] text-center font-semibold text-[11px] uppercase tracking-[0.44px] text-meldwhite">
                    Your transactions will appear here
                  </p>
                )}
              </div>
            </div>
          )}
        </div>

        {!isMobile && (
          <>
            <div className="absolute z-10  h-5 w-full shadow-[0px_4px_40px_0px_rgba(0,0,0,0.40)]" />
            <div className="relative z-20 bg-meldred px-5">
              <Button
                onClick={() => {
                  if (isPaperAddress) {
                    handleClose();
                    onDisconnect();
                  } else {
                    onBridgeClicked();
                  }
                }}
                variant="outlined"
                className={cn(
                  'mt-6 h-[59px] w-full rounded-lg border border-meldwhite font-semibold uppercase text-meldwhite transition-all hover:bg-white/10',
                  ((!onConnectCardano && !onConnectEvm) ||
                    isMobile ||
                    internalIsPaperAddress ||
                    paperAddressConnected) &&
                    'mb-6',
                )}
              >
                {internalIsPaperAddress ? 'CLEAR EXTERNAL ADDRESS' : bridgeButtonText}
              </Button>
              {((!isMobile && onConnectCardano) || onConnectEvm) &&
                !internalIsPaperAddress &&
                !paperAddressConnected && (
                  <Button
                    onClick={onConnectCardano ?? onConnectEvm}
                    variant="outlined"
                    className={
                      'mb-6 mt-3 h-[59px] w-full rounded-lg border border-meldwhite font-semibold uppercase text-meldwhite transition-all hover:bg-white/10'
                    }
                  >
                    {onConnectCardano && !isMobile ? 'cardano wallet' : 'walletconnect'}
                  </Button>
                )}
            </div>
          </>
        )}
      </div>
    );
  }, [
    address,
    copyToClipboard,
    data,
    handleClose,
    isMobile,
    onConnectCardano,
    onConnectEvm,
    onDisconnect,
    walletExplorerUrl,
    onBridgeClicked,
    bridgeButtonText,
    isPaperAddress,
    internalIsPaperAddress,
    paperAddressConnected,
  ]);

  return (
    <>
      <div>
        <div
          onClick={handleClose}
          className={cn(
            'pointer-events-none fixed left-0 top-0 z-10 h-screen w-screen overflow-hidden bg-meldblack/65 opacity-0 transition-all duration-300',
            open && isMobile && 'pointer-events-auto opacity-100',
            !open && 'pointer-events-none opacity-0',
          )}
        />
        <div
          className={cn(
            'pointer-events-none fixed bottom-0 left-0 z-20 h-[65vh] w-screen rounded-t-[27px] bg-meldred opacity-0',
            open && isMobile && 'bottomMenu pointer-events-auto opacity-100',
            isMobile && data.length === 0 && '!h-[40vh]',
          )}
        >
          {content}
        </div>
      </div>
      <div>
        <Menu
          slotProps={{
            root: {
              className: cn(isMobile && 'hidden pointer-events-none -z-10'),
            },
            paper: {
              className: cn(
                'fixed max-h-screen w-screen !max-w-none !right-0 !top-[24px] md:!right-[50px] md:!top-[50px] !left-auto md:w-[369px] bg-meldred text-meldwhite rounded-lg rounded-tr-none',
              ),
            },
          }}
          MenuListProps={{ className: 'p-0' }}
          open={!!open}
          anchorEl={open}
          onClose={handleClose}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {content}
        </Menu>
      </div>
    </>
  );
};
