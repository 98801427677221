import { postWalletRegistrationQuery } from '@api/meld-app/wallet-registration/wallet-registration-query';
import { getExtCardanoWalletTokensQuery } from '@api/meld-app/wallet-tokens/wallet-tokens-query';
import { SupportedWalletBE, WalletToken } from '@typings/wallet-asset.types';
import { CARDANO_NETWORK } from 'src/contants/cardano';
import { MELD_NETWORK } from 'src/contants/meld';
import { formatUnits } from 'viem';
import evmContractUtils from './evm/evmContractUtils';
import { BridgeContracts } from '@typings/api';
import { BridgeData } from '@store/app-slice';
import MeldBridgeReceiverABI from '../abi/MeldBridgeFarmingManager.json';
import { networks } from './networks/networks';
import evmProviderUtils from './evm/evmProviderUtils';

export const checkHasLiquidity = async ({
  selectedWalletToken,
  bridgeContracts,
  destinationNetwork,
  amount,
  setBridgeData,
}: {
  selectedWalletToken: WalletToken;
  bridgeContracts: BridgeContracts;
  destinationNetwork: string;
  amount: string;
  setBridgeData: (newBridgeData: Partial<BridgeData>) => void;
}) => {
  try {
    if (selectedWalletToken?.network === MELD_NETWORK) {
      const farmingManager = bridgeContracts?.receiver[0].farmingManager;
      // destination asset address
      const assetAddress = selectedWalletToken?.isNative
        ? bridgeContracts.receiver.find((a) => a.network === destinationNetwork)?.wETHAddress
        : bridgeContracts.supportedTokens.find(
            (a) => a.network === destinationNetwork && a.token === selectedWalletToken.contract,
          )?.remoteToken;

      if (!assetAddress) return true;

      // simple provider connected to destination network to check liquidity
      const rpcUrl = networks[destinationNetwork]?.publicRpcUrls[0];
      const provider = evmProviderUtils.connectToRPC(rpcUrl);
      // check liquidity on cardano
      if (destinationNetwork === CARDANO_NETWORK) {
        const lockerAddress = bridgeContracts.cardano.stakers[0].lockerAddress;
        // TODO - this may not be needed, need a reply from BE.
        await postWalletRegistrationQuery(lockerAddress, SupportedWalletBE.CARDANO);
        const cardanoTokens = await getExtCardanoWalletTokensQuery(lockerAddress);
        const tokenToCheck = cardanoTokens.find((a) =>
          selectedWalletToken.tokenId.includes('lovelace') ? a.isNative === true : a.contract === assetAddress,
        );
        if (Number(tokenToCheck?.amount ?? 0) < Number(amount)) {
          setBridgeData({
            liquidityData: { amount: tokenToCheck?.amount ?? '', destinationNetwork: destinationNetwork },
          });
          return false;
        }
        console.log('availableLiquidity:', tokenToCheck?.amount);
      }
      // check liquidity on EVM
      else {
        const farmingManagerContract = await evmContractUtils.connect(
          provider,
          farmingManager ?? '',
          MeldBridgeReceiverABI,
        );
        // get asset liquidity
        const data = await farmingManagerContract.getYieldAssetConfig(assetAddress ?? '');
        const availableLiquidity = Number(
          formatUnits(data.totalAvailableLiquidity.toString(), selectedWalletToken?.decimals ?? 18),
        );
        if (availableLiquidity < Number(amount)) {
          setBridgeData({ liquidityData: { amount: availableLiquidity.toString(), destinationNetwork } });
          return false;
        }
        console.log('availableLiquidity:', availableLiquidity);
      }
    }
  } catch (err) {
    console.log('failed to check liquidity', err);
  }
  return true;
};
