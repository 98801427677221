import { FormControl, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { BOX_SHADOW } from '../../contants/style';
import { cn } from '../../utils/cn';
import { TokenIcon } from '../token-icon';
import { useStore } from '@store/store';
import { shallow } from 'zustand/shallow';
import { getTokenIcon } from '@utils/assets-helper';
import { formatCryptoBalance } from '@utils/format-currency';
import { useWalletTokens } from '@hooks/use-wallet-tokens';
import { WalletToken } from '@typings/wallet-asset.types';
import { useEffect, useMemo, useState } from 'react';
import { MAIN_EVM_NETWORK } from 'src/contants/app';
import { CARDANO_NETWORK } from 'src/contants/cardano';

export const Selector = () => {
  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);
  const bridgeFailed = useStore((state) => state.bridgeData.bridgeFailed);
  const [menuOpen, setMenuOpen] = useState(false);

  const setInputData = useStore((state) => state.setInputData);
  const setSelectedWalletToken = useStore((state) => state.setSelectedWalletToken);
  const updateSelectedWalletToken = useStore((state) => state.updateSelectedWalletToken);

  const userTokens = useStore((state) => state.userTokens, shallow);

  const { isLoadingEvm, isRefetchingEvm } = useWalletTokens();

  const handleChange = (event: SelectChangeEvent<WalletToken>) => {
    setInputData({ amount: '', inputError: null });
    setSelectedWalletToken(event.target.value as WalletToken);
  };

  // defaults to "main" evm token if no token is selected
  const selectedToken = useMemo(
    () =>
      selectedWalletToken
        ? selectedWalletToken
        : userTokens.find((a) => a.isNative && a.network === MAIN_EVM_NETWORK) ??
          userTokens.find((a) => a.isNative && a.network === CARDANO_NETWORK),
    [selectedWalletToken, userTokens],
  );

  if (!selectedWalletToken && selectedToken) {
    setSelectedWalletToken(selectedToken);
  }

  useEffect(() => {
    const closeMenu = () => setMenuOpen(false);

    window.addEventListener('scroll', closeMenu);

    return () => window.removeEventListener('scroll', closeMenu);
  }, []);

  /**
   * we initially preselect a token without yet having balances so when we eventually
   * have the balances we must set the token again so the balance is reflected in the UI
   *
   * @remarks update token instead of setting it, setting token has other side-effects
   */
  useEffect(() => {
    const newToken = userTokens.find((a) => a.tokenId === selectedToken?.tokenId);
    if (newToken && newToken.amount !== selectedToken?.amount) {
      updateSelectedWalletToken(newToken);
    }
  }, [selectedToken, userTokens, updateSelectedWalletToken]);

  /**
   * sorts by amount and filters native tokens we're not meant to show
   * they only exist in the dataset so we can calculate transaction fees and determine
   * whether the user can afford the transaction
   */
  const sortedTokens = useMemo(
    () => userTokens.sort((a, b) => +b.amount - +a.amount).filter((a) => !a.hide),
    [userTokens],
  );

  /**
   * Lists the tokens for user selection
   */
  const drawTokenList = useMemo(() => {
    return sortedTokens.map((token, index) => {
      const { icon, amount, symbol, network } = token;
      return (
        // @ts-expect-error 2769
        <MenuItem
          key={index}
          value={token}
          className={cn('h-[59px] hover:!bg-[rgba(235,0,70,0.15)]', +amount === 0 && 'opacity-50')}
          sx={{
            '&.Mui-selected': {
              background: 'rgba(235,0,70,0.05) !important',
            },
          }}
        >
          <div className="flex h-[30px] w-full items-center justify-between pr-3">
            <div className="flex items-center justify-center">
              <div className="relative">
                <TokenIcon boxShadow={false} imgSrc={icon} />
                <div
                  className={cn(
                    'absolute right-[-4px] top-[19px] h-[15px] w-[15px] rounded-full [&_img]:absolute [&_img]:h-full [&_img]:w-full',
                    BOX_SHADOW,
                  )}
                >
                  {getTokenIcon(network, true)}{' '}
                </div>
              </div>
              <div className="ml-[15px] flex items-center font-semibold text-xl tracking-[0.72px] text-meldred">
                {symbol}
                <span className="relative top-[2px] ml-[5px] font-semibold text-[11px] uppercase tracking-[0-44px] text-[rgba(228,6,84,0.50)]">
                  | {network}
                </span>
              </div>
            </div>
            <div className="ml-6 font-semibold text-xl tracking-[0.72px] text-meldred">
              {formatCryptoBalance(amount)}
            </div>
          </div>
        </MenuItem>
      );
    });
  }, [sortedTokens]);

  return (
    <FormControl className="w-full md:w-[194px]">
      <Select
        open={menuOpen}
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
        className={cn(
          'rounded-lg border-0 bg-meldwhite outline-none [&_.Mui-selected]:bg-[rgba(235,0,70,0.15)]',
          isLoadingEvm || isRefetchingEvm ? 'animate-pulse' : undefined,
          bridgeFailed && 'bg-meldwhite/50',
        )}
        slotProps={{ root: '!w-[194px]' }}
        MenuProps={{
          classes: { paper: 'w-[85vw] md:w-[471px] max-h-[415px] rounded-lg' },
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          MenuListProps: {
            className: 'p-0',
          },
        }}
        value={selectedToken ?? ''}
        onChange={handleChange}
        renderValue={() => (
          <div className="flex h-full items-center pl-[16px]">
            <TokenIcon boxShadow={true} imgSrc={selectedToken?.icon ?? ''} />
            <div className="ml-[11px] font-semibold text-xl tracking-[0.72px] text-meldred">
              {selectedToken?.symbol}
            </div>
          </div>
        )}
        disableUnderline
        variant="standard"
        inputProps={{
          className: 'h-[59px] p-0',
          classes: {
            icon: 'text-meldred text-[28px]',
          },
        }}
      >
        {drawTokenList}
      </Select>
    </FormControl>
  );
};
