import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { MUI_THEME } from './theme';
import { Layout } from './components/layout';
import { TokenSelector } from './components/token-selector';
import { Bridge } from './components/bridge';
import { Details } from './components/details';
import { cn } from './utils/cn';
import { ExtCardanoWallets } from './components/with-cardano-external-wallets';
import { useInitialBEData } from '@hooks/use-initial-be-data/use-initial-be-data';
import { useWalletsRegisterAndSynced } from '@hooks/use-wallets-register-and-synced';
import { useWalletTokens } from '@hooks/use-wallet-tokens';
import { useStore } from '@store/store';
import { useUserBridges } from '@hooks/use-user-bridges';
import { shallow } from 'zustand/shallow';
import { useUnsupportedExtNetwork } from '@hooks/use-unsupported-ext-network';
import { UnsupportedWalletMessage } from '@components/unsupported-wallet-message';
import { useNFTS } from '@hooks/use-NFTS';
import { useOverrideWcColors } from '@hooks/use-override-wc-colors';
import { BridgeButton } from '@components/bridge-button';
import { ToggleBridgeTypeButton } from '@components/toggle-bridge-type-button';
import { useEffect, useRef, useState } from 'react';
import { formatUnits } from 'ethers/lib/utils';
import { getDecimalSeparator } from '@utils/format-currency/get-decimals-separator';
import getUserLocale from 'get-user-locale';
import { useSelectedNfts } from '@hooks/use-selected-nfts';
import { WCPopupAddon } from '@components/wc-popup-addon';

/*
function askNotificationPermission() {
  // Check if the browser supports notifications
  if (!('Notification' in window)) {
    console.log('This browser does not support notifications.');
    return;
  }
  Notification.requestPermission().then(() => {});
}

askNotificationPermission();
*/

function App() {
  const { amount } = useStore((state) => state.inputData, shallow);
  const { evmWalletName } = useStore((state) => state.evmData, shallow);
  const { cardanoWalletName } = useStore((state) => state.cardanoData, shallow);
  const { startedAt, completedStep, explorerUrl, bridgeFailed, bridgeBackMaxFees, notEnoughToken, liquidityData } =
    useStore((state) => state.bridgeData, shallow);
  const detailsKey = useStore((state) => state.detailsKey);
  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);
  const setNftBridgeSelected = useStore((state) => state.setNftBridgeSelected);

  const { wrongEvmNetwork, wrongCardanoNetwork, correctChainId } = useUnsupportedExtNetwork();
  const detailsComponentRef = useRef<HTMLDivElement>(null);
  const [offsetOfDetailsComponent, setoffsetOfDetailsComponent] = useState(0);
  const { thousandsSeparator, decimalSeparator } = useStore((state) => state.numberFormatting, shallow);
  const setNumberFormatting = useStore((state) => state.setNumberFormatting);
  const { nftBridgeSelected, selectedNftObjects } = useSelectedNfts();
  useEffect(() => {
    const decimalSeparator = getDecimalSeparator(getUserLocale());
    setNumberFormatting({ decimalSeparator, thousandsSeparator: decimalSeparator === '.' ? ',' : '.' });
  }, [setNumberFormatting]);

  useOverrideWcColors();
  useInitialBEData();
  useWalletsRegisterAndSynced();
  useWalletTokens();
  useUserBridges();
  useNFTS();

  useEffect(() => {
    if (detailsComponentRef.current) {
      const { y, height } = detailsComponentRef.current.getBoundingClientRect();
      setoffsetOfDetailsComponent(y + height);
    }
  }, []);

  useEffect(() => {
    const setRegularBridge = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 768) setNftBridgeSelected(false);
    };

    window.addEventListener('resize', setRegularBridge);

    return () => window.removeEventListener('resize', setRegularBridge);
  }, [setNftBridgeSelected]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={MUI_THEME}>
        <ExtCardanoWallets />
        <Layout>
          <TokenSelector
            key={thousandsSeparator}
            thousandsSeparator={thousandsSeparator}
            decimalSeparator={decimalSeparator}
          />
          <Bridge />
          <div className={cn('w-full md:max-w-[451px]')}>
            <div className="mt-[90px]">
              <UnsupportedWalletMessage
                liquidityData={liquidityData}
                enteredAmount={+amount > 0 || !!selectedNftObjects.length}
                nftBridgeSelected={nftBridgeSelected}
                correctChainId={correctChainId}
                extCardanoWalletName={cardanoWalletName ?? undefined}
                extEvmWalletName={evmWalletName}
                selectedWalletToken={selectedWalletToken}
                wrongEvmNetwork={wrongEvmNetwork}
                wrongCardanoNetwork={wrongCardanoNetwork}
                maxBridgeAmount={
                  +amount > 0 && selectedWalletToken?.toBridgeBack && notEnoughToken
                    ? bridgeBackMaxFees[selectedWalletToken?.tokenId ?? ''].data?.amount
                    : undefined
                }
                cantBridge={
                  nftBridgeSelected
                    ? notEnoughToken && !!selectedNftObjects.length
                    : +amount > 0 && selectedWalletToken?.toBridgeBack && notEnoughToken
                      ? +(selectedWalletToken.amount ?? 0) <
                        +formatUnits(
                          (
                            bridgeBackMaxFees[selectedWalletToken?.tokenId ?? ''].data?.feeInfo?.feeAmount ?? 0
                          ).toString(),
                          selectedWalletToken?.decimals ?? 18,
                        )
                      : false
                }
              />
              <BridgeButton />
            </div>
          </div>
          <Details
            componentRef={detailsComponentRef}
            startedAt={startedAt}
            completedStep={completedStep}
            explorerUrl={explorerUrl}
            bridged={completedStep === 3}
            key={detailsKey}
            bridgeFailed={bridgeFailed}
          />
          <ToggleBridgeTypeButton offsetOfDetailsComponent={offsetOfDetailsComponent} />
          <WCPopupAddon />
        </Layout>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
