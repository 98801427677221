import { AvailableToken } from '@api/meld-app/available-tokens/available-tokens.types';
import { WalletMenuProps } from '@components/user-menu/wallet-menu';
import { Bridge, UserBridge } from '@typings/api';
import { getNetworkIcon } from './assets-helper';
import { formatCryptoBalance } from './format-currency';
import { formatUnits } from 'ethers/lib/utils';
import { CARDANO_NETWORK } from 'src/contants/cardano';
import { convertHashToLink, convertEVMHashToLink } from './transaction-link.util';
import { isBridgePending } from './is-bridge-pending';
import { fetchBridgeInfo } from './fetch-bridge-info';
import { queryClient } from '@api/query-client';

export const parseUserBridgeTransaction = (userBridge: UserBridge, availableTokens: Array<AvailableToken>) => {
  const token = userBridge.cardanoNfts
    ? undefined
    : availableTokens?.find(
        (token) =>
          // cardano token
          (userBridge.originalNetwork.network === CARDANO_NETWORK &&
            `${userBridge.originalNetwork.token}${userBridge.originalNetwork.tokenName}` === token.contract) ||
          token.contract === userBridge.originalNetwork.token ||
          // native token
          (userBridge.originalNetwork.token === '0x0000000000000000000000000000000000000000' &&
            userBridge.originalNetwork.network === token.network &&
            token.isNative),
      );

  const isPending = isBridgePending(userBridge);

  // fetch status so we can update the tx in the wallet menu once completed
  if (isPending)
    fetchBridgeInfo({
      hash: userBridge.originalNetwork.transaction ?? '',
      walletAddress: userBridge.originalNetwork.user,
      destinationNetwork: userBridge.destinationNetwork.network,
      updateGlobalState: false,
      onComplete: () => {
        if (userBridge.originalNetwork.network === CARDANO_NETWORK)
          queryClient.invalidateQueries(['GET_USER_BRIDGES_CARDANO']);
        else queryClient.invalidateQueries(['GET_USER_BRIDGES_EVM']);
      },
      amount: userBridge.originalNetwork.amount,
      token: userBridge.originalNetwork.token,
      network: userBridge.originalNetwork.network,
      tokenName: userBridge.originalNetwork.tokenName,
      numberOfNfts: 0,
    });

  return {
    fromImgSrc: getNetworkIcon(userBridge.originalNetwork.network),
    toImgSrc: getNetworkIcon(userBridge.destinationNetwork.network),
    formattedAmount: userBridge.cardanoNfts
      ? `${userBridge.cardanoNfts} NFT${userBridge.cardanoNfts === 1 ? '' : 's'}`
      : // non-NFT bridges on EVM do not have the tokenName prop, so if its there it means 1 NFT was transferred
        userBridge.originalNetwork.network !== CARDANO_NETWORK && userBridge.originalNetwork.tokenName
        ? '1 NFT'
        : formatCryptoBalance(
            formatUnits(userBridge.originalNetwork.amount, token?.decimals ?? 18),
            token?.symbol === 'TestERC20' ? 'Test' : token?.symbol,
            true,
          ),
    date: userBridge.originalNetwork.confirmationTimestamp ?? '',
    // for pending bridges we only have a hash for the original network
    explorerUrl: !userBridge.destinationNetwork.transaction
      ? userBridge.originalNetwork.network === CARDANO_NETWORK
        ? convertHashToLink(userBridge.originalNetwork.transaction as string)
        : convertEVMHashToLink(userBridge.originalNetwork.transaction as string, userBridge.originalNetwork.network)
      : userBridge.destinationNetwork.network === CARDANO_NETWORK
        ? convertHashToLink(userBridge.destinationNetwork.transaction as string)
        : convertEVMHashToLink(
            userBridge.destinationNetwork.transaction as string,
            userBridge.destinationNetwork.network,
          ),
    status: userBridge.status,
  };
};

export const parseUserBridgesToTransaction: (
  bridges: Bridge,
  availableTokens: Array<AvailableToken> | null,
) => WalletMenuProps['data'] = (bridges, availableTokens) => {
  return bridges.map((bridge) => parseUserBridgeTransaction(bridge, availableTokens as Array<AvailableToken>));
};
