import { WalletToken } from '@typings/wallet-asset.types';
import { getBridgeFee } from '@api/bridge/get-fee';
import { useStore } from '@store/store';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { getNetworkId } from '@utils/get-network-id';

export const fetchMaxFeeData = async (walletToken: WalletToken) => {
  const bridgeData = useStore.getState().bridgeData;
  const bridgeContracts = useStore.getState().bridgeContracts;

  const setMaxFeeBridgeData = useStore.getState().setMaxFeeBridgeData;

  if (walletToken.toBridgeBack && +walletToken.amount > 0) {
    const token = bridgeData.bridgeBackMaxFees[walletToken.tokenId];
    if (
      !token ||
      (token && !token.isFetching && (!token?.data || +(token.data?.feeInfo.deadline ?? 0) - Date.now() < 120000))
    ) {
      const newMaxFeeData = { ...bridgeData.bridgeBackMaxFees };
      // set token as fetching
      if (!token) newMaxFeeData[walletToken.tokenId] = { isFetching: true };
      else newMaxFeeData[walletToken.tokenId].isFetching = true;
      setMaxFeeBridgeData(walletToken.tokenId, { ...(token ?? { isFetching: true }) });
      try {
        const originalNetwork = bridgeContracts?.supportedTokens.find((a) => a.token === walletToken.contract)?.network;
        const data = await getBridgeFee({
          network: getNetworkId(originalNetwork as string),
          token: walletToken?.contract ?? '',
          amount: parseUnits(walletToken.amount ?? '0', walletToken.decimals ?? 18).toString(),
        });
        const newAmount = parseUnits(walletToken.amount ?? '0', walletToken.decimals ?? 18).sub(data.feeInfo.feeAmount);

        setMaxFeeBridgeData(walletToken.tokenId, {
          data: {
            ...data,
            amount: newAmount.lt('0') ? '0' : formatUnits(newAmount.toString(), walletToken.decimals).toString(),
          },
          isFetching: false,
        });
      } catch (err) {
        console.log('err fetching max fee: ', err);
        // on error just retry
        fetchMaxFeeData(walletToken);
      }
    }
  }
};
