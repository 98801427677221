import { axiosInstance } from '@api/axios';
import { CardanoNft, EvmNft, Nft, NftAttribute, NftResponse } from '@api/meld-app/nfts/nfts-query.types';
import { SupportedWallet } from '@typings/wallet';

/**
 * Adds wallet property to each NFT in the NFT response
 * @param nft NFT Response from BE
 * @param wallet SupportedWallet
 * @returns NFT Response from BE with wallet property attached
 */
export const addWalletToNfts = <T extends EvmNft | CardanoNft>(
  nft: NftResponse<T>,
  wallet: SupportedWallet,
): NftResponse<T> => ({
  data: (nft.data ?? []).map((a) => ({ ...a, wallet })),
  nextCursor: nft.nextCursor,
});

export const formatNftMedia = <T extends Nft>(nft: T): T => {
  if (nft.metadata) {
    return {
      ...nft,
      metadata: {
        ...nft.metadata,
        image: processNftUri(nft.metadata.image),
        animation_url: processNftUri(nft.metadata.animation_url),
      },
    };
  }
  return nft;
};

// env var has /v1, and the ipfs proxy doesn't use that...
const removeVersionFromUrl = (url: string) => url.replace(/\/v\d+/, '');

// handles different types of nft uris
const processNftUri = (uri: string | undefined) => {
  if (!uri || typeof uri !== 'string') return undefined;

  if (uri.startsWith('ipfs://')) {
    const handle = uri.split('ipfs://')[1];
    return `${removeVersionFromUrl(axiosInstance.defaults.baseURL ?? '')}/proxy/ipfs/${handle}`;
  } else if (uri.startsWith('https://') || uri.startsWith('data:')) {
    return uri;
  }
  // Handle base64 GIF images
  if (Array.isArray(uri)) {
    const base64Gif = uri.find((img) => typeof img === 'string' && img.startsWith('data:image/gif;base64,'));
    if (base64Gif) {
      return uri.join('');
    }
  }
  return uri;
};

export const getNftName = (nft: Nft | undefined) => {
  return nft?.metadata?.name?.split('#')[0].trim() ?? '';
};

// easy way to get the ID for an nft
export const getNftNumber = (nft: Nft | undefined) => {
  const parts = nft?.nftId.split('-');
  if (!parts || parts.length < 3) {
    return nft?.nftId ?? '';
  }
  return parts[2];
};

export const isEvmNft = (nft: Nft): nft is EvmNft => {
  return 'tokenId' in nft && 'tokenURI' in nft;
};

export const isCardanoNft = (nft: Nft): nft is CardanoNft => {
  return 'policyId' in nft && 'assetName' in nft && 'fingerprint' in nft;
};

type RawMetadataValues = {
  attributes: Record<string, string>;
  files: Array<{ mediaType: string; src: string }>;
  name: string;
  image?: string;
  animation_url?: string;
};

// this code works to extract the image of NFTs from these collections:
//https://cexplorer.io/policy/3b07e0f2a262fa1436df3c91e420d57ad9fd46aa04377ec80a05ee3f
//https://cexplorer.io/policy/b06729158210bf1ba13f8f3d7d422a918d3eaa82561a705552a2568b

// attributes will only be displayed for the second collection, the first collection doesn't have any
export const extractDataFromJson = (json: string | null) => {
  if (!json) return null;
  const { files, image, animation_url, attributes, name }: RawMetadataValues = JSON.parse(json);

  const imageFileSrc = files?.find(
    (file) => file.src && ['image/png', 'image/jpg', 'image/jpeg'].includes(file.mediaType),
  )?.src;

  let finalImage = imageFileSrc || image;

  // Fallback to use regex to find ifps uri in the whole json
  if (!finalImage) {
    const ipfsPattern = /ipfs:\/\/([a-zA-Z0-9]+)/;
    const regexResult = json.match(ipfsPattern);
    if (regexResult) {
      const ipfsHash = regexResult[0];
      finalImage = `${axiosInstance.defaults.baseURL}/proxy/ipfs/${ipfsHash}`;
    }
  }

  const nftAttributes: NftAttribute[] = Object.entries(attributes || {}).map(([trait_type, value]) => ({
    trait_type,
    value,
  }));

  return {
    image: finalImage,
    animation_url,
    attributes: nftAttributes,
    name,
  };
};

// extracts data if BE wasn't able to parse metadata object
export const checkAndExtractRawMetadata = <T extends Nft>(item: T, isEvmNft = false): T => {
  if ((!item.metadata || !item.metadata.image) && item.rawMetadata !== '' && isEvmNft) {
    try {
      const { image, attributes, name, animation_url } =
        extractDataFromJson(isEvmNft ? (item as EvmNft).tokenURI : item.rawMetadata) ?? {};

      return {
        ...item,
        metadata: {
          name,
          attributes,
          image,
          animation_url,
        },
      };
    } catch (err) {
      console.error(err);
    }
  }
  return item;
};
