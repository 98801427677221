import { cn } from '@utils/cn';
import arrowIcon from '../assets/right-arrow.svg';
import wrongIcon from '../assets/wrong.svg';
import successIcon from '../assets/success.svg';

import { Button } from '@mui/material';
import { RefObject, useEffect, useState } from 'react';
import { isValidAddress } from '@utils/is-valid-address';
import { NetworkChainType } from '@api/meld-app/networks/networks.types';
import { useStore } from '@store/store';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { shortenAddress } from '@utils/shorten-address';
import { capture } from '@utils/metrics';
import { MetricEvents } from '@typings/metric-events';

type Props = {
  inputRef?: RefObject<HTMLInputElement>;
  chainType?: NetworkChainType;
};

export const ConnectPaperWallet = ({ inputRef, chainType = NetworkChainType.EVM }: Props) => {
  const setExternalWalletData = useStore((state) => state.setExternalWalletData);
  const { close: closeEvm } = useWeb3Modal();
  const setCardanoPopupOpen = useStore((state) => state.setIsCardanoMenuOpen);

  const [invalidAddress, setInvalidAddress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [address, setAddress] = useState('');

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setExternalWalletData({ chainType, address: address });
        if (chainType === NetworkChainType.EVM) closeEvm();
        else setCardanoPopupOpen(false);
      }, 1500);
    }
  }, [success, setExternalWalletData, address, chainType, closeEvm, setCardanoPopupOpen]);

  return (
    <div className="relative w-full">
      <div
        className={cn(
          'text-center font-mediumitalic uppercase text-meldwhite/60 ',
          // line on the left
          "before:absolute before:top-[9px] before:block before:h-[1px] before:w-[42%] before:bg-meldwhite/60 before:content-['_']",
          // line on the right
          "after:absolute after:right-0 after:top-[9px] after:block after:h-[1px] after:w-[42%] after:bg-meldwhite/60 after:content-['_']",
        )}
      >
        <span className="w-10 bg-meldred">OR</span>
      </div>
      <div className="mb-[19px] mt-5 text-center">
        <p className="m-0 font-semibold tracking-[0.64px] text-meldwhite">Bridge to Recipient Address</p>
      </div>
      <form
        onSubmit={(e) => {
          const isValid = isValidAddress(address, chainType);
          if (isValid) {
            capture(MetricEvents.UserConnectsPaperWallet, { chainType });
            setSuccess(true);
          } else {
            setInvalidAddress(true);
          }
          inputRef?.current?.focus();
          e.preventDefault();
        }}
      >
        <div className="flex h-[56px] items-center overflow-hidden rounded-lg border border-solid border-meldwhite">
          <div className="flex h-full w-full items-center shadow-[0px_0px_12px_0px_rgba(0,0,0,0.25)_inset]">
            <input
              value={success ? shortenAddress(address, 4, 5) : address}
              onChange={(e) => {
                setInvalidAddress(false);
                setSuccess(false);
                setAddress(e.target.value);
              }}
              ref={inputRef}
              autoFocus
              className={cn(
                'w-full border-none bg-transparent px-6 font-semibold text-meldwhite outline-none transition-all',
                invalidAddress && 'text-meldblack',
              )}
            />
          </div>
          <Button
            type="submit"
            style={{ borderLeftStyle: 'solid' }}
            className="flex h-full w-[58px] items-center justify-center rounded-none border-l border-meldwhite"
          >
            <img src={success ? successIcon : invalidAddress ? wrongIcon : arrowIcon} />
          </Button>
        </div>
      </form>
    </div>
  );
};
