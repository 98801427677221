import { isDevEnv, isNotProdEnv, isStagingEnv } from '@utils/environments-helper';

export const CARDANO_NFT_POLICY_ID = isNotProdEnv
  ? '2511e9ad0baa8c1a662e6eab1da2b7e501d2de729d1a317b909df24f'
  : 'b06729158210bf1ba13f8f3d7d422a918d3eaa82561a705552a2568b';

export const EVM_NFT_POLICY_ID = isStagingEnv
  ? '0xd6dc78a2a2c3cdb40f29d3a2ee2ce55e3748c6ff'
  : isDevEnv
    ? '0x78ebac2a78b255fc8a0148c2b767ae1b617bd2dc'
    : '0x333000dca02578efe421be77ff0acc0f947290f0';
