import { useSelectedNfts } from '@hooks/use-selected-nfts';
import { useStore } from '@store/store';
import { useEffect, useRef, useState } from 'react';
import { animated, config, useTrail } from 'react-spring';
import { NftDisplay } from './nft-display';
import { cn } from '@utils/cn';
import { useIsBridging } from '@hooks/use-is-bridging';
import { shallow } from 'zustand/shallow';
import { capture } from '@utils/metrics';
import { MetricEvents } from '@typings/metric-events';

type Props = {
  offsetOfDetailsComponent: number;
};

export const ToggleBridgeTypeButton = ({ offsetOfDetailsComponent }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [renderNfts, setRenderNfts] = useState(true);
  const { allNfts } = useSelectedNfts();
  const nftBridgeSelected = useStore((state) => state.nftBridgeSelected);
  const setNftBridgeSelected = useStore((state) => state.setNftBridgeSelected);
  const setBridgeData = useStore((state) => state.setBridgeData);
  const [positionedRelative, setPositionedRelative] = useState(true);
  const { approving } = useStore((state) => state.bridgeData, shallow);

  const [hoveringNftBanner, setHoveringNftBanner] = useState(false);
  const isBridging = useIsBridging();
  const trails = useTrail(allNfts.length > 6 ? 6 : allNfts.length, {
    opacity: hoveringNftBanner ? 1 : 0,
    x: hoveringNftBanner ? 0 : -10,
    scale: hoveringNftBanner ? 1 : 0.5,
    config: { ...config.wobbly, clamp: true },
  });

  useEffect(() => {
    if (!nftBridgeSelected) {
      setTimeout(() => {
        setRenderNfts(true);
      }, 700);
      setHoveringNftBanner(false);
    }
  }, [nftBridgeSelected]);

  useEffect(() => {
    const calculatePosition = () => {
      const windowHeight = window.innerHeight;
      if (windowHeight - 120 > offsetOfDetailsComponent) {
        setPositionedRelative(false);
      } else setPositionedRelative(true);
    };

    calculatePosition();

    window.addEventListener('resize', calculatePosition);

    return () => window.removeEventListener('resize', calculatePosition);
  }, [offsetOfDetailsComponent]);

  return (
    <div
      ref={ref}
      className={cn(
        'group relative mb-[33px] hidden cursor-pointer transition-all md:block',
        (isBridging || approving) && 'pointer-events-none opacity-40',
        !positionedRelative && 'fixed bottom-4 mb-0',
      )}
    >
      <p
        onMouseEnter={() => {
          if (!nftBridgeSelected) setHoveringNftBanner(true);
        }}
        onMouseLeave={() => {
          setHoveringNftBanner(false);
        }}
        className="z-10 flex cursor-pointer flex-row font-semibold text-lg uppercase tracking-[0.72px] text-meldwhite transition-all hover:opacity-80"
        onClick={() => {
          setBridgeData({
            completedStep: 0,
            data: undefined,
            startedAt: undefined,
            bridgeAgain: false,
            bridgeFailed: false,
            initiatedBridge: false,
            feeAmount: '',
          });
          if (nftBridgeSelected) {
            capture(MetricEvents.UserClicksTokenBridge);
            setNftBridgeSelected(false);
          } else {
            capture(MetricEvents.UserClicksNftBridge);
            setNftBridgeSelected(true);
            setTimeout(() => {
              setRenderNfts(false);
            }, 300);
          }
        }}
      >
        meld{' '}
        <span
          className={cn(
            'flex w-[47px] justify-center overflow-hidden tracking-[0.72px] text-meldwhite transition-all delay-1000 duration-700',
            nftBridgeSelected && 'w-1 opacity-0',
          )}
        >
          nft
        </span>{' '}
        bridge
      </p>

      {renderNfts && (
        <div className="absolute -bottom-2 z-0 flex gap-2 [&_img]:h-4 [&_img]:w-4">
          {trails.map((props, index) => (
            <animated.div key={index} style={props} className={'relative opacity-0'}>
              <NftDisplay {...allNfts[index]} networkImageVisible={false} />
            </animated.div>
          ))}
        </div>
      )}
    </div>
  );
};
