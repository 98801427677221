import { SupportedWalletBE } from '@typings/wallet-asset.types';
import {
  GetWalletRegistrationQueryType,
  OriginTypes,
  PostWalletRegistrationQueryType,
} from './wallet-registration-query.types';
import { axiosInstance } from '@api/axios';

export const postWalletRegistrationQuery = async (
  address: string,
  // TODO - fix
  // @ts-expect-error 6133
  wallet: SupportedWalletBE,
  origin = OriginTypes.WEB_APP,
): Promise<PostWalletRegistrationQueryType> => {
  return await axiosInstance.post('/wallet', [{ origin: origin, address }]);
};

export const getWalletRegistrationQuery = async (addresses: string[]): Promise<GetWalletRegistrationQueryType> => {
  const params = new URLSearchParams();
  addresses.map((a) => params.append('walletAddress', a));

  return (await axiosInstance.get('/wallet', { params })).data;
};

export const GET_WALLET_REGISTRATION_QUERY_KEY = 'GET_WALLET_REGISTRATION_QUERY_KEY';
