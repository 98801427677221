import { memo } from 'react';
import { NftMetadata } from '@api/meld-app/nfts/nfts-query.types';
import NoNft from '../assets/no-nft.png';
import Spinner from '../assets/preloader.png';
import { cn } from '@utils/cn';
import { getNetworkIcon } from '@utils/assets-helper';

type NftDisplayProps = {
  metadata?: NftMetadata;
  alt?: string;
  className?: string;
  noNfts?: boolean;
  isLoading?: boolean;
  network?: string;
  networkImageVisible?: boolean;
  noNftsLabel?: boolean;
};

export const NftDisplay = memo(
  ({
    metadata,
    alt,
    isLoading,
    noNfts,
    network,
    networkImageVisible = true,
    className,
    noNftsLabel = true,
  }: NftDisplayProps) => {
    const { image, animation_url } = metadata || {};

    if (noNfts || isLoading) {
      return (
        <div className={cn(className, 'relative h-[90px] w-[90px] [&_img]:max-h-full [&_img]:max-w-full')}>
          {isLoading ? (
            <div className="flex h-full items-center justify-center">
              <img src={Spinner} alt={'Loading...'} className="spinner h-[35px] w-[35px]" />
            </div>
          ) : (
            <>
              <img src={NoNft} className="opacity-30" />
              {noNftsLabel && (
                <p className="absolute left-[50%] top-[50%] m-0 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap font-semibold text-[11px] tracking-[0.44px] text-meldwhite">
                  NO NFTs
                </p>
              )}
            </>
          )}
        </div>
      );
    }

    return (
      <div
        className={cn(
          className,
          'flex h-full w-full select-none items-center justify-center [&_*]:max-h-full [&_*]:max-w-full [&_*]:object-contain',
        )}
        draggable="false"
      >
        {networkImageVisible && network && (
          <img className="absolute bottom-[5px] left-[5px] !h-4 !w-4" src={getNetworkIcon(network)} />
        )}
        {animation_url ? (
          <video autoPlay loop muted>
            <source src={animation_url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : image?.includes('svg+xml') ? (
          <object type="image/svg+xml" data={image}>
            Your browser does not support SVG
          </object>
        ) : (
          <img src={image} alt={alt ?? 'NFT'} loading="lazy" />
        )}
      </div>
    );
  },
);

NftDisplay.displayName = 'NftDisplay';
