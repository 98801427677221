import { useBridgeTokenData } from '@hooks/use-bridge-token-data';
import { useIsBridging } from '@hooks/use-is-bridging';
import { useIsMobile } from '@hooks/use-is-mobile';
import { LiquidityData } from '@store/app-slice';
import { WalletToken } from '@typings/wallet-asset.types';
import { cn } from '@utils/cn';
import { uppercaseFirstLetter } from '@utils/format-string.util';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { CARDANO_NETWORK } from 'src/contants/cardano';
import { useSwitchChain } from 'wagmi';

type Props = {
  selectedWalletToken?: WalletToken;
  wrongEvmNetwork?: boolean;
  wrongCardanoNetwork?: boolean;
  extCardanoWalletName?: string;
  extEvmWalletName?: string;
  maxBridgeAmount?: string;
  cantBridge?: boolean;
  correctChainId?: number;
  nftBridgeSelected: boolean;
  enteredAmount: boolean;
  liquidityData: LiquidityData;
};

const ErrorTypes = {
  CANT_BRIDGE: 'CANT_BRIDGE',
  LIQUIDITY: 'LIQUIDITY',
  WRONG_CARDANO_NETWORK: 'WRONG_CARDANO_NETWORK',
  IS_BRIDGING: 'IS_BRIDGING',
  MAX_BRIDGE_AMOUNT: 'MAX_BRIDGE_AMOUNT',
  WRONG_EVM_NETWORK: 'WRONG_EVM_NETWORK',
  CARDANO_NOT_SUPPORTED: 'CARDANO_NOT_SUPPORTED',
} as const;

type WrongCardanoNetworkType = { type: typeof ErrorTypes.WRONG_CARDANO_NETWORK; walletNameToUse: string };
type WrongEvmNetworkType = { type: typeof ErrorTypes.WRONG_EVM_NETWORK; walletNameToUse: string };
type CantBridgeType = { type: typeof ErrorTypes.CANT_BRIDGE; nftBridgeSelected: boolean };
type MaxBridgeAmountType = { type: typeof ErrorTypes.MAX_BRIDGE_AMOUNT; maxBridgeAmount: string };
type LiquidityType = { type: typeof ErrorTypes.LIQUIDITY; amount: string; destinationNetwork: string };
type IsBridgingType = { type: typeof ErrorTypes.IS_BRIDGING };
type CardanoNotSupportedType = { type: typeof ErrorTypes.CARDANO_NOT_SUPPORTED };

type Error = { showingError: boolean } & (
  | IsBridgingType
  | CardanoNotSupportedType
  | LiquidityType
  | MaxBridgeAmountType
  | CantBridgeType
  | WrongCardanoNetworkType
  | WrongEvmNetworkType
);

export const UnsupportedWalletMessage = memo(
  ({
    selectedWalletToken,
    extCardanoWalletName,
    wrongCardanoNetwork,
    wrongEvmNetwork,
    extEvmWalletName,
    maxBridgeAmount,
    cantBridge,
    correctChainId,
    nftBridgeSelected,
    enteredAmount,
    liquidityData,
  }: Props) => {
    const isBridging = useIsBridging();
    const [cardanoNotSupported, setCardanoNotSupported] = useState(false);
    const [hasError, setHasError] = useState<Error | null>(null);
    const { switchChain } = useSwitchChain();

    const wrongNetworkState = hasError?.showingError ? { opacity: 1 } : { opacity: 0 };

    const error = useMemo(() => {
      if (wrongEvmNetwork && selectedWalletToken && enteredAmount)
        return { type: ErrorTypes.WRONG_EVM_NETWORK, walletNameToUse: extEvmWalletName as string };
      if (cardanoNotSupported) return { type: ErrorTypes.CARDANO_NOT_SUPPORTED };
      if (liquidityData.amount && enteredAmount) return { type: ErrorTypes.LIQUIDITY, ...liquidityData };
      if (wrongCardanoNetwork)
        return {
          type: ErrorTypes.WRONG_CARDANO_NETWORK,
          walletNameToUse: uppercaseFirstLetter(extCardanoWalletName ?? ''),
        };
      if (cantBridge) return { type: ErrorTypes.CANT_BRIDGE, nftBridgeSelected };
      if (maxBridgeAmount) return { type: ErrorTypes.MAX_BRIDGE_AMOUNT, maxBridgeAmount };
      if (isBridging) return { type: ErrorTypes.IS_BRIDGING };
      return null;
    }, [
      cantBridge,
      cardanoNotSupported,
      enteredAmount,
      isBridging,
      liquidityData,
      maxBridgeAmount,
      selectedWalletToken,
      wrongCardanoNetwork,
      wrongEvmNetwork,
      extEvmWalletName,
      extCardanoWalletName,
      nftBridgeSelected,
    ]);

    const selectedWalletTokenRef = useRef(selectedWalletToken);

    useEffect(() => {
      if (!hasError?.showingError) selectedWalletTokenRef.current = selectedWalletToken;
    }, [hasError, selectedWalletToken]);

    useEffect(() => {
      if (error) {
        setHasError({ ...error, showingError: true } as Error);
      } else
        setHasError((oldError) => {
          return oldError ? { ...oldError, showingError: false } : null;
        });
    }, [error]);

    const data = useBridgeTokenData();

    const isMobile = useIsMobile();

    useEffect(() => {
      if (
        (data.tokenData.sourceToken.network === CARDANO_NETWORK ||
          data.tokenData.destinationToken.network === CARDANO_NETWORK) &&
        isMobile
      ) {
        setCardanoNotSupported(true);
      } else {
        setCardanoNotSupported(false);
      }
    }, [data, isMobile]);

    const wrongNetworkSpring = useSpring(wrongNetworkState);

    const getErrorMessage = () => {
      switch (hasError?.type) {
        case ErrorTypes.WRONG_EVM_NETWORK:
        case ErrorTypes.WRONG_CARDANO_NETWORK:
          return (
            <>
              Please{' '}
              <span
                onClick={
                  hasError?.type === ErrorTypes.WRONG_CARDANO_NETWORK
                    ? undefined
                    : () => switchChain && switchChain({ chainId: correctChainId as number })
                }
                className={cn(
                  hasError?.type === ErrorTypes.WRONG_EVM_NETWORK &&
                    "relative inline-block cursor-pointer after:block after:h-[1px] after:w-full after:bg-meldred after:content-['_']",
                )}
              >
                switch to the correct network
              </span>{' '}
              in {hasError.walletNameToUse} to bridge.
            </>
          );
        case ErrorTypes.LIQUIDITY:
          return (
            <span className="block px-3">
              <span className="font-semibold text-meldorange">
                Low liquidity available ({hasError.amount} {selectedWalletTokenRef.current?.symbol}) on the{' '}
                {uppercaseFirstLetter(hasError.destinationNetwork)} network.
              </span>
              <br />
              <span className="font-medium text-meldorange">
                If you submit your bridge transaction now, your tokens may be locked until there is sufficient
                liquidity. This may take up to 24 hours.
              </span>
            </span>
          );
        case ErrorTypes.CANT_BRIDGE:
          return `Not enough ${hasError.nftBridgeSelected ? 'gMELD' : selectedWalletTokenRef.current?.symbol ?? ''} to pay BRIDGE FEE. You can’t bridge this ${hasError.nftBridgeSelected ? 'NFT' : 'amount'}.`;
        case ErrorTypes.MAX_BRIDGE_AMOUNT:
          return (
            <>
              Not enough {selectedWalletTokenRef.current?.symbol ?? ''} to pay BRIDGE FEE. <br />
              <span className="font-medium">
                Your max amount to bridge is {hasError.maxBridgeAmount} {selectedWalletTokenRef.current?.symbol ?? ''}.
              </span>
            </>
          );
        case ErrorTypes.IS_BRIDGING:
          return 'Bridging can take a few minutes. Please stay on this page. If you leave, reconnect your wallet and check the transaction status in the side panel.';
        case ErrorTypes.CARDANO_NOT_SUPPORTED:
          return 'Cardano is not supported on mobile yet.';

        default:
          return '';
      }
    };

    return (
      <animated.div
        style={wrongNetworkSpring}
        className={cn(
          'm-0 mx-auto mb-3 box-border flex h-[60px] w-full items-center justify-center rounded-lg bg-meldblack px-4 text-center opacity-0 ',
        )}
      >
        <p className={cn('font-semibold text-[12px] leading-[18px] text-meldred', isBridging && 'px-2 text-meldblue')}>
          {getErrorMessage()}
        </p>
      </animated.div>
    );
  },
);
