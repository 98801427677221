import { createWeb3Modal } from '@web3modal/wagmi/react';

import { WagmiProvider } from 'wagmi';
import { ReactNode } from 'react';
import { WAGMI_CONFIG, WC_PROJECT_ID } from './constants';

// 3. Create modal
createWeb3Modal({
  wagmiConfig: WAGMI_CONFIG,
  projectId: WC_PROJECT_ID,
  themeVariables: {
    '--w3m-font-family': 'BRHendrixSemiBold',
    '--w3m-accent': '#FFFFFF',
    '--w3m-color-mix': '#EB0046',
    '--w3m-color-mix-strength': 100,
    '--w3m-border-radius-master': '3px',
  },
});

export function Web3ModalProvider({ children }: { children: ReactNode }) {
  /** 
   * this code doesn't seem to work anymore - pasted from meld app
  // update config as we get the chains data from the BE
  useEffect(() => {
    if (networks && Object.keys(networks).length && !hasSetCorrectConfigRef.current) {
      hasSetCorrectConfigRef.current = true;
      const chains = parseChains(networks);

      console.log('chains: ', JSON.stringify(chains));

      const wagmiConfig = createConfig({
        // @ts-expect-error 2322
        chains,
        projectId,
        metadata,
      });

      setPostInitWagmiConfig(wagmiConfig);
    }
  }, [networks]);
  */

  return <WagmiProvider config={WAGMI_CONFIG}>{children}</WagmiProvider>;
}
