import { getUserBridgesByAddress } from '@api/bridge/user-bridges';
import { useStore } from '@store/store';
import { useQuery } from '@tanstack/react-query';
import { UserBridges } from '@typings/api';
import { parseUserBridgesToTransaction } from '@utils/parse-user-bridges';
import { getPendingBridgesFromLocalStorage } from '@utils/pending-bridges-storage';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';

export const useUserBridges = () => {
  const cardanoAddress = useStore((state) => state.cardanoData.cardanoAddress);
  const evmAddress = useStore((state) => state.evmData.evmAddress);
  const availableTokens = useStore((state) => state.availableTokens, shallow);

  const setEvmData = useStore((state) => state.setEvmData);
  const setCardanoData = useStore((state) => state.setCardanoData);

  const {
    isLoading: isLoadingEvm,
    fetchStatus: fetchStatusEvm,
    data: dataEvm,
    isRefetching: isRefetchingEvm,
  } = useQuery(
    ['GET_USER_BRIDGES_EVM', evmAddress],
    () => getUserBridgesByAddress({ walletAddress: [evmAddress as string] }),
    {
      refetchOnWindowFocus: false,
      enabled: !!evmAddress,
      refetchOnMount: false,
    },
  );

  const {
    isLoading: isLoadingCardano,
    fetchStatus: fetchStatusCardano,
    data: dataCardano,
    isRefetching: isRefetchingCardano,
  } = useQuery(
    ['GET_USER_BRIDGES_CARDANO', cardanoAddress],
    () => getUserBridgesByAddress({ walletAddress: [cardanoAddress as string] }),
    {
      refetchOnWindowFocus: false,
      enabled: !!cardanoAddress,
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    if (dataEvm) {
      const pendingUserBridges = getPendingBridgesFromLocalStorage(true);
      pendingUserBridges
        .filter((a) => a.walletAddress === evmAddress)
        .forEach(({ hash, walletAddress, destinationNetwork, amount, token, network, tokenName }) => {
          const bridgeExists = dataEvm.find((a) => a.originalNetwork.transaction === hash);
          if (!bridgeExists) {
            // push non-existing bridge in API response (will be removed once we refetch the user bridges)
            dataEvm.unshift({
              status: 'Pending',
              // @ts-expect-error 2739
              originalNetwork: { transaction: hash, user: walletAddress, amount, token, network, tokenName },
              // @ts-expect-error 2739
              destinationNetwork: { network: destinationNetwork },
            });
          }
        });
      const parsedEvmBridges = parseUserBridgesToTransaction(dataEvm, availableTokens);
      setEvmData({ evmUserBridges: parsedEvmBridges });
    }
  }, [dataEvm, availableTokens, setEvmData, evmAddress]);

  useEffect(() => {
    if (dataCardano) {
      const pendingUserBridges = getPendingBridgesFromLocalStorage(false);
      pendingUserBridges
        .filter((a) => a.walletAddress === cardanoAddress)
        .forEach(({ hash, walletAddress, destinationNetwork, amount, token, network, tokenName }) => {
          const bridgeExists = dataCardano.find((a) => a.originalNetwork.transaction === hash);
          if (!bridgeExists) {
            // push non-existing bridge in API response (will be removed once we refetch the user bridges)
            dataCardano.unshift({
              status: 'Pending',
              // @ts-expect-error 2739
              originalNetwork: { transaction: hash, user: walletAddress, amount, token, network, tokenName },
              // @ts-expect-error 2739
              destinationNetwork: { network: destinationNetwork },
            });
          }
        });

      // need to group by hash to know how many NFTs were trasnferred (they share the same hash)
      const cardanoDataByHash = dataCardano.reduce<Record<string, UserBridges>>((prev, curr) => {
        const existingArray = prev[curr.originalNetwork.transaction ?? ''];
        if (existingArray) existingArray.push(curr);
        else prev[curr.originalNetwork.transaction ?? ''] = [curr];
        return prev;
      }, {});

      const normalisedCardanoData = Object.values(cardanoDataByHash).map((a) => {
        const assetName = Buffer.from(a[0].originalNetwork.tokenName, 'hex').toString();
        return { ...a[0], cardanoNfts: assetName.includes('Meld Bank Manager') ? a.length : undefined };
      });

      const parsedCardanoBridges = parseUserBridgesToTransaction(normalisedCardanoData, availableTokens);
      setCardanoData({ cardanoUserBridges: parsedCardanoBridges });
    }
  }, [dataCardano, availableTokens, setCardanoData, cardanoAddress]);

  return {
    isLoadingEvm,
    fetchStatusEvm,
    dataEvm,
    isRefetchingEvm,
    isLoadingCardano,
    fetchStatusCardano,
    dataCardano,
    isRefetchingCardano,
  };
};
