import { initReactI18next } from 'react-i18next';

import i18next from 'i18next';
import Detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import en from './languages/en';
import es from './languages/es';

export const resources = { en, es } as const;

export const availableLanguages = {
  en: 'us',
  es: 'es',
};

export const defaultNS = 'common';

const initTranslation = (_resources: typeof resources) => {
  i18next
    .use(Backend)
    .use(Detector)
    .use(initReactI18next)
    .init({
      returnNull: false,
      fallbackLng: 'en',
      load: 'languageOnly',
      debug: false,
      ns: Object.keys(_resources),
      defaultNS: 'common',
      resources: _resources,
    });
};

initTranslation(resources);

/**
 * this allows to change "languages/en.ts" and update, add and remove text strings
 * while preventing a full page reload
 * */
if (import.meta.hot) {
  import.meta.hot.accept('./languages/en', async function (module) {
    if (module) {
      const resources = { en: module.default, es } as const;
      initTranslation(resources);
    }
  });
}

export default i18next;
