import { AvailableToken } from '@api/meld-app/available-tokens/available-tokens.types';
import { NetworkChainType } from '@api/meld-app/networks/networks.types';
import { SupportedWallet } from './wallet';

export type WalletToken = {
  amount: string; // amount formatted, not in WEI
  color: string;
  contract: string;
  decimals: number;
  description: string;
  fiatAmount: string;
  icon: string; // path to SVG
  isEvm: boolean;
  isNative: boolean;
  isStableCoin: boolean;
  name: string;
  network: string;
  walletAddress: string;
  price: string;
  slug: string;
  symbol: string;
  tokenId: string;
  // props below are added by the FE
  inShortAddress?: boolean;
  wallet: SupportedWallet;
  chainName: string;
  chainType: NetworkChainType;
  usdTotalPrice: number;
  toBridgeBack?: boolean;
  // we need native token info but we dont show these as they are not bridgeable
  hide?: boolean;
};

export type WalletTokenLendingAndBorrowing = WalletToken & {
  poolVariableRate: number;
  canBeUsedAsCollateral: boolean;
};

export type AvailableTokenLendingAndBorrowing = AvailableToken & {
  walletToken?: WalletToken;
  poolVariableRate: number;
  canBeUsedAsCollateral: boolean;
};

export type WalletTokenByChain = {
  [key: string]: WalletToken;
};

type EmptyObject = { [key: string]: never };

export type WalletTokensByChains = Record<string, WalletTokenByChain> | EmptyObject;

/**
 * Summary with assets is used to display the summary of a specific token + the assets for that token
 */
export interface SummaryWithAssets {
  name: string;
  chainNames: string[];
  amount: string; // amount formatted, not in WEI
  fiatAmount: string;
  price: string;
  assets: WalletToken[];
  slug: string;
  description: string;
  symbol?: string;
  icon: string; // path to SVG
  decimals: number;
}

export enum SupportedWalletBE {
  CARDANO = 'cardano',
  EVM = 'evm',
}

export type AssetPerformance = {
  percentage?: number;
  amount: number;
};
