import { Lucid } from 'lucid-cardano/dist';

import { INetworkNames, Networks, setEvmNetworks, setNetworkNames, setNetworks } from '@utils/networks/networks';
import {
  ExtCardanoWallet,
  SupportedCardanoWallet,
} from '@components/with-cardano-external-wallets/with-cardano-external-wallet.types';
import { AvailableToken } from '@api/meld-app/available-tokens/available-tokens.types';
import { StateSlice } from '@typings/state-creator.types';
import { setSbAssets, setSbAssetsByContract, setSbAssetsByTokenId, setSbAssetsObj } from '@utils/assets-helper';
import { BridgeContracts, UserBridge } from '@typings/api';
import { WalletToken } from '@typings/wallet-asset.types';
import { ValueInputError } from '@typings/ValueInputError';
import { WalletMenuProps } from '@components/user-menu/wallet-menu';
import { BigNumber } from 'ethers';
import { CARDANO_NETWORK } from 'src/contants/cardano';
import { parseUserBridgeTransaction } from '@utils/parse-user-bridges';
import { queryClient } from '@api/query-client';
import {
  GET_EXT_CARDANO_WALLET_TOKENS_QUERY_KEY,
  GET_METAMASK_WALLET_TOKENS_QUERY_KEY,
} from '@api/meld-app/wallet-tokens/wallet-tokens-query';
import { CardanoNft, EvmNft } from '@api/meld-app/nfts/nfts-query.types';
import { SupportedWallet } from '@typings/wallet';
import { GetBridgeFeeReturnType } from '@api/bridge/get-fee';
import { fetchMaxFeeData } from '@utils/fetch-max-fee-data';
import { NetworkChainType } from '@api/meld-app/networks/networks.types';

type EVMData = {
  evmConnectedChainId?: number | undefined;
  evmRequiresApproval?: boolean;
  evmWalletName?: string;
  evmTxFeeData?: { maxFeePerGas: BigNumber; maxPriorityFeePerGas: BigNumber };
  evmUserBridges?: WalletMenuProps['data'] | null;
  notBroadcastedExternalEVMWalletAddress: string | null;
  evmAddress: string | null;
  evmAddressRegistered: boolean;
  evmNfts: Array<EvmNft>;
};

type CardanoData = {
  cardanoNfts: Array<CardanoNft>;
  cardanoWrongNetwork?: boolean;
  cardanoUserBridges: WalletMenuProps['data'] | null;
  cardanoAddress: string | null;
  cardanoDisconnectWallet: (() => void) | null;
  cardanoConnectWallet: ((walletName: SupportedCardanoWallet, autoConnecting: boolean) => Promise<void>) | null;
  cardanoWalletRegistered: boolean;
  cardanoWalletName: SupportedCardanoWallet | null;
  cardanoWallet: Lucid | null;
  cardanoConnected: boolean;
  cardanoLoaded: boolean;
  cardanoConnecting: boolean;
  cardanoNotBroadcastedAddress: string | null;
  cardanoWallets: ExtCardanoWallet[];
};

export type LiquidityData = { amount: string; destinationNetwork: string };

export type InnerBridgeData = { amount: string; to: string; explorerUrl: string; numberOfNfts: number };

export type BridgeData = {
  // this turns true before startedAt is defined
  // essentially means we fired the popup for the user to accept the tx but they haven't accepted yet
  initiatedBridge: boolean;
  approving: boolean;
  startedAt?: number;
  bridgingCardano?: boolean;
  bridgeFailed: boolean;
  explorerUrl?: string;
  completedStep: number;
  bridgeAgain?: boolean;
  data?: InnerBridgeData;
  transactionCost: string;
  // this is returned by the BE when burning the token in the meld network
  realBridgeAmount: string;
  deadline: string;
  feeAmount: string;
  signature: string;
  notEnoughToken: boolean;
  bridgeBackMaxFees: Record<string, { data?: GetBridgeFeeReturnType; isFetching: boolean }>;
  liquidityData: LiquidityData;
};

type InputData = {
  isTransferingFullNativeAmount: boolean;
  amount: string;
  /**
   * this is used when bridging total cardano balance where we deduct 2 ADA
   * also used when bridging total native token balance on EVM
   */
  realAmount: string;
  inputError: ValueInputError | null;
  key: number;
};

type ExternalWalletData = {
  chainType?: NetworkChainType;
  address?: string;
};

export type appSliceType = {
  userTokens: WalletToken[];
  setUserTokens: (userTokens: WalletToken[]) => void;

  externalWalletData: ExternalWalletData;
  setExternalWalletData: (data: Partial<ExternalWalletData>) => void;
  disconnectExternalWallet: () => void;

  evmData: EVMData;
  setEvmData: (newEvmData: Partial<EVMData>) => void;

  cardanoData: CardanoData;
  setCardanoData: (newCardanoData: Partial<CardanoData>) => void;

  bridgeData: BridgeData;
  setBridgeData: (newBridgeData: Partial<BridgeData>) => void;

  setMaxFeeBridgeData: (tokenId: string, data: { data?: GetBridgeFeeReturnType; isFetching: boolean }) => void;

  inputData: InputData;
  setInputData: (newInputData: Partial<InputData>, resetAmount?: boolean) => void;

  nftBridgeSelected: boolean;
  setNftBridgeSelected: (nftBridgeSelected: boolean) => void;

  numberFormatting: { decimalSeparator: string; thousandsSeparator: string };
  setNumberFormatting: (data: { decimalSeparator: string; thousandsSeparator: string }) => void;

  selectedNfts: Record<string, SupportedWallet | undefined>;
  setSelectedNfts: (id: string, wallet?: SupportedWallet) => void;
  unselectAllEvmNfts: () => void;
  unselectAllCardanoNfts: () => void;

  addUserBridge: (userBridge: UserBridge) => void;

  // this is a hack to make the input reset
  detailsKey: number;
  setDetailsKey: (detailsKey: number) => void;

  selectedWalletToken: undefined | WalletToken;
  setSelectedWalletToken: (selectedWalletToken: undefined | WalletToken) => void;

  updateSelectedWalletToken: (selectedWalletToken: undefined | WalletToken) => void;

  bridgeContracts: BridgeContracts | null;
  setBridgeContracts: (bridgeContracts: BridgeContracts) => void;

  cardanoMenuIsOpen: boolean;
  setIsCardanoMenuOpen: (cardanoMenuIsOpen: boolean) => void;

  setAvailableTokens: (availableTokens: AvailableToken[]) => void;
  availableTokens: AvailableToken[] | null;

  setNetworks: (networks: Networks) => void;
  networks: Networks | null;
};

export const createAppSlice: StateSlice<appSliceType> = (set) => ({
  userTokens: [],
  setUserTokens: (userTokens: WalletToken[]) => set({ userTokens }, false, 'setUserTokens'),

  externalWalletData: {},
  setExternalWalletData: (externalWalletData: Partial<ExternalWalletData>) =>
    set(
      (oldState) => ({ ...oldState, externalWalletData: { ...oldState.externalWalletData, ...externalWalletData } }),
      false,
      'setExternalWalletData',
    ),

  disconnectExternalWallet: () =>
    set((oldState) => ({ ...oldState, externalWalletData: {} }), false, 'disconnectExternalWallet'),

  evmData: {
    notBroadcastedExternalEVMWalletAddress: null,
    evmAddress: null,
    evmAddressRegistered: false,
    evmNfts: [],
  },
  setEvmData: (newEvmData: Partial<EVMData>) =>
    set((oldState) => ({ ...oldState, evmData: { ...oldState.evmData, ...newEvmData } }), false, 'setEvmData'),

  cardanoData: {
    cardanoWrongNetwork: false,
    cardanoAddress: null,
    cardanoUserBridges: [],
    cardanoNotBroadcastedAddress: null,
    cardanoDisconnectWallet: null,
    cardanoConnected: false,
    cardanoLoaded: false,
    cardanoConnecting: false,
    cardanoWallets: [],
    cardanoWalletRegistered: false,
    cardanoWalletName: null,
    cardanoWallet: null,
    cardanoNfts: [],
    cardanoConnectWallet: null,
  },
  setCardanoData: (newCardanoData: Partial<CardanoData>) =>
    set(
      (oldState) => ({ ...oldState, cardanoData: { ...oldState.cardanoData, ...newCardanoData } }),
      false,
      'setEvmData',
    ),

  numberFormatting: { decimalSeparator: '.', thousandsSeparator: ',' },
  setNumberFormatting: (numberFormatting: { decimalSeparator: string; thousandsSeparator: string }) =>
    set({ numberFormatting }, false, 'setNumberFormatting'),

  bridgeData: {
    initiatedBridge: false,
    approving: false,
    bridgeFailed: false,
    completedStep: 0,
    transactionCost: '',
    realBridgeAmount: '',
    deadline: '',
    feeAmount: '',
    signature: '',
    notEnoughToken: false,
    bridgeBackMaxFees: {},
    liquidityData: { amount: '', token: '', destinationNetwork: '' },
  },
  setBridgeData: (newBridgeData: Partial<BridgeData>) =>
    set(
      (oldState) => ({ ...oldState, bridgeData: { ...oldState.bridgeData, ...newBridgeData } }),
      false,
      'setBridgeData',
    ),

  setMaxFeeBridgeData: (tokenId: string, data: { data?: GetBridgeFeeReturnType; isFetching: boolean }) =>
    set(
      (oldState) => ({
        ...oldState,
        bridgeData: {
          ...oldState.bridgeData,
          bridgeBackMaxFees: { ...oldState.bridgeData.bridgeBackMaxFees, [tokenId]: data },
        },
      }),
      false,
      'setMaxFeeBridgeData',
    ),

  inputData: { isTransferingFullNativeAmount: false, amount: '', realAmount: '', inputError: null, key: 0 },
  setInputData: (newInputData: Partial<InputData>, resetAmount = false) =>
    set(
      (oldState) => ({
        ...oldState,
        inputData: {
          ...oldState.inputData,
          ...newInputData,
          key: oldState.inputData.key + (resetAmount ? 1 : 0),
        },
      }),
      false,
      'setInputData',
    ),

  nftBridgeSelected: false,
  setNftBridgeSelected: (nftBridgeSelected: boolean) =>
    set(
      (oldState) => ({
        ...oldState,
        nftBridgeSelected,
        inputData: { ...oldState.inputData, amount: '', realAmount: '', key: oldState.inputData.key + 1 },
        selectedNfts: {},
        bridgeData: { ...oldState.bridgeData, transactionCost: '', feeAmount: '', notEnoughToken: false },
      }),
      false,
      'setNftBridgeSelected',
    ),

  unselectAllEvmNfts: () =>
    set(
      (oldState) => ({
        ...oldState,
        selectedNfts: Object.entries(oldState.selectedNfts).reduce<Record<string, SupportedWallet | undefined>>(
          (prev, [key, value]) => {
            if (value === SupportedWallet.EVM) {
              prev[key] = undefined;
            } else prev[key] = value;
            return prev;
          },
          {},
        ),
      }),
      false,
      'unselectAllEvmNfts',
    ),
  unselectAllCardanoNfts: () =>
    set(
      (oldState) => ({
        ...oldState,
        selectedNfts: Object.entries(oldState.selectedNfts).reduce<Record<string, SupportedWallet | undefined>>(
          (prev, [key, value]) => {
            if (value !== SupportedWallet.EVM) {
              prev[key] = undefined;
            } else prev[key] = value;
            return prev;
          },
          {},
        ),
      }),
      false,
      'unselectAllCardanoNfts',
    ),

  selectedNfts: {},
  setSelectedNfts: (id: string, wallet?: SupportedWallet) =>
    set(
      (oldState) => ({
        ...oldState,
        selectedNfts: { ...oldState.selectedNfts, [id]: oldState.selectedNfts[id] ? undefined : wallet },
        bridgeData: {
          ...oldState.bridgeData,
          transactionCost: '',
          notEnoughToken: false,
          feeAmount: '',
          deadline: '',
          signature: '',
        },
        evmData: { ...oldState.evmData, evmRequiresApproval: false },
      }),
      false,
      'setSelectedNfts',
    ),

  addUserBridge: (userBridge: UserBridge) => {
    set(
      (state) => {
        const parsedBridge = parseUserBridgeTransaction(userBridge, state.availableTokens as Array<AvailableToken>);
        if (state.selectedWalletToken?.network === CARDANO_NETWORK) {
          // refetch user balances
          queryClient.invalidateQueries({ queryKey: [GET_EXT_CARDANO_WALLET_TOKENS_QUERY_KEY] });
          return {
            ...state,
            evmData: {
              ...state.evmData,
              userBridges: state.evmData.evmUserBridges
                ? [parsedBridge, ...state.evmData.evmUserBridges]
                : [parsedBridge],
            },
          };
        } else {
          // refetch user balances
          queryClient.invalidateQueries({ queryKey: [GET_METAMASK_WALLET_TOKENS_QUERY_KEY] });
          return {
            ...state,
            cardanoData: {
              ...state.cardanoData,
              userBridges: state.cardanoData.cardanoUserBridges
                ? [parsedBridge, ...state.cardanoData.cardanoUserBridges]
                : [parsedBridge],
            },
          };
        }
      },
      false,
      'addUserBridge',
    );
  },

  detailsKey: 0,
  setDetailsKey: (detailsKey: number) => set({ detailsKey }, false, 'setDetailsKey'),

  selectedWalletToken: undefined,
  setSelectedWalletToken: (selectedWalletToken: undefined | WalletToken) => {
    selectedWalletToken && fetchMaxFeeData(selectedWalletToken);
    set(
      (oldState) => ({
        ...oldState,
        selectedWalletToken,
        transactionCost: '',
        evmData: { ...oldState.evmData, evmRequiresApproval: false },
        bridgeData: {
          ...oldState.bridgeData,
          notEnoughToken: false,
          feeAmount: '',
          deadline: '',
          signature: '',
          transactionCost: '',
          liquidityData: { amount: '', destinationNetwork: '' },
        },
        inputData: {
          ...oldState.inputData,
          isTransferingFullNativeAmount: false,
          amount: '',
          realAmount: '',
          key: oldState.inputData.key + 1,
        },
      }),
      false,
      'setSelectedWalletToken',
    );
  },

  updateSelectedWalletToken: (selectedWalletToken: undefined | WalletToken) => {
    set(
      (oldState) => ({
        ...oldState,
        selectedWalletToken,
      }),
      false,
      'updateSelectedWalletToken',
    );
  },

  bridgeContracts: null,
  setBridgeContracts: (bridgeContracts: BridgeContracts) => set({ bridgeContracts }, false, 'setBridgeContracts'),

  cardanoMenuIsOpen: false,
  setIsCardanoMenuOpen: (cardanoMenuIsOpen: boolean) => set({ cardanoMenuIsOpen }, false, 'setIsCardanoMenuOpen'),

  availableTokens: null,
  setAvailableTokens: (newAvailableTokens: AvailableToken[]) => {
    setSbAssets(newAvailableTokens);
    setSbAssetsObj(
      newAvailableTokens.reduce<Record<string, AvailableToken>>((prev, curr) => {
        prev[curr.name] = curr;
        return prev;
      }, {}),
    );
    setSbAssetsByTokenId(
      newAvailableTokens.reduce<Record<string, AvailableToken>>((prev, curr) => {
        prev[curr.tokenId] = curr;
        return prev;
      }, {}),
    );
    setSbAssetsByContract(
      newAvailableTokens.reduce<Record<string, AvailableToken>>((prev, curr) => {
        prev[curr.contract] = curr;
        return prev;
      }, {}),
    );
    set({ availableTokens: newAvailableTokens }, false, 'setAvailableTokens');
  },

  networks: null,

  setNetworks: (newNetworks: Networks) => {
    setNetworks(newNetworks);

    // we do this to be able to access correct network manually
    const updatedNetworkNames: INetworkNames = Object.keys(newNetworks).reduce((prev, curr) => {
      let key: keyof INetworkNames;
      switch (curr) {
        case 'preprod':
          key = 'cardano';
          break;
        case 'mumbai':
          key = 'ethereum';
          break;
        case 'fuji':
          key = 'avalanche';
          break;
        case 'kanazawa':
          key = 'meld';
          break;
        default:
          key = curr as keyof INetworkNames;
          break;
      }
      prev[key] = curr;
      return prev;
    }, {} as INetworkNames);

    setNetworkNames(updatedNetworkNames);
    setEvmNetworks(Object.values(newNetworks).filter((network) => network.chainType === 'evm'));
    set({ networks: newNetworks }, false, 'setNetworks');
  },
});
