import { useIsMobile } from '@hooks/use-is-mobile';
import CardanoLogo from '../../assets/cardano.svg?react';
import WalletConnectLogo from '../../assets/walletconnect.svg?react';
import { Menu } from '@mui/material';

export const CloseSvg = ({ className }: { className?: string }) => (
  <svg fill="none" viewBox="0 0 16 16" width={16} className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.54 2.54a1 1 0 0 1 1.42 0L8 6.6l4.04-4.05a1 1 0 1 1 1.42 1.42L9.4 8l4.05 4.04a1 1 0 0 1-1.42 1.42L8 9.4l-4.04 4.05a1 1 0 0 1-1.42-1.42L6.6 8 2.54 3.96a1 1 0 0 1 0-1.42Z"
      clipRule="evenodd"
    />
  </svg>
);

export type Props = {
  open: HTMLElement | null;
  handleClose: () => void;
  onWallectConnectClicked: () => void;
  onCardanoClicked: () => void;
};

export const PickChainPopup = ({ open, handleClose, onWallectConnectClicked, onCardanoClicked }: Props) => {
  const isMobile = useIsMobile();

  return (
    <>
      <Menu
        slotProps={{
          paper: {
            className:
              'm-0 !p-0 w-full !top-[50px] md:w-[396px] bg-meldred text-meldwhite px-5 pt-2 rounded-lg shadow-[0px_4px_40px_0px_rgba(0,0,0,0.50)]',
          },
        }}
        MenuListProps={{ className: 'p-0' }}
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div onClick={(e) => e.stopPropagation()} className={'rounded-[22px] bg-[#E51B44] px-6 pb-6 pt-3'}>
          <p className="m-0 mb-6 font-semibold text-[11px] uppercase text-white/60">Connect</p>
          <div className="h-0.5 w-full bg-[rgba(255,255,255,0.05)]" />

          <div className="flex flex-col gap-3">
            <div
              onClick={onWallectConnectClicked}
              className="flex h-[56px] cursor-pointer items-center justify-between rounded-lg border border-solid  border-[#D9D9D9] px-3 font-semibold text-lg uppercase tracking-[0.72px] text-meldwhite transition-all hover:bg-white/10"
            >
              <div>EVM Wallet</div>
              <WalletConnectLogo />
            </div>
            {!isMobile && (
              <div
                onClick={onCardanoClicked}
                className="flex h-[56px] cursor-pointer items-center justify-between rounded-lg border border-solid border-[#D9D9D9] px-3 font-semibold text-lg uppercase  tracking-[0.72px] text-meldwhite transition-all hover:bg-white/10"
              >
                <div>Cardano wallet</div>
                <CardanoLogo />
              </div>
            )}
          </div>
        </div>
      </Menu>
    </>
  );
};
