import { detect } from 'detect-browser';
import { cn } from '../../utils/cn';
import { useStore } from '../../store/store';
import { SupportedWallet } from '../../types/wallet';
import { shallow } from 'zustand/shallow';
import { CARDANO_SUPPORTED_WALLETS } from 'src/contants/cardano';
import { useMemo } from 'react';
import { ConnectPaperWallet } from '@components/connect-paper-wallet';
import { NetworkChainType } from '@api/meld-app/networks/networks.types';

const browser = detect();

const UNSUPPORTED_BROWSERS = ['firefox', 'safari'];
const unsupportedBrowser = UNSUPPORTED_BROWSERS.includes(browser?.name ?? '');

export const CloseSvg = ({ className }: { className?: string }) => (
  <svg fill="none" viewBox="0 0 16 16" width={16} className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.54 2.54a1 1 0 0 1 1.42 0L8 6.6l4.04-4.05a1 1 0 1 1 1.42 1.42L9.4 8l4.05 4.04a1 1 0 0 1-1.42 1.42L8 9.4l-4.04 4.05a1 1 0 0 1-1.42-1.42L6.6 8 2.54 3.96a1 1 0 0 1 0-1.42Z"
      clipRule="evenodd"
    />
  </svg>
);

export const CardanoPopup = () => {
  const open = useStore((state) => state.cardanoMenuIsOpen);
  const setOpen = useStore((state) => state.setIsCardanoMenuOpen);
  const { cardanoWallets, cardanoConnectWallet } = useStore((state) => state.cardanoData, shallow);
  const { evmAddress } = useStore((state) => state.evmData, shallow);

  const walletsToUse = useMemo(
    () =>
      CARDANO_SUPPORTED_WALLETS.map(({ name, icon, url }) => {
        const existingWallet = cardanoWallets.filter((a) => a.key.toLowerCase() === name.toLowerCase()).shift();
        if (existingWallet) return existingWallet;
        return {
          name,
          icon,
          key: name,
          version: '1',
          url,
        };
      }),
    [cardanoWallets],
  );

  return (
    <>
      <div
        className={cn(
          'pointer-events-none fixed left-0 top-0 z-10 flex h-screen w-screen items-center justify-center bg-[rgba(20,20,20,0.8)] opacity-0 transition-all',
          open && 'pointer-events-auto z-20 opacity-100',
        )}
        onClick={() => setOpen(false)}
      >
        {/* modal */}
        {open && (
          <div
            onClick={(e) => e.stopPropagation()}
            className="zoomInAnimation cardanoPopup relative -mt-[258px] w-full max-w-[450px] rounded-lg bg-[#E51B44] shadow-[rgba(255,255,255,0.05)_0px_0px_0px_1px]"
          >
            <div className="relative flex w-full flex-row items-center justify-center pb-[26px] pt-4 font-semibold">
              <p className="m-0">Connect Wallet</p>
              <div
                onClick={() => setOpen(false)}
                className="absolute right-[18px] flex h-8 w-8 cursor-pointer items-center justify-center rounded-xl transition-all hover:bg-[#ffffff3f]"
              >
                <CloseSvg />
              </div>
            </div>

            <div className={cn('flex flex-col gap-2 px-6', !evmAddress && 'pb-6')}>
              {/* @ts-expect-error 2339 */}
              {walletsToUse.map(({ name, icon, key, url }) => (
                <div
                  key={name}
                  className={cn(
                    'flex h-[56px] flex-row items-center justify-between rounded-xl border border-solid border-meldwhite px-2 pr-6 transition-all ',
                    !url && 'cursor-pointer hover:bg-[#ffffff3f]',
                  )}
                  onClick={() => {
                    !url &&
                      cardanoConnectWallet?.(key === 'nami' ? SupportedWallet.NAMI : SupportedWallet.ETERNL, false);
                  }}
                >
                  <div className={'flex flex-row items-center gap-3'}>
                    <div className="flex h-10 w-10 items-center justify-center overflow-hidden rounded-xl">
                      <img src={icon} className="h-full w-full" />
                    </div>
                    <p className="font-semibold uppercase">{name}</p>
                  </div>
                  {url && !unsupportedBrowser && (
                    <a
                      href={url}
                      target="_blank"
                      className="cursor-pointer font-semibold text-[11px] uppercase text-meldwhite/90 no-underline transition-all hover:text-meldwhite/80"
                    >
                      Install
                    </a>
                  )}
                  {url && unsupportedBrowser && (
                    <p className="font-semibold text-[11px] uppercase text-meldwhite/90">Unsupported browser</p>
                  )}
                </div>
              ))}
            </div>
            {evmAddress && (
              <div className="mb-5 px-6 pt-5">
                <ConnectPaperWallet chainType={NetworkChainType.CARDANO} />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
