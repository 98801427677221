import { NetworkChainType } from '@api/meld-app/networks/networks.types';
import { useStore } from '@store/store';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';

export const useCardanoConnection = () => {
  const open = useStore((state) => state.cardanoMenuIsOpen);
  const setOpen = useStore((state) => state.setIsCardanoMenuOpen);
  const externalWallet = useStore((state) => state.externalWalletData, shallow);
  const disconnectExternalWallet = useStore((state) => state.disconnectExternalWallet);

  const { cardanoConnecting, cardanoAddress, cardanoConnected, cardanoDisconnectWallet } = useStore(
    (state) => state.cardanoData,
    shallow,
  );

  useEffect(() => {
    if (cardanoConnected) {
      setOpen(false);
    }
  }, [cardanoConnected, setOpen]);

  return {
    open,
    address: cardanoAddress,
    isConnected: cardanoConnected,
    isConnecting: cardanoConnecting,
    disconnect:
      externalWallet.chainType === NetworkChainType.CARDANO ? disconnectExternalWallet : cardanoDisconnectWallet,
    setOpen,
  };
};
