/**
 * Spanish dictionary.
 */
export default {
  common: {
    header: {
      wallet: 'Cartera',
      supplyingAndBorrowing: 'Supplying & Borrowing',
      staking: 'Staking',
      governance: 'Governance',
    },
    //HEADER:
    Account: 'Cuenta',
    Wallet: 'Cartera',
    Staking: 'Replanteo',
    'Supply & Borrow': 'Suministro & Préstamo',
    Governance: 'Gobernancia',
    'Connect wallet': 'Conectar billetera',
  },
};
