import { cn } from '@utils/cn';
import { animated, useSpring } from 'react-spring';
import ShareIcon from '../../assets/share.svg?react';
import { memo, useEffect, useState } from 'react';
import { InnerBridgeData } from '@store/app-slice';

type Props = {
  bridgeData?: InnerBridgeData;
};

export const SuccessMessage = memo(({ bridgeData }: Props) => {
  const [dataToUse, setDataToUse] = useState({ destinationNetwork: '', explorerUrl: '', amount: '' });

  const successAnimationState = bridgeData
    ? { from: { opacity: 0, y: 10 }, to: { opacity: 1, y: 0 } }
    : { from: { opacity: 1, y: 0 }, to: { opacity: 0, y: 20 } };

  const bridgeSuccess1Spring = useSpring({
    ...successAnimationState,
    delay: bridgeData ? 550 : 350,
  });

  const bridgeSuccess2Spring = useSpring({
    ...successAnimationState,
    delay: bridgeData ? 750 : 200,
  });

  useEffect(() => {
    if (bridgeData) {
      setDataToUse({
        amount: bridgeData.numberOfNfts
          ? `${bridgeData.numberOfNfts} NFT${bridgeData.numberOfNfts > 1 ? 's' : ''}`
          : bridgeData.amount,
        destinationNetwork: bridgeData.to,
        explorerUrl: bridgeData.explorerUrl,
      });
    }
  }, [bridgeData]);

  return (
    <animated.div
      className={cn(
        'opacity-1 absolute flex max-w-[258px] flex-col flex-wrap pt-10 md:block md:max-w-none',
        bridgeData && 'z-10',
        !bridgeData && 'pointer-events-none -z-10',
      )}
    >
      <animated.div
        style={bridgeSuccess1Spring}
        className="text-center font-semibold text-xl uppercase tracking-[0.72px] text-meldgreen opacity-0 md:whitespace-nowrap md:text-lg"
      >
        SUCCESSFULLY BRIDGED <span className="text-meldblack">{dataToUse.amount}</span> TO{' '}
        {dataToUse.destinationNetwork}
      </animated.div>
      <animated.div style={bridgeSuccess2Spring} className={'flex w-full justify-center opacity-0'}>
        <animated.a
          className="mt-3 block w-max cursor-pointer no-underline md:mt-1"
          target="_blank"
          href={dataToUse.explorerUrl}
        >
          <div className="flex items-center justify-center gap-1 [&_path]:fill-meldwhite">
            <div className="font-semibold text-xs text-meldwhite">VIEW IN EXPLORER</div>
            <ShareIcon className="h-4 w-4" />
          </div>
        </animated.a>
      </animated.div>
    </animated.div>
  );
});
