import { bridgeAxiosInstance } from '@api/axios';
import { UserBridges, UserBridgesParams } from '@typings/api';

export const getUserBridgesByAddress = async (params?: UserBridgesParams): Promise<UserBridges> => {
  return (
    await bridgeAxiosInstance.get<UserBridges>(
      `/bridges/user?walletAddress=${params?.walletAddress.join(',').toLowerCase()}`,
    )
  ).data.sort(
    (a, b) =>
      new Date(b.originalNetwork.confirmationTimestamp ?? 0).getTime() -
      new Date(a.originalNetwork.confirmationTimestamp ?? 0).getTime(),
  );
};
