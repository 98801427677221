import {
  GET_EXT_CARDANO_WALLET_TOKENS_QUERY_KEY,
  GET_METAMASK_WALLET_TOKENS_QUERY_KEY,
  getExtCardanoWalletTokensQuery,
  getMetamaskWalletTokensQuery,
} from '@api/meld-app/wallet-tokens/wallet-tokens-query';
import { useEthersSigner } from '@hooks/use-ethers-signer';
import { useStore } from '@store/store';
import { useQuery } from '@tanstack/react-query';
import { SupportedWallet } from '@typings/wallet';
import { WalletToken } from '@typings/wallet-asset.types';
import { formatEther, formatUnits } from 'ethers/lib/utils';
import { useCallback, useEffect, useMemo } from 'react';
import { CARDANO_NETWORK } from 'src/contants/cardano';
import { shallow } from 'zustand/shallow';

export const useWalletTokens = () => {
  const { cardanoAddress } = useStore((state) => state.cardanoData, shallow);
  const evmAddress = useStore((state) => state.evmData.evmAddress);
  const bridgeContracts = useStore((state) => state.bridgeContracts, shallow);
  const availableTokens = useStore((state) => state.availableTokens, shallow);
  const setUserTokens = useStore((state) => state.setUserTokens);

  const evmSigner = useEthersSigner();

  const {
    isLoading: isLoadingEvm,
    fetchStatus: fetchStatusEvm,
    data: dataEvm,
    isRefetching: isRefetchingEvm,
  } = useQuery(
    [GET_METAMASK_WALLET_TOKENS_QUERY_KEY, evmAddress],
    () => getMetamaskWalletTokensQuery(evmAddress as string),
    {
      refetchOnWindowFocus: false,
      enabled: !!evmAddress && !!evmSigner,
      refetchOnMount: false,
    },
  );

  const {
    isLoading: isLoadingCardano,
    fetchStatus: fetchStatusCardano,
    isRefetching: isRefetchingCardano,
    data: dataCardano,
  } = useQuery(
    [GET_EXT_CARDANO_WALLET_TOKENS_QUERY_KEY, cardanoAddress],
    () => getExtCardanoWalletTokensQuery(cardanoAddress as string),
    {
      refetchOnWindowFocus: false,
      enabled: !!cardanoAddress,
      refetchOnMount: false,
    },
  );

  const defineToken = useCallback(
    (
      bridgeToken: { network: string; token: string; toBridgeBack: boolean },
      walletTokens: Array<WalletToken>,
      wallet: SupportedWallet,
    ) => {
      const userToken = walletTokens?.find((token) =>
        !bridgeToken.token
          ? token.isNative && token.network === bridgeToken.network
          : bridgeToken.token === token.contract,
      );

      const availableToken = userToken
        ? userToken
        : availableTokens?.find((token) =>
            !bridgeToken.token
              ? token.isNative && token.network === bridgeToken.network
              : bridgeToken.token === token.contract,
          );

      let symbol = (userToken ?? availableToken)?.symbol ?? '';
      symbol = symbol === 'TestERC20' ? 'Test' : symbol;

      return {
        ...(userToken ?? availableToken),
        amount: formatUnits(userToken?.amount ?? '0', (userToken ?? availableToken)?.decimals ?? 18),
        toBridgeBack: bridgeToken.toBridgeBack,
        symbol,
        wallet,
      };
    },
    [availableTokens],
  );

  const tokenData = useMemo(() => {
    const allSupportedTokens: Array<{ network: string; token: string; toBridgeBack: boolean }> = [];

    bridgeContracts?.supportedTokens.forEach((entry) => {
      allSupportedTokens.push({ network: entry.network, token: entry.remoteToken, toBridgeBack: false });
      allSupportedTokens.push({ network: entry.network, token: entry.token, toBridgeBack: true });
    });

    const tokenData = allSupportedTokens
      .map((a) =>
        defineToken(
          a,
          (dataEvm ?? []).concat(dataCardano ?? []),
          a.network === CARDANO_NETWORK
            ? useStore.getState().cardanoData.cardanoWalletName ?? SupportedWallet.EVM
            : SupportedWallet.EVM,
        ),
      )
      .filter((t) => t.symbol);

    const existingMeldToken = dataEvm?.filter((a) => a.isNative && a.symbol.toLowerCase() === 'gmeld').shift();
    const availableTokenMeld = existingMeldToken
      ? undefined
      : {
          ...availableTokens?.filter((a) => a.isNative && a.symbol.toLowerCase() === 'gmeld').shift(),
          amount: 0,
        };

    const meldToken = existingMeldToken ?? availableTokenMeld;

    return tokenData
      .concat([
        {
          ...{ ...meldToken, amount: formatEther(meldToken?.amount ?? '') },
          hide: true,
        },
      ])
      .filter((a) => a.symbol);
  }, [dataEvm, dataCardano, bridgeContracts, defineToken, availableTokens]);

  useEffect(() => {
    setUserTokens(tokenData as Array<WalletToken>);
  }, [tokenData, setUserTokens]);

  return {
    isLoadingEvm: isLoadingEvm && fetchStatusEvm !== 'idle',
    fetchStatusEvm,
    dataEvm,
    isRefetchingEvm,
    isLoadingCardano: isLoadingCardano && fetchStatusCardano !== 'idle',
    dataCardano,
    isRefetchingCardano,
    tokenData,
  };
};
