import { useStore } from '@store/store';
import { useQuery } from '@tanstack/react-query';
import { CardanoNft, EvmNft, GetNFTsQueryParams } from '@api/meld-app/nfts/nfts-query.types';
import { SupportedWallet } from '@typings/wallet';
import { fetchPaginatedDataCursor } from '@utils/fetch-paginated-data-cursor';
import { CARDANO_NETWORK } from 'src/contants/cardano';
import { GET_NFTS_QUERY_KEY, GET_EVM_NFTS_QUERY_KEY } from '@api/meld-app/nfts/nfts-query';
import { CARDANO_NFT_POLICY_ID, EVM_NFT_POLICY_ID } from 'src/contants/nfts';
import { checkAndExtractRawMetadata, formatNftMedia } from '@utils/nftUtils';
import { shallow } from 'zustand/shallow';
import { MELD_NETWORK } from 'src/contants/meld';
import { useEffect } from 'react';

/**
 * Fetches Cardano and Evm NFTs, waits for all images to load before pushing data to the state
 */
export const useNFTS = () => {
  const evmWalletAddress = useStore((state) => state.evmData.evmAddress);
  const setCardanoData = useStore((state) => state.setCardanoData);
  const setEvmData = useStore((state) => state.setEvmData);
  const unselectAllCardanoNfts = useStore((state) => state.unselectAllCardanoNfts);
  const unselectAllEvmNfts = useStore((state) => state.unselectAllEvmNfts);

  const { cardanoAddress, cardanoWalletName } = useStore((state) => state.cardanoData, shallow);

  const { isLoading, fetchStatus } = useQuery(
    [GET_NFTS_QUERY_KEY, cardanoAddress],
    async () => {
      const nfts = await fetchPaginatedDataCursor<CardanoNft, GetNFTsQueryParams>('/nfts', {
        walletAddress: cardanoAddress as string,
        perPage: 10,
        network: CARDANO_NETWORK,
      });

      const bankManagerNfts = nfts.filter((a) => a.policyId === CARDANO_NFT_POLICY_ID);

      const images: Array<Promise<boolean>> = [];

      bankManagerNfts.forEach((nft) => {
        nft.wallet = cardanoWalletName as SupportedWallet;
        return nft;
      });

      const parsedNfts = bankManagerNfts.map((a) => checkAndExtractRawMetadata(a)).map(formatNftMedia);
      // preload images before showing NFTs
      parsedNfts.forEach((nft) => {
        if (nft.metadata?.image) {
          const image = new Image();
          image.src = nft.metadata.image;
          images.push(loadImage(nft.metadata.image));
        }
      });

      await Promise.all(images);

      setCardanoData({ cardanoNfts: parsedNfts });

      return parsedNfts;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!cardanoAddress,
      refetchOnMount: false,
    },
  );

  // reset cardano NFTs on disconnect
  useEffect(() => {
    if (!cardanoAddress) {
      setCardanoData({ cardanoNfts: [] });
      unselectAllCardanoNfts();
    }
  }, [cardanoAddress, setCardanoData, unselectAllCardanoNfts]);

  // reset evm NFTs on disconnect
  useEffect(() => {
    if (!evmWalletAddress) {
      setEvmData({ evmNfts: [] });
      unselectAllEvmNfts();
    }
  }, [evmWalletAddress, setEvmData, unselectAllEvmNfts]);

  const { isLoading: evmIsLoading, fetchStatus: evmFetchStatus } = useQuery(
    [GET_EVM_NFTS_QUERY_KEY, evmWalletAddress],
    async () => {
      const nfts = await fetchPaginatedDataCursor<EvmNft, GetNFTsQueryParams>('/nfts', {
        contract: EVM_NFT_POLICY_ID,
        walletAddress: evmWalletAddress as string,
        perPage: 10,
        network: MELD_NETWORK,
      });

      const images: Array<Promise<boolean>> = [];

      nfts.map((nft) => {
        nft.wallet = SupportedWallet.EVM;
        return nft;
      });

      const parsedNfts = nfts.map((a) => checkAndExtractRawMetadata(a, true)).map(formatNftMedia);
      // preload images before showing NFTs
      parsedNfts.forEach((nft) => {
        if (nft.metadata?.image) {
          const image = new Image();
          image.src = nft.metadata.image;
          images.push(loadImage(nft.metadata.image));
        }
      });

      await Promise.all(images);
      setEvmData({ evmNfts: parsedNfts });

      return nfts;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!evmWalletAddress,
    },
  );

  return {
    isLoading: (isLoading && fetchStatus !== 'idle') || (evmIsLoading && evmFetchStatus !== 'idle'),
  };
};

const loadImage = (src: string): Promise<boolean> => {
  return new Promise(function (resolve) {
    const img = new Image();
    img.onload = function () {
      resolve(true);
    };
    img.onerror = img.onabort = function () {
      resolve(false);
    };
    img.src = src;
  });
};
