import { bridgeAxiosInstance } from '@api/axios';

export type GetBridgeFeeParam = {
  // destination network chain id
  network: number;
  // contract address
  token: string;
  // amount to transfer in wei
  amount: string;
  tokenId?: string;
};

export type GetBridgeFeeReturnType = {
  amount: string;
  feeInfo: {
    deadline: string;
    feeAmount: string;
    signature: string;
  };
  feeToken: string;
};

export const getBridgeFee = async (data: GetBridgeFeeParam): Promise<GetBridgeFeeReturnType> => {
  return (await bridgeAxiosInstance.get('/bridges/fee?excludeFee=true', { params: data })).data;
};
