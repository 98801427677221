import { getBridgesByRequestId } from '@api/bridge/bridges';
import { getUserBridgesByAddress } from '@api/bridge/user-bridges';
import { useStore } from '@store/store';
import { Bridge, UserBridge, UserBridges } from '@typings/api';
import { formatCryptoBalance } from './format-currency';
import { convertEVMHashToLink, convertHashToLink } from './transaction-link.util';
import { NetworkChainType } from '@api/meld-app/networks/networks.types';
import { IS_MOCK_ENV } from 'src/contants/is-mock-env';
import { addPendingBridgeToLocalStorage, removePendingBridgeFromLocalStorage } from './pending-bridges-storage';
import { CARDANO_NETWORK } from 'src/contants/cardano';

export const MOCK_DATA = { requests: 0, hash: '' };

const MOCK_TIMEOUT_INTERVAL = 100;

// checks what bridges we're checking status for
const RUNNING_BRIDGE_CHECKS: Record<string, boolean> = {};

const mockGetBridgesByRequestId: (data: { bridgeRequestId: string[] }) => Promise<Bridge> = ({
  bridgeRequestId,
}: {
  bridgeRequestId: string[];
}) =>
  new Promise((resolve) => {
    MOCK_DATA.requests += 1;
    setTimeout(
      () =>
        resolve([
          {
            id: bridgeRequestId[0],
            status: MOCK_DATA.requests === 2 ? 'Confirming' : MOCK_DATA.requests === 3 ? 'Executed' : 'Completed',
            fee: {
              token: 'string',
              amount: 'string',
            },
            // @ts-expect-error 2322
            originalNetwork: {
              token: 'lovelace',
              amount: '1000000',
              network: 'string',
              user: 'string',
              transaction: 'string',
              confirmationTimestamp: '2024-05-06T08:05:25.268Z',
            },
            destinationNetwork: {
              token: 'string',
              amount: 'string',
              network: 'string',
              user: 'string',
              transaction: 'string',
              confirmationTimestamp: '2024-05-06T08:05:25.268Z',
            },
            errors: [
              {
                type: 'string',
                timestamp: '2024-05-06T08:05:25.268Z',
              },
            ],
          },
        ]),
      MOCK_TIMEOUT_INTERVAL,
    );
  });

const mockGetUserBridgesByAddress: (data: { walletAddress: string[] }) => Promise<UserBridges> = () =>
  new Promise((resolve) => {
    MOCK_DATA.requests += 1;
    setTimeout(
      () =>
        resolve([
          {
            id: 'random',
            status: MOCK_DATA.requests === 2 ? 'Confirming' : MOCK_DATA.requests === 3 ? 'Submitted' : 'Completed',
            fee: {
              token: 'string',
              amount: 'string',
            },
            // @ts-expect-error 2322
            originalNetwork: {
              token: 'lovelace',
              amount: '1000000',
              network: 'string',
              user: 'string',
              transaction: MOCK_DATA.hash,
              confirmationTimestamp: '2024-05-06T08:05:25.268Z',
            },
            destinationNetwork: {
              token: 'string',
              amount: 'string',
              network: 'string',
              user: 'string',
              transaction: 'string',
              confirmationTimestamp: '2024-05-06T08:05:25.268Z',
            },
            errors: [
              {
                type: 'string',
                timestamp: '2024-05-06T08:05:25.268Z',
              },
            ],
          },
        ]),
      MOCK_TIMEOUT_INTERVAL,
    );
  });

const runLater = (cb: () => void, timeout = 3000) => setTimeout(() => cb(), timeout);

export const fetchBridgeInfo = async (
  data: {
    hash: string;
    walletAddress: string;
    destinationNetwork: string;
    onComplete: (userBridge: UserBridge) => void;
    updateGlobalState: boolean;
    amount: string;
    token: string;
    network: string;
    tokenName?: string;
    numberOfNfts: number;
  },
  firstRun = true,
  bridgeId?: string,
) => {
  const internalBridgeId = `${data.hash}-${data.walletAddress}-${data.destinationNetwork}`;

  if (!RUNNING_BRIDGE_CHECKS[internalBridgeId]) {
    RUNNING_BRIDGE_CHECKS[internalBridgeId] = true;
    addPendingBridgeToLocalStorage({
      hash: data.hash,
      walletAddress: data.walletAddress,
      destinationNetwork: data.destinationNetwork,
      amount: data.amount,
      token: data.token,
      network: data.network,
      tokenName: data.tokenName,
    });
  }
  // this is a mechanism to ensure we are never checking the status of the same bridge more than once
  else if (firstRun) return;
  const { selectedWalletToken, setBridgeData, inputData, bridgeData, unselectAllCardanoNfts, unselectAllEvmNfts } =
    useStore.getState();
  const { startedAt } = bridgeData;
  const { amount } = inputData;

  if (!startedAt && data.updateGlobalState) {
    setBridgeData({ startedAt: new Date().getTime(), bridgingCardano: data.network === CARDANO_NETWORK });
  }

  // if we have the bridge ID  then request info about that specific bridge
  if (bridgeId) {
    const bridgeIdData = await (IS_MOCK_ENV ? mockGetBridgesByRequestId : getBridgesByRequestId)({
      bridgeRequestId: [bridgeId],
    });
    const { status, destinationNetwork, originalNetwork } = bridgeIdData[0];

    const explorerUrl =
      selectedWalletToken?.chainType === NetworkChainType.CARDANO
        ? convertHashToLink(originalNetwork.transaction as string)
        : convertEVMHashToLink(originalNetwork.transaction as string, originalNetwork.network);

    if (status === 'Confirming') {
      data.updateGlobalState &&
        setBridgeData({
          completedStep: 1,
          explorerUrl,
        });

      runLater(() => fetchBridgeInfo(data, false, bridgeId));
      return;
    }

    if (status === 'Executed') {
      data.updateGlobalState &&
        setBridgeData({
          completedStep: 2,
          explorerUrl,
        });
      runLater(() => fetchBridgeInfo(data, false, bridgeId));
      return;
    }

    if (status === 'Completed' || status === 'Finalized') {
      setTimeout(() => {
        data.updateGlobalState &&
          setBridgeData({
            completedStep: 3,
            explorerUrl,
          });

        data.onComplete(bridgeIdData[0]);

        if (data.updateGlobalState) {
          setBridgeData({
            data: {
              amount: formatCryptoBalance(amount, selectedWalletToken?.symbol, true),
              explorerUrl:
                destinationNetwork.network === CARDANO_NETWORK
                  ? convertHashToLink(destinationNetwork.transaction as string)
                  : convertEVMHashToLink(destinationNetwork.transaction as string, data.destinationNetwork),
              to: data.destinationNetwork,
              numberOfNfts: data.numberOfNfts,
            },
          });
          unselectAllEvmNfts();
          unselectAllCardanoNfts();
        }

        removePendingBridgeFromLocalStorage({
          hash: data.hash,
          walletAddress: data.walletAddress,
          destinationNetwork: data.destinationNetwork,
          amount: data.amount,
          token: data.token,
          network: data.network,
          tokenName: data.tokenName,
        });

        data.updateGlobalState && runLater(() => setBridgeData({ bridgeAgain: true }), 2000);
      }, 15000);

      return;
    }

    if (status === 'Reverted') {
      // update the status of the bridge

      removePendingBridgeFromLocalStorage({
        hash: data.hash,
        walletAddress: data.walletAddress,
        destinationNetwork: data.destinationNetwork,
        amount: data.amount,
        token: data.token,
        network: data.network,
        tokenName: data.tokenName,
      });

      data.updateGlobalState && setBridgeData({ bridgeFailed: true, initiatedBridge: false });
      return;
    }

    // call this again after 5 seconds

    runLater(() => fetchBridgeInfo(data, false, bridgeId));
  } else {
    // fetch all bridges for that address and fetch the bridge id that matches the hash
    try {
      const userBridgesData = await (IS_MOCK_ENV ? mockGetUserBridgesByAddress : getUserBridgesByAddress)({
        walletAddress: [data.walletAddress],
      });
      // try to get bridgeId
      const newBridgeId = userBridgesData.filter(
        (entry) => entry.originalNetwork.transaction?.toLowerCase() === data.hash.toLowerCase(),
      )[0]?.id;
      // run again with bridgeId or undefined to try and fetch it again
      runLater(() => fetchBridgeInfo(data, false, newBridgeId));
    } catch (error) {
      // we'll try again in 5 seconds
      runLater(() => fetchBridgeInfo(data, false, bridgeId));
      return;
    }
  }
};
