import { ConnectButtonMenu } from './connect-button/ConnectButtonMenu';
import { Logo } from './logo';

export const Header = () => {
  return (
    <div className="flex justify-between">
      <Logo />
      <ConnectButtonMenu />
    </div>
  );
};
