export enum NetworkChainType {
  EVM = 'evm',
  CARDANO = 'cardano',
}

export type Network = {
  chainId: number;
  chainType: NetworkChainType;
  darkIcon: string; //ex: '/static/networks/PolygonDark.svg'
  decimals: number;
  defaultTokenSymbol: string;
  explorerUrl: string;
  lightIcon: string;
  name: string;
  publicRpcUrls: Array<string>; // can have both https and ws protocol
};

export type NetworksResponse = Array<Network>;
