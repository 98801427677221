export const isDevEnv =
  import.meta.env.VITE_APP_ENV === 'development' || window.location.host === 'dev-bridge.meldlabs.dev';

export const isNotProdEnv = import.meta.env.VITE_APP_ENV !== 'production';
export const isStagingEnv = window.location.host === 'testnet-bridge.meld.com';

export const checkEnvVars = () => {
  const vitalEnvVars = [
    'VITE_APP_ENV',
    'VITE_APP_API_BASE_URL',
    'VITE_APP_CARDANO_BLOCKFROST_URL',
    'VITE_APP_AVAILABLE_POOL_IDS',
    'VITE_APP_EVM_FAUCET_API_BASE_URL',
    'VITE_APP_CARDANO_TOKEN_API_BASE_URL',
    'VITE_APP_NATS_URL',
    'VITE_APP_TRACKING_NODE_METADATA_STAKING_API_BASE_URL',
    'VITE_APP_TRACKING_STAKING_API_BASE_URL',
    'VITE_APP_CHAIN_PORT_API_BASE_URL',
    'VITE_APP_WALLET_CONNECT_PROJECT_ID',
    'VITE_APP_BANK_API_BASE_URL',
  ];

  vitalEnvVars.map((env) => {
    if (!import.meta.env[env]) {
      console.error(`Error: env var ${env} is NOT set.`);
    }
  });

  if (import.meta.env.VITE_APP_ENV !== 'production' && location.search.includes('debug')) {
    console.debug(import.meta.env);
  }
};
