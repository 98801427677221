import { useRef, useState } from 'react';
import logo from '../assets/meldbridge.svg';
import meld from '../assets/meld.svg';
import { animated, config, useSpring } from 'react-spring';
import { cn } from '@utils/cn';
import { useLoadedInitialBeData } from '@hooks/use-loaded-initial-be-data';
import { useStore } from '@store/store';

export const Logo = () => {
  const [loop, setLoop] = useState(false);
  const [textStyleActive, setTextStyleActive] = useState(false);
  const loopRef = useRef(false);
  const [hideBridge, setHideBridge] = useState(false);

  const nftBridgeSelected = useStore((state) => state.nftBridgeSelected);

  const loadedInitialBeData = useLoadedInitialBeData();

  const logoStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    onRest: () => {
      setTextStyleActive(true);
    },
  });

  const textStyle = useSpring(
    textStyleActive
      ? {
          from: { opacity: 0 },
          to: { opacity: 1 },
          delay: 300,
          onChange: (e) => {
            if (e.value.opacity > 0.5 && !loopRef.current) {
              loopRef.current = true;
              setLoop(true);
            }
          },
          config: config.molasses,
        }
      : {},
  );

  return (
    <div
      className={cn(
        'flex items-center gap-[10px]',
        // pulse if data has not loaded yet
        textStyleActive && !loop && !loadedInitialBeData && 'animate-pulse',
      )}
    >
      <animated.img
        style={logoStyle}
        src={logo}
        className="logo react w-12 drop-shadow-[0px_4px_30px_rgba(0,_0,_0,_0.25)] md:w-auto"
        alt="meld logo"
      />
      <animated.img style={logoStyle} src={meld} className="logo react w-16 md:w-auto" alt="meld logo" />
      <animated.div style={textStyle} className={'min-[400px]:block relative -top-[14px] hidden opacity-0 [&_p]:m-0'}>
        <span
          className={cn(
            'absolute top-[3px] font-light text-base text-white opacity-100 md:top-[2px] md:text-[27px]',
            hideBridge && 'opacity-0',
          )}
        >
          BRIDGE
        </span>
        <div className="absolute top-[3px] flex flex-row md:top-[2px]">
          <span
            className={cn(
              'mr-0 block w-0 overflow-hidden font-light text-base text-white transition-all delay-1000 duration-700 md:text-[27px]',
              nftBridgeSelected && 'w-[57px]',
            )}
          >
            NFT
          </span>
          <span
            onAnimationIteration={() => {
              setLoop(false);
              setHideBridge(true);
            }}
            className={cn('font-light text-base text-white opacity-100  md:text-[27px]', loop && 'logoText')}
          >
            <div>BRIDGE</div>
          </span>
        </div>
      </animated.div>
    </div>
  );
};
