import { useCallback, useEffect, useMemo, useState } from 'react';
import { Input } from './input';
import { Selector } from './selector';
import { cn } from '../../utils/cn.ts';
import { useStore } from '@store/store.ts';
import { shallow } from 'zustand/shallow';
import { formatCryptoBalance } from '@utils/format-currency/format-currency.util.ts';
import { useCountUp } from 'use-count-up';
import { useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import { SuccessMessage } from './success-message.tsx';
import { useNativeToken } from '@hooks/use-native-token.ts';
import { useCalculateTxFee } from '@hooks/use-calculate-tx-fee.ts';
import { NFTSelector } from './nft-selector.tsx';
import { useIsBridging } from '@hooks/use-is-bridging.ts';
import { Balance } from '@components/balance.tsx';
import { BridgeFee } from '@components/bridge-fee.tsx';
import isMobile from 'is-mobile';

export const TokenSelector = ({
  thousandsSeparator,
  decimalSeparator,
}: {
  thousandsSeparator: string;
  decimalSeparator: string;
}) => {
  const setBridgeData = useStore((state) => state.setBridgeData);
  const setEvmData = useStore((state) => state.setEvmData);
  const setInputData = useStore((state) => state.setInputData);
  const nftBridgeSelected = useStore((state) => state.nftBridgeSelected);
  const {
    bridgeFailed,
    data: bridgeData,
    feeAmount: bridgeBackFeeAmount,
    notEnoughToken,
    bridgeBackMaxFees,
  } = useStore((state) => state.bridgeData, shallow);
  const [renderNfts, setRenderNfts] = useState(false);
  const { evmRequiresApproval } = useStore((state) => state.evmData, shallow);

  const { amount, inputError, key } = useStore((state) => state.inputData, shallow);
  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);
  const transactionCost = useStore((state) => state.bridgeData.transactionCost);

  const isBridging = useIsBridging();

  useEffect(() => {
    if (nftBridgeSelected) {
      setTimeout(() => {
        setRenderNfts(true);
      }, 300);
    }
  }, [nftBridgeSelected]);
  const tokenAmountRef = useRef(0);

  const nativeToken = useNativeToken({ network: selectedWalletToken?.network });
  const { isLoading: isLoadingTransactionFee } = useCalculateTxFee();

  const tokenAmount = useMemo(() => {
    const formattedAmount = selectedWalletToken?.amount ?? 0;
    if (formattedAmount === '0.00') return 0;

    return Number(formattedAmount);
  }, [selectedWalletToken]);

  const { value, reset } = useCountUp({
    isCounting: true,
    end: tokenAmountRef.current,
    duration: 1.3,
    easing: 'easeOutCubic',
    start: 0,
    formatter: (value) =>
      formatCryptoBalance(
        value,
        undefined,
        undefined,
        formatCryptoBalance(selectedWalletToken?.amount.toString() ?? '0').split(decimalSeparator)?.[1]?.length ?? 0,
      ),
  });

  useEffect(() => {
    setTimeout(() => {
      tokenAmountRef.current = tokenAmount;
      reset();
    }, 100);
  }, [tokenAmount, reset]);

  const selectorState = bridgeData
    ? { from: { opacity: 1, y: 0 }, to: { opacity: 0, y: -20 } }
    : { from: { opacity: 0, y: -20 }, to: { opacity: 1, y: 0 } };

  const selectorSpring = useSpring({
    ...selectorState,
    delay: bridgeData ? 200 : 500,
  });

  const updateAmount = useCallback(
    (amount: string) => {
      setInputData({ amount });
      setBridgeData({ liquidityData: { amount: '', destinationNetwork: '' } });
      setEvmData({ evmRequiresApproval: false });
      if (amount === '') {
        setBridgeData({ notEnoughToken: false, feeAmount: '' });
      }
    },
    [setInputData, setBridgeData, setEvmData],
  );

  const onBalanceClicked = useCallback(
    (tokenAmount: number) =>
      tokenAmount &&
      (selectedWalletToken?.toBridgeBack ? bridgeBackMaxFees[selectedWalletToken?.tokenId ?? ''].data?.amount : true) &&
      setInputData({
        amount: selectedWalletToken?.toBridgeBack
          ? bridgeBackMaxFees[selectedWalletToken?.tokenId ?? ''].data?.amount ?? '0'
          : tokenAmount.toString(),
      }),
    [bridgeBackMaxFees, selectedWalletToken, setInputData],
  );

  const hiddenStyle = useMemo(
    () => [
      isBridging && !bridgeData && 'pointer-events-none',
      isBridging && !bridgeData && !bridgeFailed && 'opacity-50',
    ],
    [isBridging, bridgeData, bridgeFailed],
  );

  return (
    <>
      <animated.div style={{ position: 'absolute', ...selectorSpring }}>
        {renderNfts && !isMobile() && <NFTSelector onHideNfts={() => setRenderNfts(false)} />}
      </animated.div>
      {!isMobile() && <NFTSelector hidden />}
      <SuccessMessage bridgeData={bridgeData} />
      <div className={cn('w-full', nftBridgeSelected && 'pointer-events-none')}>
        <div className={cn('relative flex w-full justify-center transition-all duration-500')}>
          <animated.div
            className={cn('w-full md:w-max', renderNfts && '!pointer-events-none !opacity-0')}
            style={selectorSpring}
          >
            <div className={cn('transition-all duration-500', hiddenStyle)}>
              <Balance
                isLoading={!!bridgeBackMaxFees[selectedWalletToken?.tokenId ?? '']?.isFetching}
                animatedValue={value}
                formattedValue={formatCryptoBalance(tokenAmount)}
                tokenAmount={tokenAmount}
                onClick={onBalanceClicked}
              />
            </div>
            <div className="my-[7px] flex w-full flex-col gap-[14px] md:flex-row">
              <div className={cn('transition-all duration-500', hiddenStyle)}>
                <Selector />
              </div>
              <div className="relative">
                <div className={cn('transition-all duration-500', hiddenStyle)}>
                  <Input
                    key={key}
                    amount={amount}
                    handleAmountChange={(amount) => {
                      updateAmount(amount);
                    }}
                    selectedToken={selectedWalletToken}
                    customMaxAmount={selectedWalletToken?.amount ?? '0'}
                    thousandsSeparator={thousandsSeparator}
                    decimalSeparator={decimalSeparator}
                  />
                </div>
                <BridgeFee
                  inputError={inputError}
                  selectedWalletToken={selectedWalletToken}
                  nativeToken={nativeToken}
                  isLoadingTransactionFee={isLoadingTransactionFee}
                  amount={amount}
                  transactionCost={transactionCost}
                  bridgeBackFee={selectedWalletToken?.toBridgeBack ? bridgeBackFeeAmount : undefined}
                  notEnoughToken={notEnoughToken}
                  bridgingBack={selectedWalletToken?.toBridgeBack ?? false}
                  needsApproval={evmRequiresApproval ?? true}
                />
              </div>
            </div>
          </animated.div>
        </div>
      </div>
    </>
  );
};
