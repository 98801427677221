import { useStore } from '@store/store';
import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';

export const useLoadedInitialBeData = () => {
  const availableTokens = useStore((state) => state.availableTokens, shallow);

  // networks, tokens and bridge data are all fetched simultaneously so we can just check tokens here
  const loadedData = useMemo(() => !!availableTokens, [availableTokens]);

  return loadedData;
};
