import { Networkish } from '@ethersproject/networks';
import { JsonRpcProvider } from '@ethersproject/providers';
import { providers } from 'ethers';

const connectToRPC = (rpcUrl: string, network?: Networkish) => {
  let provider: JsonRpcProvider;
  if (network) {
    provider = new providers.JsonRpcProvider(rpcUrl, network);
  } else {
    provider = new providers.JsonRpcProvider(rpcUrl);
  }

  return provider;
};

const evmProviderUtils = {
  connectToRPC,
};

export default evmProviderUtils;
