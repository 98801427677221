import BigArrow from '../assets/big-arrow.svg?react';
import { cn } from '../utils/cn';
import { animated } from 'react-spring';
import { useBridgeTokenData } from '@hooks/use-bridge-token-data';
import { TokenIcon } from './token-icon';
import { useEffect, useMemo, useRef } from 'react';
import { useStore } from '@store/store';
import { shallow } from 'zustand/shallow';
import { useIsMobile } from '@hooks/use-is-mobile';
import { MELD_NETWORK } from 'src/contants/meld';
import { useSelectedNfts } from '@hooks/use-selected-nfts';

export const Bridge = () => {
  const { tokenData, meldData } = useBridgeTokenData();
  const { bridgeFailed, completedStep } = useStore((state) => state.bridgeData, shallow);
  const bridgeFailedRef = useRef(bridgeFailed);
  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);
  const { nftBridgeSelected, selectedNftObjects } = useSelectedNfts();
  const { data } = useStore((state) => state.bridgeData, shallow);

  const isMobile = useIsMobile();

  useEffect(() => {
    bridgeFailedRef.current = bridgeFailed;
  }, [bridgeFailed]);

  const dataTokenOnTheLeft = useMemo(
    () =>
      (nftBridgeSelected ? selectedNftObjects?.[0]?.network === MELD_NETWORK : selectedWalletToken?.toBridgeBack)
        ? tokenData.destinationToken
        : tokenData.sourceToken,
    [tokenData, selectedWalletToken, nftBridgeSelected, selectedNftObjects],
  );

  const successOrFailedUI = useMemo(
    () =>
      !isMobile && (
        <animated.div
          className={cn(
            'absolute -bottom-9 left-0 hidden -translate-x-1/4 translate-y-2 font-semibold text-xl uppercase text-meldgreen opacity-0 transition-all duration-500 md:block',
            (completedStep === 3 || bridgeFailed) && 'translate-y-0 opacity-100',
            (bridgeFailedRef.current || bridgeFailed) && '-right-1 text-meldwhite',
            selectedWalletToken?.toBridgeBack && '',
          )}
        >
          {bridgeFailedRef.current || bridgeFailed ? 'FAILED' : 'SUCCESS'}
        </animated.div>
      ),
    [bridgeFailed, completedStep, isMobile, selectedWalletToken?.toBridgeBack],
  );

  return (
    <div className="relative mt-[145px] flex w-full justify-between">
      <div
        className={cn(
          'absolute top-[12px] z-[-1] h-[2px] w-full -translate-y-2/4 bg-[linear-gradient(to_left,transparent,white_10%,white_90%,transparent_100%)]',
          // after
          "after:relative after:block after:h-full after:w-[0%] after:max-w-[calc(100%_-_15px)] after:bg-[linear-gradient(to_left,transparent,#33FF00_15%,#33FF00_100%)] after:transition-all after:duration-1000 after:content-['_']",
          completedStep >= 1 && completedStep < 3
            ? tokenData.sourceToken.network === MELD_NETWORK
              ? 'grow rotate-180 after:-right-[15px]'
              : 'grow after:left-[15px]'
            : '',
        )}
      />
      <div className="relative">
        <div className="absolute -top-2 flex flex-col items-center gap-[11px]">
          <div className="absolute -top-5 font-semibold text-[11px] uppercase text-meldwhite/50">
            {dataTokenOnTheLeft?.network}
          </div>
          <div className="relative h-[38px] w-[38px]">
            <TokenIcon imgSrc={dataTokenOnTheLeft?.icon} />
          </div>
          {data?.to !== MELD_NETWORK ? successOrFailedUI : undefined}
        </div>
      </div>
      <BigArrow
        className={cn(
          'absolute left-[50%] -translate-x-1/2 text-meldwhite transition-all duration-300 [&>path]:transition-all [&>path]:duration-1000',
          completedStep >= 1 && completedStep < 3 && 'text-meldgreen',
          tokenData.sourceToken.network === MELD_NETWORK && 'rotate-180',
          completedStep === 3 && '[&>path]:opacity-0 [&>path]:duration-300',
          selectedWalletToken?.toBridgeBack && !nftBridgeSelected && 'rotate-180',
        )}
      />
      <div className="relative">
        <div className="absolute -top-2 right-0 flex flex-col items-center gap-[11px]">
          <div className="absolute -top-5 font-semibold text-[11px] uppercase text-meldwhite/50">
            {meldData?.network}
          </div>
          <div className="relative h-[38px] w-[38px]">
            <TokenIcon
              imgSrc={meldData?.icon}
              border={bridgeFailed}
              borderColor={bridgeFailed ? 'bg-meldblack/15' : undefined}
            />
          </div>
          {data?.to === MELD_NETWORK ? successOrFailedUI : undefined}
        </div>
      </div>
    </div>
  );
};
